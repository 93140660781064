/* eslint-disable */
import { languageTag } from "./runtime.js"
import * as fr from "./messages/fr.js"
import * as en from "./messages/en.js"


/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_pathname = (params = {}, options = {}) => {
	return {
		en: en.course_pathname,
		fr: fr.course_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ slug: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_pathname_generation = (params , options = {}) => {
	return {
		en: en.course_pathname_generation,
		fr: fr.course_pathname_generation
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const courses_pathname = (params = {}, options = {}) => {
	return {
		en: en.courses_pathname,
		fr: fr.courses_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_pathname = (params = {}, options = {}) => {
	return {
		en: en.course_content_pathname,
		fr: fr.course_content_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ slug: NonNullable<unknown>, partNumber: NonNullable<unknown>, sessionNumber: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_pathname_generation = (params , options = {}) => {
	return {
		en: en.course_content_pathname_generation,
		fr: fr.course_content_pathname_generation
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const program_dashboard_pathname = (params = {}, options = {}) => {
	return {
		en: en.program_dashboard_pathname,
		fr: fr.program_dashboard_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerators_pathname = (params = {}, options = {}) => {
	return {
		en: en.accelerators_pathname,
		fr: fr.accelerators_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_pathname = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_pathname,
		fr: fr.accelerator_dashboard_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ slug: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_pathname_generation = (params , options = {}) => {
	return {
		en: en.accelerator_dashboard_pathname_generation,
		fr: fr.accelerator_dashboard_pathname_generation
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_entryDocument_pathname = (params = {}, options = {}) => {
	return {
		en: en.accelerator_entryDocument_pathname,
		fr: fr.accelerator_entryDocument_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ slug: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_entryDocument_pathname_generation = (params , options = {}) => {
	return {
		en: en.accelerator_entryDocument_pathname_generation,
		fr: fr.accelerator_entryDocument_pathname_generation
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_content_pathname = (params = {}, options = {}) => {
	return {
		en: en.accelerator_content_pathname,
		fr: fr.accelerator_content_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ slug: NonNullable<unknown>, moduleNumber: NonNullable<unknown>, partNumber: NonNullable<unknown>, sessionNumber: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_content_pathname_generation = (params , options = {}) => {
	return {
		en: en.accelerator_content_pathname_generation,
		fr: fr.accelerator_content_pathname_generation
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const glossaries_pathname = (params = {}, options = {}) => {
	return {
		en: en.glossaries_pathname,
		fr: fr.glossaries_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const glossary_pathname = (params = {}, options = {}) => {
	return {
		en: en.glossary_pathname,
		fr: fr.glossary_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const term_pathname = (params = {}, options = {}) => {
	return {
		en: en.term_pathname,
		fr: fr.term_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const serie_pathname = (params = {}, options = {}) => {
	return {
		en: en.serie_pathname,
		fr: fr.serie_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ slug: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const serie_pathname_generation = (params , options = {}) => {
	return {
		en: en.serie_pathname_generation,
		fr: fr.serie_pathname_generation
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const serie_watch_pathname = (params = {}, options = {}) => {
	return {
		en: en.serie_watch_pathname,
		fr: fr.serie_watch_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ slug: NonNullable<unknown>, seasonNumber: NonNullable<unknown>, episodeNumber: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const serie_watch_pathname_generation = (params , options = {}) => {
	return {
		en: en.serie_watch_pathname_generation,
		fr: fr.serie_watch_pathname_generation
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_pathname = (params = {}, options = {}) => {
	return {
		en: en.login_pathname,
		fr: fr.login_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_pathname = (params = {}, options = {}) => {
	return {
		en: en.signup_pathname,
		fr: fr.signup_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_pathname = (params = {}, options = {}) => {
	return {
		en: en.fragments_pathname,
		fr: fr.fragments_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_pathname = (params = {}, options = {}) => {
	return {
		en: en.writer_pathname,
		fr: fr.writer_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_files_pathname = (params = {}, options = {}) => {
	return {
		en: en.writer_files_pathname,
		fr: fr.writer_files_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_pathname = (params = {}, options = {}) => {
	return {
		en: en.writer_article_pathname,
		fr: fr.writer_article_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ slug: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_pathname_generation = (params , options = {}) => {
	return {
		en: en.writer_article_pathname_generation,
		fr: fr.writer_article_pathname_generation
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_pathname = (params = {}, options = {}) => {
	return {
		en: en.mentor_pathname,
		fr: fr.mentor_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const article_pathname = (params = {}, options = {}) => {
	return {
		en: en.article_pathname,
		fr: fr.article_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ slug: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const article_pathname_generation = (params , options = {}) => {
	return {
		en: en.article_pathname_generation,
		fr: fr.article_pathname_generation
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const articles_pathname = (params = {}, options = {}) => {
	return {
		en: en.articles_pathname,
		fr: fr.articles_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_pathname = (params = {}, options = {}) => {
	return {
		en: en.call_pathname,
		fr: fr.call_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ token: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_pathname_generation = (params , options = {}) => {
	return {
		en: en.call_pathname_generation,
		fr: fr.call_pathname_generation
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_pathname = (params = {}, options = {}) => {
	return {
		en: en.instructor_pathname,
		fr: fr.instructor_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_course_pathname = (params = {}, options = {}) => {
	return {
		en: en.instructor_course_pathname,
		fr: fr.instructor_course_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ slug: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_course_pathname_generation = (params , options = {}) => {
	return {
		en: en.instructor_course_pathname_generation,
		fr: fr.instructor_course_pathname_generation
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const certification_pathname = (params = {}, options = {}) => {
	return {
		en: en.certification_pathname,
		fr: fr.certification_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ certificationNumber: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const certification_pathname_generation = (params , options = {}) => {
	return {
		en: en.certification_pathname_generation,
		fr: fr.certification_pathname_generation
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_pathname = (params = {}, options = {}) => {
	return {
		en: en.profile_pathname,
		fr: fr.profile_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ pseudo: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_pathname_generation = (params , options = {}) => {
	return {
		en: en.profile_pathname_generation,
		fr: fr.profile_pathname_generation
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const verifyAccount_pathname = (params = {}, options = {}) => {
	return {
		en: en.verifyAccount_pathname,
		fr: fr.verifyAccount_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_pathname = (params = {}, options = {}) => {
	return {
		en: en.onboarding_pathname,
		fr: fr.onboarding_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenPassword_pathname = (params = {}, options = {}) => {
	return {
		en: en.forgottenPassword_pathname,
		fr: fr.forgottenPassword_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_pathname = (params = {}, options = {}) => {
	return {
		en: en.pricing_pathname,
		fr: fr.pricing_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_pathname = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_pathname,
		fr: fr.joinInstructor_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_pathname = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_pathname,
		fr: fr.joinMentor_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legalMentions_pathname = (params = {}, options = {}) => {
	return {
		en: en.legalMentions_pathname,
		fr: fr.legalMentions_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const privacy_pathname = (params = {}, options = {}) => {
	return {
		en: en.privacy_pathname,
		fr: fr.privacy_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const community_pathname = (params = {}, options = {}) => {
	return {
		en: en.community_pathname,
		fr: fr.community_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const community_discussion_pathname = (params = {}, options = {}) => {
	return {
		en: en.community_discussion_pathname,
		fr: fr.community_discussion_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ id: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const community_discussion_pathname_generation = (params , options = {}) => {
	return {
		en: en.community_discussion_pathname_generation,
		fr: fr.community_discussion_pathname_generation
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const termsOfSale_pathname = (params = {}, options = {}) => {
	return {
		en: en.termsOfSale_pathname,
		fr: fr.termsOfSale_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_pathname = (params = {}, options = {}) => {
	return {
		en: en.press_pathname,
		fr: fr.press_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const events_pathname = (params = {}, options = {}) => {
	return {
		en: en.events_pathname,
		fr: fr.events_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invitation_pathname = (params = {}, options = {}) => {
	return {
		en: en.invitation_pathname,
		fr: fr.invitation_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ key: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invitation_pathname_generation = (params , options = {}) => {
	return {
		en: en.invitation_pathname_generation,
		fr: fr.invitation_pathname_generation
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SideHeader_dashboard = (params = {}, options = {}) => {
	return {
		en: en.SideHeader_dashboard,
		fr: fr.SideHeader_dashboard
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SideHeader_courses = (params = {}, options = {}) => {
	return {
		en: en.SideHeader_courses,
		fr: fr.SideHeader_courses
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SideHeader_accelerators = (params = {}, options = {}) => {
	return {
		en: en.SideHeader_accelerators,
		fr: fr.SideHeader_accelerators
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SideHeader_glossaries = (params = {}, options = {}) => {
	return {
		en: en.SideHeader_glossaries,
		fr: fr.SideHeader_glossaries
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SideHeader_doneYourself = (params = {}, options = {}) => {
	return {
		en: en.SideHeader_doneYourself,
		fr: fr.SideHeader_doneYourself
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SideHeader_articles = (params = {}, options = {}) => {
	return {
		en: en.SideHeader_articles,
		fr: fr.SideHeader_articles
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SideHeader_discord = (params = {}, options = {}) => {
	return {
		en: en.SideHeader_discord,
		fr: fr.SideHeader_discord
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SideHeader_youtube = (params = {}, options = {}) => {
	return {
		en: en.SideHeader_youtube,
		fr: fr.SideHeader_youtube
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SideHeader_doneWithYou = (params = {}, options = {}) => {
	return {
		en: en.SideHeader_doneWithYou,
		fr: fr.SideHeader_doneWithYou
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SideHeader_mentors = (params = {}, options = {}) => {
	return {
		en: en.SideHeader_mentors,
		fr: fr.SideHeader_mentors
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SideHeader_tools = (params = {}, options = {}) => {
	return {
		en: en.SideHeader_tools,
		fr: fr.SideHeader_tools
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SideHeader_new = (params = {}, options = {}) => {
	return {
		en: en.SideHeader_new,
		fr: fr.SideHeader_new
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SideHeader_needHelp = (params = {}, options = {}) => {
	return {
		en: en.SideHeader_needHelp,
		fr: fr.SideHeader_needHelp
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SideHeader_newVersionTitle = (params = {}, options = {}) => {
	return {
		en: en.SideHeader_newVersionTitle,
		fr: fr.SideHeader_newVersionTitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SideHeader_newVersionDescription = (params = {}, options = {}) => {
	return {
		en: en.SideHeader_newVersionDescription,
		fr: fr.SideHeader_newVersionDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SideHeader_needHelpButton = (params = {}, options = {}) => {
	return {
		en: en.SideHeader_needHelpButton,
		fr: fr.SideHeader_needHelpButton
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const TableOfContents_summary = (params = {}, options = {}) => {
	return {
		en: en.TableOfContents_summary,
		fr: fr.TableOfContents_summary
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const TableOfContents_article = (params = {}, options = {}) => {
	return {
		en: en.TableOfContents_article,
		fr: fr.TableOfContents_article
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const TableOfContents_term = (params = {}, options = {}) => {
	return {
		en: en.TableOfContents_term,
		fr: fr.TableOfContents_term
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Level_level = (params = {}, options = {}) => {
	return {
		en: en.Level_level,
		fr: fr.Level_level
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Level_xp = (params = {}, options = {}) => {
	return {
		en: en.Level_xp,
		fr: fr.Level_xp
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_pathname = (params = {}, options = {}) => {
	return {
		en: en.account_pathname,
		fr: fr.account_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_rocket_pathname = (params = {}, options = {}) => {
	return {
		en: en.accelerator_rocket_pathname,
		fr: fr.accelerator_rocket_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Term_languageChangedMessage = (params = {}, options = {}) => {
	return {
		en: en.Term_languageChangedMessage,
		fr: fr.Term_languageChangedMessage
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ slug: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const glossary_pathname_generation = (params , options = {}) => {
	return {
		en: en.glossary_pathname_generation,
		fr: fr.glossary_pathname_generation
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ slug: NonNullable<unknown>, termSlug: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const term_pathname_generation = (params , options = {}) => {
	return {
		en: en.term_pathname_generation,
		fr: fr.term_pathname_generation
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Term_noContentAvailableForThisTerm = (params = {}, options = {}) => {
	return {
		en: en.Term_noContentAvailableForThisTerm,
		fr: fr.Term_noContentAvailableForThisTerm
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Glossary_aside_language = (params = {}, options = {}) => {
	return {
		en: en.Glossary_aside_language,
		fr: fr.Glossary_aside_language
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Glossary_aside_availableLanguages = (params = {}, options = {}) => {
	return {
		en: en.Glossary_aside_availableLanguages,
		fr: fr.Glossary_aside_availableLanguages
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const messenger_pathname = (params = {}, options = {}) => {
	return {
		en: en.messenger_pathname,
		fr: fr.messenger_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Glossary_term_linkTitle = (params = {}, options = {}) => {
	return {
		en: en.Glossary_term_linkTitle,
		fr: fr.Glossary_term_linkTitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerators_meta_title = (params = {}, options = {}) => {
	return {
		en: en.accelerators_meta_title,
		fr: fr.accelerators_meta_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerators_meta_description = (params = {}, options = {}) => {
	return {
		en: en.accelerators_meta_description,
		fr: fr.accelerators_meta_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_pathname = (params = {}, options = {}) => {
	return {
		en: en.accelerator_pathname,
		fr: fr.accelerator_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerators_title = (params = {}, options = {}) => {
	return {
		en: en.accelerators_title,
		fr: fr.accelerators_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerators_headline = (params = {}, options = {}) => {
	return {
		en: en.accelerators_headline,
		fr: fr.accelerators_headline
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerators_languageNotSupportedYet = (params = {}, options = {}) => {
	return {
		en: en.accelerators_languageNotSupportedYet,
		fr: fr.accelerators_languageNotSupportedYet
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentoring_pathname = (params = {}, options = {}) => {
	return {
		en: en.mentoring_pathname,
		fr: fr.mentoring_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentoring_meta_title = (params = {}, options = {}) => {
	return {
		en: en.mentoring_meta_title,
		fr: fr.mentoring_meta_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentoring_meta_description = (params = {}, options = {}) => {
	return {
		en: en.mentoring_meta_description,
		fr: fr.mentoring_meta_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentoring_title = (params = {}, options = {}) => {
	return {
		en: en.mentoring_title,
		fr: fr.mentoring_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentoring_headline = (params = {}, options = {}) => {
	return {
		en: en.mentoring_headline,
		fr: fr.mentoring_headline
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentoring_noMentorsFoundAlert = (params = {}, options = {}) => {
	return {
		en: en.mentoring_noMentorsFoundAlert,
		fr: fr.mentoring_noMentorsFoundAlert
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentoring_filters_title = (params = {}, options = {}) => {
	return {
		en: en.mentoring_filters_title,
		fr: fr.mentoring_filters_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentoring_filters_description = (params = {}, options = {}) => {
	return {
		en: en.mentoring_filters_description,
		fr: fr.mentoring_filters_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentoring_filters_domains = (params = {}, options = {}) => {
	return {
		en: en.mentoring_filters_domains,
		fr: fr.mentoring_filters_domains
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentorprofile_pathname = (params = {}, options = {}) => {
	return {
		en: en.mentorprofile_pathname,
		fr: fr.mentorprofile_pathname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const filter_domain_all = (params = {}, options = {}) => {
	return {
		en: en.filter_domain_all,
		fr: fr.filter_domain_all
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const filter_domain_marketing = (params = {}, options = {}) => {
	return {
		en: en.filter_domain_marketing,
		fr: fr.filter_domain_marketing
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const filter_domain_copywriting = (params = {}, options = {}) => {
	return {
		en: en.filter_domain_copywriting,
		fr: fr.filter_domain_copywriting
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const filter_domain_seo = (params = {}, options = {}) => {
	return {
		en: en.filter_domain_seo,
		fr: fr.filter_domain_seo
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const filter_domain_startup = (params = {}, options = {}) => {
	return {
		en: en.filter_domain_startup,
		fr: fr.filter_domain_startup
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const filter_domain_psychology = (params = {}, options = {}) => {
	return {
		en: en.filter_domain_psychology,
		fr: fr.filter_domain_psychology
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const filter_domain_ecommerce = (params = {}, options = {}) => {
	return {
		en: en.filter_domain_ecommerce,
		fr: fr.filter_domain_ecommerce
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const filter_domain_design = (params = {}, options = {}) => {
	return {
		en: en.filter_domain_design,
		fr: fr.filter_domain_design
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const filter_domain_programming = (params = {}, options = {}) => {
	return {
		en: en.filter_domain_programming,
		fr: fr.filter_domain_programming
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ pseudo: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentorprofile_pathname_generation = (params , options = {}) => {
	return {
		en: en.mentorprofile_pathname_generation,
		fr: fr.mentorprofile_pathname_generation
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ fullName: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentorprofile_meta_title = (params , options = {}) => {
	return {
		en: en.mentorprofile_meta_title,
		fr: fr.mentorprofile_meta_title
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ fullName: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentorprofile_meta_description = (params , options = {}) => {
	return {
		en: en.mentorprofile_meta_description,
		fr: fr.mentorprofile_meta_description
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentorprofile_domains = (params = {}, options = {}) => {
	return {
		en: en.mentorprofile_domains,
		fr: fr.mentorprofile_domains
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentorprofile_presentation = (params = {}, options = {}) => {
	return {
		en: en.mentorprofile_presentation,
		fr: fr.mentorprofile_presentation
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentorprofile_recommendations = (params = {}, options = {}) => {
	return {
		en: en.mentorprofile_recommendations,
		fr: fr.mentorprofile_recommendations
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentorprofile_no_recommendations = (params = {}, options = {}) => {
	return {
		en: en.mentorprofile_no_recommendations,
		fr: fr.mentorprofile_no_recommendations
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_priceLabel = (params = {}, options = {}) => {
	return {
		en: en.BookNewCall_priceLabel,
		fr: fr.BookNewCall_priceLabel
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_free = (params = {}, options = {}) => {
	return {
		en: en.BookNewCall_free,
		fr: fr.BookNewCall_free
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_onlyOneLeftForThisPrice = (params = {}, options = {}) => {
	return {
		en: en.BookNewCall_onlyOneLeftForThisPrice,
		fr: fr.BookNewCall_onlyOneLeftForThisPrice
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ number: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_onlyFewCallsAvailableAtThisPrice = (params , options = {}) => {
	return {
		en: en.BookNewCall_onlyFewCallsAvailableAtThisPrice,
		fr: fr.BookNewCall_onlyFewCallsAvailableAtThisPrice
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_minutes = (params = {}, options = {}) => {
	return {
		en: en.BookNewCall_minutes,
		fr: fr.BookNewCall_minutes
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_hours = (params = {}, options = {}) => {
	return {
		en: en.BookNewCall_hours,
		fr: fr.BookNewCall_hours
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ number: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_onlyFewDaysAvailableAtThisPrice = (params , options = {}) => {
	return {
		en: en.BookNewCall_onlyFewDaysAvailableAtThisPrice,
		fr: fr.BookNewCall_onlyFewDaysAvailableAtThisPrice
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_onlyOneDayAvailableAtThisPrice = (params = {}, options = {}) => {
	return {
		en: en.BookNewCall_onlyOneDayAvailableAtThisPrice,
		fr: fr.BookNewCall_onlyOneDayAvailableAtThisPrice
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_reservation_success = (params = {}, options = {}) => {
	return {
		en: en.BookNewCall_reservation_success,
		fr: fr.BookNewCall_reservation_success
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ fullName: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_reservation_success_message = (params , options = {}) => {
	return {
		en: en.BookNewCall_reservation_success_message,
		fr: fr.BookNewCall_reservation_success_message
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_code_added = (params = {}, options = {}) => {
	return {
		en: en.BookNewCall_code_added,
		fr: fr.BookNewCall_code_added
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_code_invalid = (params = {}, options = {}) => {
	return {
		en: en.BookNewCall_code_invalid,
		fr: fr.BookNewCall_code_invalid
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_slotNotAvailable = (params = {}, options = {}) => {
	return {
		en: en.BookNewCall_slotNotAvailable,
		fr: fr.BookNewCall_slotNotAvailable
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_bookForFree = (params = {}, options = {}) => {
	return {
		en: en.BookNewCall_bookForFree,
		fr: fr.BookNewCall_bookForFree
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_cantBookWithThisMentor = (params = {}, options = {}) => {
	return {
		en: en.BookNewCall_cantBookWithThisMentor,
		fr: fr.BookNewCall_cantBookWithThisMentor
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_pleaseSelectDateAndHour = (params = {}, options = {}) => {
	return {
		en: en.BookNewCall_pleaseSelectDateAndHour,
		fr: fr.BookNewCall_pleaseSelectDateAndHour
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_informationsChanged_success = (params = {}, options = {}) => {
	return {
		en: en.BookNewCall_informationsChanged_success,
		fr: fr.BookNewCall_informationsChanged_success
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ time: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_youHaveLessThan24HoursToEnjoyThisPrice = (params , options = {}) => {
	return {
		en: en.BookNewCall_youHaveLessThan24HoursToEnjoyThisPrice,
		fr: fr.BookNewCall_youHaveLessThan24HoursToEnjoyThisPrice
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ minutesPerCall: NonNullable<unknown>, fullName: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_bookDescription = (params , options = {}) => {
	return {
		en: en.BookNewCall_bookDescription,
		fr: fr.BookNewCall_bookDescription
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ minutesPerCall: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_book_point_minutes = (params , options = {}) => {
	return {
		en: en.BookNewCall_book_point_minutes,
		fr: fr.BookNewCall_book_point_minutes
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_book_point_hiddenFees = (params = {}, options = {}) => {
	return {
		en: en.BookNewCall_book_point_hiddenFees,
		fr: fr.BookNewCall_book_point_hiddenFees
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_book_point_selection = (params = {}, options = {}) => {
	return {
		en: en.BookNewCall_book_point_selection,
		fr: fr.BookNewCall_book_point_selection
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_book_point_cancellationAvailable = (params = {}, options = {}) => {
	return {
		en: en.BookNewCall_book_point_cancellationAvailable,
		fr: fr.BookNewCall_book_point_cancellationAvailable
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_book_point_cancellationAvailable_day = (params = {}, options = {}) => {
	return {
		en: en.BookNewCall_book_point_cancellationAvailable_day,
		fr: fr.BookNewCall_book_point_cancellationAvailable_day
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ days: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_book_point_cancellationAvailable_days = (params , options = {}) => {
	return {
		en: en.BookNewCall_book_point_cancellationAvailable_days,
		fr: fr.BookNewCall_book_point_cancellationAvailable_days
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_book_point_cancellationAvailable_hour = (params = {}, options = {}) => {
	return {
		en: en.BookNewCall_book_point_cancellationAvailable_hour,
		fr: fr.BookNewCall_book_point_cancellationAvailable_hour
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ hours: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_book_point_cancellationAvailable_hours = (params , options = {}) => {
	return {
		en: en.BookNewCall_book_point_cancellationAvailable_hours,
		fr: fr.BookNewCall_book_point_cancellationAvailable_hours
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_book_freeCancellation = (params = {}, options = {}) => {
	return {
		en: en.BookNewCall_book_freeCancellation,
		fr: fr.BookNewCall_book_freeCancellation
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_book_fullRefund = (params = {}, options = {}) => {
	return {
		en: en.BookNewCall_book_fullRefund,
		fr: fr.BookNewCall_book_fullRefund
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ percent: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_book_partialRefund = (params , options = {}) => {
	return {
		en: en.BookNewCall_book_partialRefund,
		fr: fr.BookNewCall_book_partialRefund
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_book_modificationAvailable = (params = {}, options = {}) => {
	return {
		en: en.BookNewCall_book_modificationAvailable,
		fr: fr.BookNewCall_book_modificationAvailable
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ numberOfChanges: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_book_point_modificationAvailable_day = (params , options = {}) => {
	return {
		en: en.BookNewCall_book_point_modificationAvailable_day,
		fr: fr.BookNewCall_book_point_modificationAvailable_day
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ numberOfChanges: NonNullable<unknown>, days: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_book_point_modificationAvailable_days = (params , options = {}) => {
	return {
		en: en.BookNewCall_book_point_modificationAvailable_days,
		fr: fr.BookNewCall_book_point_modificationAvailable_days
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ numberOfChanges: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_book_point_modificationAvailable_hour = (params , options = {}) => {
	return {
		en: en.BookNewCall_book_point_modificationAvailable_hour,
		fr: fr.BookNewCall_book_point_modificationAvailable_hour
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ numberOfChanges: NonNullable<unknown>, hours: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_book_point_modificationAvailable_hours = (params , options = {}) => {
	return {
		en: en.BookNewCall_book_point_modificationAvailable_hours,
		fr: fr.BookNewCall_book_point_modificationAvailable_hours
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_book_modificationAvailableMaximumOneDayAfterDescription = (params = {}, options = {}) => {
	return {
		en: en.BookNewCall_book_modificationAvailableMaximumOneDayAfterDescription,
		fr: fr.BookNewCall_book_modificationAvailableMaximumOneDayAfterDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ days: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_book_modificationAvailableMaximumDaysAfterDescription = (params , options = {}) => {
	return {
		en: en.BookNewCall_book_modificationAvailableMaximumDaysAfterDescription,
		fr: fr.BookNewCall_book_modificationAvailableMaximumDaysAfterDescription
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_button_bookACall = (params = {}, options = {}) => {
	return {
		en: en.BookNewCall_button_bookACall,
		fr: fr.BookNewCall_button_bookACall
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_button_soon = (params = {}, options = {}) => {
	return {
		en: en.BookNewCall_button_soon,
		fr: fr.BookNewCall_button_soon
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_button_bookAFreeCall = (params = {}, options = {}) => {
	return {
		en: en.BookNewCall_button_bookAFreeCall,
		fr: fr.BookNewCall_button_bookAFreeCall
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_address_title = (params = {}, options = {}) => {
	return {
		en: en.BookNewCall_address_title,
		fr: fr.BookNewCall_address_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_address_updateQuestion = (params = {}, options = {}) => {
	return {
		en: en.BookNewCall_address_updateQuestion,
		fr: fr.BookNewCall_address_updateQuestion
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_address_updateButton = (params = {}, options = {}) => {
	return {
		en: en.BookNewCall_address_updateButton,
		fr: fr.BookNewCall_address_updateButton
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_button_next = (params = {}, options = {}) => {
	return {
		en: en.BookNewCall_button_next,
		fr: fr.BookNewCall_button_next
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_calendar_title = (params = {}, options = {}) => {
	return {
		en: en.BookNewCall_calendar_title,
		fr: fr.BookNewCall_calendar_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_hour_title = (params = {}, options = {}) => {
	return {
		en: en.BookNewCall_hour_title,
		fr: fr.BookNewCall_hour_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_payment_title = (params = {}, options = {}) => {
	return {
		en: en.BookNewCall_payment_title,
		fr: fr.BookNewCall_payment_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_payment_description = (params = {}, options = {}) => {
	return {
		en: en.BookNewCall_payment_description,
		fr: fr.BookNewCall_payment_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_payment_read = (params = {}, options = {}) => {
	return {
		en: en.BookNewCall_payment_read,
		fr: fr.BookNewCall_payment_read
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_payment_free_description = (params = {}, options = {}) => {
	return {
		en: en.BookNewCall_payment_free_description,
		fr: fr.BookNewCall_payment_free_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Calendar_week_mon = (params = {}, options = {}) => {
	return {
		en: en.Calendar_week_mon,
		fr: fr.Calendar_week_mon
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Calendar_week_tue = (params = {}, options = {}) => {
	return {
		en: en.Calendar_week_tue,
		fr: fr.Calendar_week_tue
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Calendar_week_wed = (params = {}, options = {}) => {
	return {
		en: en.Calendar_week_wed,
		fr: fr.Calendar_week_wed
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Calendar_week_thu = (params = {}, options = {}) => {
	return {
		en: en.Calendar_week_thu,
		fr: fr.Calendar_week_thu
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Calendar_week_fri = (params = {}, options = {}) => {
	return {
		en: en.Calendar_week_fri,
		fr: fr.Calendar_week_fri
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Calendar_week_sat = (params = {}, options = {}) => {
	return {
		en: en.Calendar_week_sat,
		fr: fr.Calendar_week_sat
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Calendar_week_sun = (params = {}, options = {}) => {
	return {
		en: en.Calendar_week_sun,
		fr: fr.Calendar_week_sun
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Checkout_button_label = (params = {}, options = {}) => {
	return {
		en: en.Checkout_button_label,
		fr: fr.Checkout_button_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_street = (params = {}, options = {}) => {
	return {
		en: en.address_street,
		fr: fr.address_street
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_street_required = (params = {}, options = {}) => {
	return {
		en: en.address_street_required,
		fr: fr.address_street_required
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_option = (params = {}, options = {}) => {
	return {
		en: en.address_option,
		fr: fr.address_option
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_zipCode = (params = {}, options = {}) => {
	return {
		en: en.address_zipCode,
		fr: fr.address_zipCode
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_zipCode_required = (params = {}, options = {}) => {
	return {
		en: en.address_zipCode_required,
		fr: fr.address_zipCode_required
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_city = (params = {}, options = {}) => {
	return {
		en: en.address_city,
		fr: fr.address_city
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_city_required = (params = {}, options = {}) => {
	return {
		en: en.address_city_required,
		fr: fr.address_city_required
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_country = (params = {}, options = {}) => {
	return {
		en: en.address_country,
		fr: fr.address_country
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_AF = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_AF,
		fr: fr.address_selectedCountry_AF
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_ZA = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_ZA,
		fr: fr.address_selectedCountry_ZA
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_AL = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_AL,
		fr: fr.address_selectedCountry_AL
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_DZ = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_DZ,
		fr: fr.address_selectedCountry_DZ
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_DE = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_DE,
		fr: fr.address_selectedCountry_DE
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_AD = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_AD,
		fr: fr.address_selectedCountry_AD
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_AO = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_AO,
		fr: fr.address_selectedCountry_AO
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_AI = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_AI,
		fr: fr.address_selectedCountry_AI
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_AQ = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_AQ,
		fr: fr.address_selectedCountry_AQ
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_AG = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_AG,
		fr: fr.address_selectedCountry_AG
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_SA = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_SA,
		fr: fr.address_selectedCountry_SA
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_AR = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_AR,
		fr: fr.address_selectedCountry_AR
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_AM = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_AM,
		fr: fr.address_selectedCountry_AM
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_AW = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_AW,
		fr: fr.address_selectedCountry_AW
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_AU = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_AU,
		fr: fr.address_selectedCountry_AU
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_AT = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_AT,
		fr: fr.address_selectedCountry_AT
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_AZ = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_AZ,
		fr: fr.address_selectedCountry_AZ
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_BS = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_BS,
		fr: fr.address_selectedCountry_BS
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_BH = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_BH,
		fr: fr.address_selectedCountry_BH
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_BD = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_BD,
		fr: fr.address_selectedCountry_BD
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_BB = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_BB,
		fr: fr.address_selectedCountry_BB
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_BE = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_BE,
		fr: fr.address_selectedCountry_BE
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_BZ = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_BZ,
		fr: fr.address_selectedCountry_BZ
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_BJ = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_BJ,
		fr: fr.address_selectedCountry_BJ
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_BM = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_BM,
		fr: fr.address_selectedCountry_BM
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_BT = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_BT,
		fr: fr.address_selectedCountry_BT
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_BY = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_BY,
		fr: fr.address_selectedCountry_BY
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_BO = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_BO,
		fr: fr.address_selectedCountry_BO
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_BA = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_BA,
		fr: fr.address_selectedCountry_BA
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_BW = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_BW,
		fr: fr.address_selectedCountry_BW
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_BR = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_BR,
		fr: fr.address_selectedCountry_BR
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_BN = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_BN,
		fr: fr.address_selectedCountry_BN
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_BG = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_BG,
		fr: fr.address_selectedCountry_BG
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_BF = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_BF,
		fr: fr.address_selectedCountry_BF
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_BI = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_BI,
		fr: fr.address_selectedCountry_BI
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_KH = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_KH,
		fr: fr.address_selectedCountry_KH
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_CM = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_CM,
		fr: fr.address_selectedCountry_CM
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_CA = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_CA,
		fr: fr.address_selectedCountry_CA
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_CV = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_CV,
		fr: fr.address_selectedCountry_CV
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_CL = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_CL,
		fr: fr.address_selectedCountry_CL
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_CN = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_CN,
		fr: fr.address_selectedCountry_CN
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_CY = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_CY,
		fr: fr.address_selectedCountry_CY
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_CO = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_CO,
		fr: fr.address_selectedCountry_CO
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_KM = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_KM,
		fr: fr.address_selectedCountry_KM
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_CG = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_CG,
		fr: fr.address_selectedCountry_CG
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_CD = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_CD,
		fr: fr.address_selectedCountry_CD
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_KR = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_KR,
		fr: fr.address_selectedCountry_KR
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_CR = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_CR,
		fr: fr.address_selectedCountry_CR
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_CI = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_CI,
		fr: fr.address_selectedCountry_CI
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_HR = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_HR,
		fr: fr.address_selectedCountry_HR
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_CW = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_CW,
		fr: fr.address_selectedCountry_CW
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_DK = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_DK,
		fr: fr.address_selectedCountry_DK
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_DJ = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_DJ,
		fr: fr.address_selectedCountry_DJ
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_DM = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_DM,
		fr: fr.address_selectedCountry_DM
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_EG = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_EG,
		fr: fr.address_selectedCountry_EG
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_AE = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_AE,
		fr: fr.address_selectedCountry_AE
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_EC = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_EC,
		fr: fr.address_selectedCountry_EC
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_ER = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_ER,
		fr: fr.address_selectedCountry_ER
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_ES = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_ES,
		fr: fr.address_selectedCountry_ES
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_EE = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_EE,
		fr: fr.address_selectedCountry_EE
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_SZ = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_SZ,
		fr: fr.address_selectedCountry_SZ
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_VA = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_VA,
		fr: fr.address_selectedCountry_VA
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_US = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_US,
		fr: fr.address_selectedCountry_US
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_ET = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_ET,
		fr: fr.address_selectedCountry_ET
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_FJ = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_FJ,
		fr: fr.address_selectedCountry_FJ
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_FI = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_FI,
		fr: fr.address_selectedCountry_FI
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_FR = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_FR,
		fr: fr.address_selectedCountry_FR
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_GA = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_GA,
		fr: fr.address_selectedCountry_GA
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_GM = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_GM,
		fr: fr.address_selectedCountry_GM
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_GE = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_GE,
		fr: fr.address_selectedCountry_GE
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_GS = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_GS,
		fr: fr.address_selectedCountry_GS
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_GH = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_GH,
		fr: fr.address_selectedCountry_GH
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_GI = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_GI,
		fr: fr.address_selectedCountry_GI
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_GR = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_GR,
		fr: fr.address_selectedCountry_GR
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_GD = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_GD,
		fr: fr.address_selectedCountry_GD
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_GL = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_GL,
		fr: fr.address_selectedCountry_GL
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_GP = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_GP,
		fr: fr.address_selectedCountry_GP
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_GU = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_GU,
		fr: fr.address_selectedCountry_GU
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_GT = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_GT,
		fr: fr.address_selectedCountry_GT
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_GG = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_GG,
		fr: fr.address_selectedCountry_GG
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_GN = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_GN,
		fr: fr.address_selectedCountry_GN
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_GQ = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_GQ,
		fr: fr.address_selectedCountry_GQ
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_GW = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_GW,
		fr: fr.address_selectedCountry_GW
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_GY = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_GY,
		fr: fr.address_selectedCountry_GY
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_GF = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_GF,
		fr: fr.address_selectedCountry_GF
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_HT = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_HT,
		fr: fr.address_selectedCountry_HT
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_HN = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_HN,
		fr: fr.address_selectedCountry_HN
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_HU = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_HU,
		fr: fr.address_selectedCountry_HU
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_BV = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_BV,
		fr: fr.address_selectedCountry_BV
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_AC = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_AC,
		fr: fr.address_selectedCountry_AC
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_IM = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_IM,
		fr: fr.address_selectedCountry_IM
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_AX = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_AX,
		fr: fr.address_selectedCountry_AX
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_KY = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_KY,
		fr: fr.address_selectedCountry_KY
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_CK = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_CK,
		fr: fr.address_selectedCountry_CK
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_FO = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_FO,
		fr: fr.address_selectedCountry_FO
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_FK = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_FK,
		fr: fr.address_selectedCountry_FK
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_PN = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_PN,
		fr: fr.address_selectedCountry_PN
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_SB = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_SB,
		fr: fr.address_selectedCountry_SB
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_TC = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_TC,
		fr: fr.address_selectedCountry_TC
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_VG = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_VG,
		fr: fr.address_selectedCountry_VG
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_IN = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_IN,
		fr: fr.address_selectedCountry_IN
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_ID = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_ID,
		fr: fr.address_selectedCountry_ID
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_IQ = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_IQ,
		fr: fr.address_selectedCountry_IQ
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_IE = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_IE,
		fr: fr.address_selectedCountry_IE
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_IS = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_IS,
		fr: fr.address_selectedCountry_IS
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_IL = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_IL,
		fr: fr.address_selectedCountry_IL
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_IT = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_IT,
		fr: fr.address_selectedCountry_IT
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_JM = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_JM,
		fr: fr.address_selectedCountry_JM
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_JP = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_JP,
		fr: fr.address_selectedCountry_JP
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_JE = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_JE,
		fr: fr.address_selectedCountry_JE
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_JO = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_JO,
		fr: fr.address_selectedCountry_JO
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_KZ = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_KZ,
		fr: fr.address_selectedCountry_KZ
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_KE = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_KE,
		fr: fr.address_selectedCountry_KE
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_KG = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_KG,
		fr: fr.address_selectedCountry_KG
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_KI = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_KI,
		fr: fr.address_selectedCountry_KI
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_XK = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_XK,
		fr: fr.address_selectedCountry_XK
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_KW = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_KW,
		fr: fr.address_selectedCountry_KW
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_RE = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_RE,
		fr: fr.address_selectedCountry_RE
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_LA = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_LA,
		fr: fr.address_selectedCountry_LA
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_LS = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_LS,
		fr: fr.address_selectedCountry_LS
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_LV = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_LV,
		fr: fr.address_selectedCountry_LV
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_LB = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_LB,
		fr: fr.address_selectedCountry_LB
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_LR = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_LR,
		fr: fr.address_selectedCountry_LR
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_LY = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_LY,
		fr: fr.address_selectedCountry_LY
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_LI = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_LI,
		fr: fr.address_selectedCountry_LI
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_LT = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_LT,
		fr: fr.address_selectedCountry_LT
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_LU = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_LU,
		fr: fr.address_selectedCountry_LU
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_MK = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_MK,
		fr: fr.address_selectedCountry_MK
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_MG = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_MG,
		fr: fr.address_selectedCountry_MG
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_MY = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_MY,
		fr: fr.address_selectedCountry_MY
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_MW = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_MW,
		fr: fr.address_selectedCountry_MW
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_MV = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_MV,
		fr: fr.address_selectedCountry_MV
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_ML = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_ML,
		fr: fr.address_selectedCountry_ML
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_MT = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_MT,
		fr: fr.address_selectedCountry_MT
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_MA = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_MA,
		fr: fr.address_selectedCountry_MA
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_MQ = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_MQ,
		fr: fr.address_selectedCountry_MQ
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_MU = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_MU,
		fr: fr.address_selectedCountry_MU
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_MR = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_MR,
		fr: fr.address_selectedCountry_MR
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_YT = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_YT,
		fr: fr.address_selectedCountry_YT
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_MX = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_MX,
		fr: fr.address_selectedCountry_MX
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_MD = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_MD,
		fr: fr.address_selectedCountry_MD
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_MC = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_MC,
		fr: fr.address_selectedCountry_MC
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_MN = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_MN,
		fr: fr.address_selectedCountry_MN
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_ME = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_ME,
		fr: fr.address_selectedCountry_ME
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_MS = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_MS,
		fr: fr.address_selectedCountry_MS
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_MZ = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_MZ,
		fr: fr.address_selectedCountry_MZ
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_MM = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_MM,
		fr: fr.address_selectedCountry_MM
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_NA = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_NA,
		fr: fr.address_selectedCountry_NA
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_NR = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_NR,
		fr: fr.address_selectedCountry_NR
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_NP = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_NP,
		fr: fr.address_selectedCountry_NP
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_NI = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_NI,
		fr: fr.address_selectedCountry_NI
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_NE = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_NE,
		fr: fr.address_selectedCountry_NE
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_NG = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_NG,
		fr: fr.address_selectedCountry_NG
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_NU = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_NU,
		fr: fr.address_selectedCountry_NU
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_NO = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_NO,
		fr: fr.address_selectedCountry_NO
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_NC = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_NC,
		fr: fr.address_selectedCountry_NC
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_NZ = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_NZ,
		fr: fr.address_selectedCountry_NZ
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_OM = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_OM,
		fr: fr.address_selectedCountry_OM
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_UG = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_UG,
		fr: fr.address_selectedCountry_UG
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_UZ = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_UZ,
		fr: fr.address_selectedCountry_UZ
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_PK = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_PK,
		fr: fr.address_selectedCountry_PK
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_PA = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_PA,
		fr: fr.address_selectedCountry_PA
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_PG = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_PG,
		fr: fr.address_selectedCountry_PG
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_PY = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_PY,
		fr: fr.address_selectedCountry_PY
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_NL = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_NL,
		fr: fr.address_selectedCountry_NL
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_BQ = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_BQ,
		fr: fr.address_selectedCountry_BQ
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_PE = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_PE,
		fr: fr.address_selectedCountry_PE
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_PH = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_PH,
		fr: fr.address_selectedCountry_PH
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_PL = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_PL,
		fr: fr.address_selectedCountry_PL
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_PF = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_PF,
		fr: fr.address_selectedCountry_PF
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_PR = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_PR,
		fr: fr.address_selectedCountry_PR
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_PT = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_PT,
		fr: fr.address_selectedCountry_PT
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_QA = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_QA,
		fr: fr.address_selectedCountry_QA
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_HK = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_HK,
		fr: fr.address_selectedCountry_HK
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_MO = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_MO,
		fr: fr.address_selectedCountry_MO
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_CF = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_CF,
		fr: fr.address_selectedCountry_CF
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_DO = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_DO,
		fr: fr.address_selectedCountry_DO
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_RO = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_RO,
		fr: fr.address_selectedCountry_RO
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_GB = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_GB,
		fr: fr.address_selectedCountry_GB
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_RU = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_RU,
		fr: fr.address_selectedCountry_RU
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_RW = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_RW,
		fr: fr.address_selectedCountry_RW
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_EH = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_EH,
		fr: fr.address_selectedCountry_EH
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_BL = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_BL,
		fr: fr.address_selectedCountry_BL
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_KN = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_KN,
		fr: fr.address_selectedCountry_KN
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_SM = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_SM,
		fr: fr.address_selectedCountry_SM
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_MF = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_MF,
		fr: fr.address_selectedCountry_MF
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_SX = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_SX,
		fr: fr.address_selectedCountry_SX
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_PM = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_PM,
		fr: fr.address_selectedCountry_PM
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_VC = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_VC,
		fr: fr.address_selectedCountry_VC
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_SH = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_SH,
		fr: fr.address_selectedCountry_SH
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_LC = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_LC,
		fr: fr.address_selectedCountry_LC
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_SV = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_SV,
		fr: fr.address_selectedCountry_SV
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_WS = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_WS,
		fr: fr.address_selectedCountry_WS
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_ST = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_ST,
		fr: fr.address_selectedCountry_ST
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_SN = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_SN,
		fr: fr.address_selectedCountry_SN
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_RS = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_RS,
		fr: fr.address_selectedCountry_RS
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_SC = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_SC,
		fr: fr.address_selectedCountry_SC
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_SL = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_SL,
		fr: fr.address_selectedCountry_SL
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_SG = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_SG,
		fr: fr.address_selectedCountry_SG
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_SK = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_SK,
		fr: fr.address_selectedCountry_SK
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_SI = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_SI,
		fr: fr.address_selectedCountry_SI
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_SO = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_SO,
		fr: fr.address_selectedCountry_SO
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_SS = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_SS,
		fr: fr.address_selectedCountry_SS
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_LK = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_LK,
		fr: fr.address_selectedCountry_LK
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_SE = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_SE,
		fr: fr.address_selectedCountry_SE
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_CH = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_CH,
		fr: fr.address_selectedCountry_CH
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_SR = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_SR,
		fr: fr.address_selectedCountry_SR
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_SJ = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_SJ,
		fr: fr.address_selectedCountry_SJ
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_TJ = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_TJ,
		fr: fr.address_selectedCountry_TJ
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_TW = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_TW,
		fr: fr.address_selectedCountry_TW
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_TZ = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_TZ,
		fr: fr.address_selectedCountry_TZ
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_TD = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_TD,
		fr: fr.address_selectedCountry_TD
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_CZ = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_CZ,
		fr: fr.address_selectedCountry_CZ
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_TF = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_TF,
		fr: fr.address_selectedCountry_TF
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_IO = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_IO,
		fr: fr.address_selectedCountry_IO
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_PS = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_PS,
		fr: fr.address_selectedCountry_PS
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_TH = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_TH,
		fr: fr.address_selectedCountry_TH
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_TL = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_TL,
		fr: fr.address_selectedCountry_TL
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_TG = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_TG,
		fr: fr.address_selectedCountry_TG
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_TK = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_TK,
		fr: fr.address_selectedCountry_TK
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_TO = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_TO,
		fr: fr.address_selectedCountry_TO
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_TT = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_TT,
		fr: fr.address_selectedCountry_TT
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_TA = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_TA,
		fr: fr.address_selectedCountry_TA
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_TN = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_TN,
		fr: fr.address_selectedCountry_TN
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_TM = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_TM,
		fr: fr.address_selectedCountry_TM
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_TR = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_TR,
		fr: fr.address_selectedCountry_TR
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_TV = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_TV,
		fr: fr.address_selectedCountry_TV
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_UA = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_UA,
		fr: fr.address_selectedCountry_UA
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_UY = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_UY,
		fr: fr.address_selectedCountry_UY
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_VU = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_VU,
		fr: fr.address_selectedCountry_VU
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_VE = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_VE,
		fr: fr.address_selectedCountry_VE
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_VN = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_VN,
		fr: fr.address_selectedCountry_VN
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_WF = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_WF,
		fr: fr.address_selectedCountry_WF
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_YE = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_YE,
		fr: fr.address_selectedCountry_YE
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_ZM = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_ZM,
		fr: fr.address_selectedCountry_ZM
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_ZW = (params = {}, options = {}) => {
	return {
		en: en.address_selectedCountry_ZW,
		fr: fr.address_selectedCountry_ZW
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_legalMentions = (params = {}, options = {}) => {
	return {
		en: en.Footer_legalMentions,
		fr: fr.Footer_legalMentions
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_privacy = (params = {}, options = {}) => {
	return {
		en: en.Footer_privacy,
		fr: fr.Footer_privacy
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_qualiopi = (params = {}, options = {}) => {
	return {
		en: en.Footer_qualiopi,
		fr: fr.Footer_qualiopi
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_qualiopi_description = (params = {}, options = {}) => {
	return {
		en: en.Footer_qualiopi_description,
		fr: fr.Footer_qualiopi_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_accelerators = (params = {}, options = {}) => {
	return {
		en: en.Footer_accelerators,
		fr: fr.Footer_accelerators
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_accelerators_rocket = (params = {}, options = {}) => {
	return {
		en: en.Footer_accelerators_rocket,
		fr: fr.Footer_accelerators_rocket
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_accelerators_newYear = (params = {}, options = {}) => {
	return {
		en: en.Footer_accelerators_newYear,
		fr: fr.Footer_accelerators_newYear
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_accelerators_founder = (params = {}, options = {}) => {
	return {
		en: en.Footer_accelerators_founder,
		fr: fr.Footer_accelerators_founder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_usefulLinks = (params = {}, options = {}) => {
	return {
		en: en.Footer_usefulLinks,
		fr: fr.Footer_usefulLinks
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_usefulLinks_becomingInstructor = (params = {}, options = {}) => {
	return {
		en: en.Footer_usefulLinks_becomingInstructor,
		fr: fr.Footer_usefulLinks_becomingInstructor
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_usefulLinks_becomingMentor = (params = {}, options = {}) => {
	return {
		en: en.Footer_usefulLinks_becomingMentor,
		fr: fr.Footer_usefulLinks_becomingMentor
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_usefulLinks_pricing = (params = {}, options = {}) => {
	return {
		en: en.Footer_usefulLinks_pricing,
		fr: fr.Footer_usefulLinks_pricing
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_usefulLinks_articles = (params = {}, options = {}) => {
	return {
		en: en.Footer_usefulLinks_articles,
		fr: fr.Footer_usefulLinks_articles
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_usefulLinks_courses = (params = {}, options = {}) => {
	return {
		en: en.Footer_usefulLinks_courses,
		fr: fr.Footer_usefulLinks_courses
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_usefulLinks_glossaries = (params = {}, options = {}) => {
	return {
		en: en.Footer_usefulLinks_glossaries,
		fr: fr.Footer_usefulLinks_glossaries
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_usefulLinks_community = (params = {}, options = {}) => {
	return {
		en: en.Footer_usefulLinks_community,
		fr: fr.Footer_usefulLinks_community
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_usefulLinks_mentoring = (params = {}, options = {}) => {
	return {
		en: en.Footer_usefulLinks_mentoring,
		fr: fr.Footer_usefulLinks_mentoring
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_usefulLinks_events = (params = {}, options = {}) => {
	return {
		en: en.Footer_usefulLinks_events,
		fr: fr.Footer_usefulLinks_events
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_usefulLinks_podcast_conversationWith = (params = {}, options = {}) => {
	return {
		en: en.Footer_usefulLinks_podcast_conversationWith,
		fr: fr.Footer_usefulLinks_podcast_conversationWith
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_usefulLinks_podcast_bookReviews = (params = {}, options = {}) => {
	return {
		en: en.Footer_usefulLinks_podcast_bookReviews,
		fr: fr.Footer_usefulLinks_podcast_bookReviews
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_followUs = (params = {}, options = {}) => {
	return {
		en: en.Footer_followUs,
		fr: fr.Footer_followUs
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_followUs_press = (params = {}, options = {}) => {
	return {
		en: en.Footer_followUs_press,
		fr: fr.Footer_followUs_press
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_weLoveThem = (params = {}, options = {}) => {
	return {
		en: en.Footer_weLoveThem,
		fr: fr.Footer_weLoveThem
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const userDropdown_administrationPanel = (params = {}, options = {}) => {
	return {
		en: en.userDropdown_administrationPanel,
		fr: fr.userDropdown_administrationPanel
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const userDropdown_instructorPanel = (params = {}, options = {}) => {
	return {
		en: en.userDropdown_instructorPanel,
		fr: fr.userDropdown_instructorPanel
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const userDropdown_mentorPanel = (params = {}, options = {}) => {
	return {
		en: en.userDropdown_mentorPanel,
		fr: fr.userDropdown_mentorPanel
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const userDropdown_writerPanel = (params = {}, options = {}) => {
	return {
		en: en.userDropdown_writerPanel,
		fr: fr.userDropdown_writerPanel
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const userDropdown_profile = (params = {}, options = {}) => {
	return {
		en: en.userDropdown_profile,
		fr: fr.userDropdown_profile
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const userDropdown_account = (params = {}, options = {}) => {
	return {
		en: en.userDropdown_account,
		fr: fr.userDropdown_account
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const userDropdown_darkMode = (params = {}, options = {}) => {
	return {
		en: en.userDropdown_darkMode,
		fr: fr.userDropdown_darkMode
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const userDropdown_lightMode = (params = {}, options = {}) => {
	return {
		en: en.userDropdown_lightMode,
		fr: fr.userDropdown_lightMode
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const userDropdown_logout = (params = {}, options = {}) => {
	return {
		en: en.userDropdown_logout,
		fr: fr.userDropdown_logout
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SpeedSearch_emptyResults = (params = {}, options = {}) => {
	return {
		en: en.SpeedSearch_emptyResults,
		fr: fr.SpeedSearch_emptyResults
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SpeedSearch_placeholder = (params = {}, options = {}) => {
	return {
		en: en.SpeedSearch_placeholder,
		fr: fr.SpeedSearch_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SpeedSearch_title = (params = {}, options = {}) => {
	return {
		en: en.SpeedSearch_title,
		fr: fr.SpeedSearch_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SpeedSearch_search_placeholder = (params = {}, options = {}) => {
	return {
		en: en.SpeedSearch_search_placeholder,
		fr: fr.SpeedSearch_search_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SpeedSearch_courses = (params = {}, options = {}) => {
	return {
		en: en.SpeedSearch_courses,
		fr: fr.SpeedSearch_courses
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SpeedSearch_loadMore = (params = {}, options = {}) => {
	return {
		en: en.SpeedSearch_loadMore,
		fr: fr.SpeedSearch_loadMore
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SpeedSearch_articles = (params = {}, options = {}) => {
	return {
		en: en.SpeedSearch_articles,
		fr: fr.SpeedSearch_articles
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Streak_title = (params = {}, options = {}) => {
	return {
		en: en.Streak_title,
		fr: fr.Streak_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Streak_zero = (params = {}, options = {}) => {
	return {
		en: en.Streak_zero,
		fr: fr.Streak_zero
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Streak_one_day = (params = {}, options = {}) => {
	return {
		en: en.Streak_one_day,
		fr: fr.Streak_one_day
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ days: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Streak_x_days = (params , options = {}) => {
	return {
		en: en.Streak_x_days,
		fr: fr.Streak_x_days
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Streak_longest = (params = {}, options = {}) => {
	return {
		en: en.Streak_longest,
		fr: fr.Streak_longest
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Streak_days = (params = {}, options = {}) => {
	return {
		en: en.Streak_days,
		fr: fr.Streak_days
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Streak_day = (params = {}, options = {}) => {
	return {
		en: en.Streak_day,
		fr: fr.Streak_day
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Streak_shields = (params = {}, options = {}) => {
	return {
		en: en.Streak_shields,
		fr: fr.Streak_shields
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Streak_shields_plural = (params = {}, options = {}) => {
	return {
		en: en.Streak_shields_plural,
		fr: fr.Streak_shields_plural
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Streak_shields_singular = (params = {}, options = {}) => {
	return {
		en: en.Streak_shields_singular,
		fr: fr.Streak_shields_singular
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Fragments_title = (params = {}, options = {}) => {
	return {
		en: en.Fragments_title,
		fr: fr.Fragments_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Fragments_description = (params = {}, options = {}) => {
	return {
		en: en.Fragments_description,
		fr: fr.Fragments_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Fragments_showMyFragments = (params = {}, options = {}) => {
	return {
		en: en.Fragments_showMyFragments,
		fr: fr.Fragments_showMyFragments
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Level_modale_title = (params = {}, options = {}) => {
	return {
		en: en.Level_modale_title,
		fr: fr.Level_modale_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Level_modale_description1 = (params = {}, options = {}) => {
	return {
		en: en.Level_modale_description1,
		fr: fr.Level_modale_description1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Level_modale_description2 = (params = {}, options = {}) => {
	return {
		en: en.Level_modale_description2,
		fr: fr.Level_modale_description2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Level_modale_description3 = (params = {}, options = {}) => {
	return {
		en: en.Level_modale_description3,
		fr: fr.Level_modale_description3
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Level_modale_howToEarnXP_title = (params = {}, options = {}) => {
	return {
		en: en.Level_modale_howToEarnXP_title,
		fr: fr.Level_modale_howToEarnXP_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Level_modale_howToEarnXP_description = (params = {}, options = {}) => {
	return {
		en: en.Level_modale_howToEarnXP_description,
		fr: fr.Level_modale_howToEarnXP_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Level_modale_howToEarnXP_1 = (params = {}, options = {}) => {
	return {
		en: en.Level_modale_howToEarnXP_1,
		fr: fr.Level_modale_howToEarnXP_1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Level_modale_howToEarnXP_2 = (params = {}, options = {}) => {
	return {
		en: en.Level_modale_howToEarnXP_2,
		fr: fr.Level_modale_howToEarnXP_2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Level_modale_howToEarnXP_3 = (params = {}, options = {}) => {
	return {
		en: en.Level_modale_howToEarnXP_3,
		fr: fr.Level_modale_howToEarnXP_3
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Level_modale_howToEarnXP_4 = (params = {}, options = {}) => {
	return {
		en: en.Level_modale_howToEarnXP_4,
		fr: fr.Level_modale_howToEarnXP_4
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_meta_title = (params = {}, options = {}) => {
	return {
		en: en.fragments_meta_title,
		fr: fr.fragments_meta_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_meta_description = (params = {}, options = {}) => {
	return {
		en: en.fragments_meta_description,
		fr: fr.fragments_meta_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_title = (params = {}, options = {}) => {
	return {
		en: en.fragments_title,
		fr: fr.fragments_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_headline = (params = {}, options = {}) => {
	return {
		en: en.fragments_headline,
		fr: fr.fragments_headline
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_explanation = (params = {}, options = {}) => {
	return {
		en: en.fragments_explanation,
		fr: fr.fragments_explanation
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_title = (params = {}, options = {}) => {
	return {
		en: en.fragments_modale_title,
		fr: fr.fragments_modale_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_description1 = (params = {}, options = {}) => {
	return {
		en: en.fragments_modale_description1,
		fr: fr.fragments_modale_description1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_description2 = (params = {}, options = {}) => {
	return {
		en: en.fragments_modale_description2,
		fr: fr.fragments_modale_description2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_description3 = (params = {}, options = {}) => {
	return {
		en: en.fragments_modale_description3,
		fr: fr.fragments_modale_description3
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_description4 = (params = {}, options = {}) => {
	return {
		en: en.fragments_modale_description4,
		fr: fr.fragments_modale_description4
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_howToEarnFragments_title = (params = {}, options = {}) => {
	return {
		en: en.fragments_modale_howToEarnFragments_title,
		fr: fr.fragments_modale_howToEarnFragments_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_howToEarnFragments_description = (params = {}, options = {}) => {
	return {
		en: en.fragments_modale_howToEarnFragments_description,
		fr: fr.fragments_modale_howToEarnFragments_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_howToEarnFragments_1 = (params = {}, options = {}) => {
	return {
		en: en.fragments_modale_howToEarnFragments_1,
		fr: fr.fragments_modale_howToEarnFragments_1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_howToEarnFragments_2 = (params = {}, options = {}) => {
	return {
		en: en.fragments_modale_howToEarnFragments_2,
		fr: fr.fragments_modale_howToEarnFragments_2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_howToEarnFragments_3 = (params = {}, options = {}) => {
	return {
		en: en.fragments_modale_howToEarnFragments_3,
		fr: fr.fragments_modale_howToEarnFragments_3
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_alert_openSinceLittleTime = (params = {}, options = {}) => {
	return {
		en: en.fragments_alert_openSinceLittleTime,
		fr: fr.fragments_alert_openSinceLittleTime
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_invitation_title = (params = {}, options = {}) => {
	return {
		en: en.fragments_modale_invitation_title,
		fr: fr.fragments_modale_invitation_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_invitation_description1 = (params = {}, options = {}) => {
	return {
		en: en.fragments_modale_invitation_description1,
		fr: fr.fragments_modale_invitation_description1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_invitation_description2 = (params = {}, options = {}) => {
	return {
		en: en.fragments_modale_invitation_description2,
		fr: fr.fragments_modale_invitation_description2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_invitation_description3 = (params = {}, options = {}) => {
	return {
		en: en.fragments_modale_invitation_description3,
		fr: fr.fragments_modale_invitation_description3
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_invitation_details_title = (params = {}, options = {}) => {
	return {
		en: en.fragments_modale_invitation_details_title,
		fr: fr.fragments_modale_invitation_details_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_invitation_details_description = (params = {}, options = {}) => {
	return {
		en: en.fragments_modale_invitation_details_description,
		fr: fr.fragments_modale_invitation_details_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_invitation_details_1 = (params = {}, options = {}) => {
	return {
		en: en.fragments_modale_invitation_details_1,
		fr: fr.fragments_modale_invitation_details_1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_invitation_details_2 = (params = {}, options = {}) => {
	return {
		en: en.fragments_modale_invitation_details_2,
		fr: fr.fragments_modale_invitation_details_2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_invitation_details_3 = (params = {}, options = {}) => {
	return {
		en: en.fragments_modale_invitation_details_3,
		fr: fr.fragments_modale_invitation_details_3
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_invitation_details_4 = (params = {}, options = {}) => {
	return {
		en: en.fragments_modale_invitation_details_4,
		fr: fr.fragments_modale_invitation_details_4
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_invitation_details_5 = (params = {}, options = {}) => {
	return {
		en: en.fragments_modale_invitation_details_5,
		fr: fr.fragments_modale_invitation_details_5
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_invitation_details_6 = (params = {}, options = {}) => {
	return {
		en: en.fragments_modale_invitation_details_6,
		fr: fr.fragments_modale_invitation_details_6
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_shop_shield_title = (params = {}, options = {}) => {
	return {
		en: en.fragments_shop_shield_title,
		fr: fr.fragments_shop_shield_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_shop_shield_description = (params = {}, options = {}) => {
	return {
		en: en.fragments_shop_shield_description,
		fr: fr.fragments_shop_shield_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_shop_name = (params = {}, options = {}) => {
	return {
		en: en.fragments_shop_name,
		fr: fr.fragments_shop_name
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_button_buy = (params = {}, options = {}) => {
	return {
		en: en.fragments_button_buy,
		fr: fr.fragments_button_buy
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_shop_pen_title = (params = {}, options = {}) => {
	return {
		en: en.fragments_shop_pen_title,
		fr: fr.fragments_shop_pen_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_shop_pen_description = (params = {}, options = {}) => {
	return {
		en: en.fragments_shop_pen_description,
		fr: fr.fragments_shop_pen_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_shop_premium_title = (params = {}, options = {}) => {
	return {
		en: en.fragments_shop_premium_title,
		fr: fr.fragments_shop_premium_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_shop_premium_description = (params = {}, options = {}) => {
	return {
		en: en.fragments_shop_premium_description,
		fr: fr.fragments_shop_premium_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_shop_booknote_title = (params = {}, options = {}) => {
	return {
		en: en.fragments_shop_booknote_title,
		fr: fr.fragments_shop_booknote_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_shop_booknote_description = (params = {}, options = {}) => {
	return {
		en: en.fragments_shop_booknote_description,
		fr: fr.fragments_shop_booknote_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_shop_usb_title = (params = {}, options = {}) => {
	return {
		en: en.fragments_shop_usb_title,
		fr: fr.fragments_shop_usb_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_shop_usb_description = (params = {}, options = {}) => {
	return {
		en: en.fragments_shop_usb_description,
		fr: fr.fragments_shop_usb_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_shop_bag_title = (params = {}, options = {}) => {
	return {
		en: en.fragments_shop_bag_title,
		fr: fr.fragments_shop_bag_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_shop_bag_description = (params = {}, options = {}) => {
	return {
		en: en.fragments_shop_bag_description,
		fr: fr.fragments_shop_bag_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_shop_mug_title = (params = {}, options = {}) => {
	return {
		en: en.fragments_shop_mug_title,
		fr: fr.fragments_shop_mug_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_shop_mug_description = (params = {}, options = {}) => {
	return {
		en: en.fragments_shop_mug_description,
		fr: fr.fragments_shop_mug_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_shop_invitation_title = (params = {}, options = {}) => {
	return {
		en: en.fragments_shop_invitation_title,
		fr: fr.fragments_shop_invitation_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_shop_invitation_description = (params = {}, options = {}) => {
	return {
		en: en.fragments_shop_invitation_description,
		fr: fr.fragments_shop_invitation_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_shop_invitation_forTheMostLoyal = (params = {}, options = {}) => {
	return {
		en: en.fragments_shop_invitation_forTheMostLoyal,
		fr: fr.fragments_shop_invitation_forTheMostLoyal
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_shop_invitation_button = (params = {}, options = {}) => {
	return {
		en: en.fragments_shop_invitation_button,
		fr: fr.fragments_shop_invitation_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_button_label = (params = {}, options = {}) => {
	return {
		en: en.upload_button_label,
		fr: fr.upload_button_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_meta_title = (params = {}, options = {}) => {
	return {
		en: en.writer_meta_title,
		fr: fr.writer_meta_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_meta_description = (params = {}, options = {}) => {
	return {
		en: en.writer_meta_description,
		fr: fr.writer_meta_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_title = (params = {}, options = {}) => {
	return {
		en: en.writer_title,
		fr: fr.writer_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_headline = (params = {}, options = {}) => {
	return {
		en: en.writer_headline,
		fr: fr.writer_headline
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_nav_home = (params = {}, options = {}) => {
	return {
		en: en.writer_nav_home,
		fr: fr.writer_nav_home
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_nav_newArticle = (params = {}, options = {}) => {
	return {
		en: en.writer_nav_newArticle,
		fr: fr.writer_nav_newArticle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_nav_files = (params = {}, options = {}) => {
	return {
		en: en.writer_nav_files,
		fr: fr.writer_nav_files
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_drafts_title = (params = {}, options = {}) => {
	return {
		en: en.writer_drafts_title,
		fr: fr.writer_drafts_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_drafts_empty = (params = {}, options = {}) => {
	return {
		en: en.writer_drafts_empty,
		fr: fr.writer_drafts_empty
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_articles_title = (params = {}, options = {}) => {
	return {
		en: en.writer_articles_title,
		fr: fr.writer_articles_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_articles_empty = (params = {}, options = {}) => {
	return {
		en: en.writer_articles_empty,
		fr: fr.writer_articles_empty
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_newArticle_title = (params = {}, options = {}) => {
	return {
		en: en.writer_newArticle_title,
		fr: fr.writer_newArticle_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_thumbnail_title = (params = {}, options = {}) => {
	return {
		en: en.writer_thumbnail_title,
		fr: fr.writer_thumbnail_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_thumbnail_description = (params = {}, options = {}) => {
	return {
		en: en.writer_thumbnail_description,
		fr: fr.writer_thumbnail_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_aside_description1 = (params = {}, options = {}) => {
	return {
		en: en.writer_aside_description1,
		fr: fr.writer_aside_description1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_aside_description2 = (params = {}, options = {}) => {
	return {
		en: en.writer_aside_description2,
		fr: fr.writer_aside_description2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_aside_description3 = (params = {}, options = {}) => {
	return {
		en: en.writer_aside_description3,
		fr: fr.writer_aside_description3
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_aside_files = (params = {}, options = {}) => {
	return {
		en: en.writer_aside_files,
		fr: fr.writer_aside_files
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_form_title_label = (params = {}, options = {}) => {
	return {
		en: en.writer_form_title_label,
		fr: fr.writer_form_title_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_form_title_placeholder = (params = {}, options = {}) => {
	return {
		en: en.writer_form_title_placeholder,
		fr: fr.writer_form_title_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_form_description_label = (params = {}, options = {}) => {
	return {
		en: en.writer_form_description_label,
		fr: fr.writer_form_description_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_form_description_placeholder = (params = {}, options = {}) => {
	return {
		en: en.writer_form_description_placeholder,
		fr: fr.writer_form_description_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_form_content_label = (params = {}, options = {}) => {
	return {
		en: en.writer_form_content_label,
		fr: fr.writer_form_content_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_form_content_placeholder = (params = {}, options = {}) => {
	return {
		en: en.writer_form_content_placeholder,
		fr: fr.writer_form_content_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_form_button_submit = (params = {}, options = {}) => {
	return {
		en: en.writer_form_button_submit,
		fr: fr.writer_form_button_submit
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_thumbnail_type_error = (params = {}, options = {}) => {
	return {
		en: en.writer_thumbnail_type_error,
		fr: fr.writer_thumbnail_type_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_thumbnail_empty_error = (params = {}, options = {}) => {
	return {
		en: en.writer_thumbnail_empty_error,
		fr: fr.writer_thumbnail_empty_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_title_empty_error = (params = {}, options = {}) => {
	return {
		en: en.writer_title_empty_error,
		fr: fr.writer_title_empty_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_description_empty_error = (params = {}, options = {}) => {
	return {
		en: en.writer_description_empty_error,
		fr: fr.writer_description_empty_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_content_empty_error = (params = {}, options = {}) => {
	return {
		en: en.writer_content_empty_error,
		fr: fr.writer_content_empty_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_unknown_error = (params = {}, options = {}) => {
	return {
		en: en.writer_unknown_error,
		fr: fr.writer_unknown_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_added = (params = {}, options = {}) => {
	return {
		en: en.writer_article_added,
		fr: fr.writer_article_added
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_email_not_verified = (params = {}, options = {}) => {
	return {
		en: en.writer_email_not_verified,
		fr: fr.writer_email_not_verified
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_files_meta_title = (params = {}, options = {}) => {
	return {
		en: en.writer_files_meta_title,
		fr: fr.writer_files_meta_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_files_meta_description = (params = {}, options = {}) => {
	return {
		en: en.writer_files_meta_description,
		fr: fr.writer_files_meta_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Files_myDocuments = (params = {}, options = {}) => {
	return {
		en: en.Files_myDocuments,
		fr: fr.Files_myDocuments
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Files_title = (params = {}, options = {}) => {
	return {
		en: en.Files_title,
		fr: fr.Files_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Files_headline = (params = {}, options = {}) => {
	return {
		en: en.Files_headline,
		fr: fr.Files_headline
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Files_alert_usage = (params = {}, options = {}) => {
	return {
		en: en.Files_alert_usage,
		fr: fr.Files_alert_usage
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Files_copied = (params = {}, options = {}) => {
	return {
		en: en.Files_copied,
		fr: fr.Files_copied
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Files_linkToYourUpload = (params = {}, options = {}) => {
	return {
		en: en.Files_linkToYourUpload,
		fr: fr.Files_linkToYourUpload
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Files_uploadAnotherDocument = (params = {}, options = {}) => {
	return {
		en: en.Files_uploadAnotherDocument,
		fr: fr.Files_uploadAnotherDocument
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Files_button_add_upload = (params = {}, options = {}) => {
	return {
		en: en.Files_button_add_upload,
		fr: fr.Files_button_add_upload
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Files_noDocuments = (params = {}, options = {}) => {
	return {
		en: en.Files_noDocuments,
		fr: fr.Files_noDocuments
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_meta_title = (params = {}, options = {}) => {
	return {
		en: en.mentor_meta_title,
		fr: fr.mentor_meta_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_meta_description = (params = {}, options = {}) => {
	return {
		en: en.mentor_meta_description,
		fr: fr.mentor_meta_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_title = (params = {}, options = {}) => {
	return {
		en: en.mentor_title,
		fr: fr.mentor_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_headline = (params = {}, options = {}) => {
	return {
		en: en.mentor_headline,
		fr: fr.mentor_headline
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_nav_dashboard = (params = {}, options = {}) => {
	return {
		en: en.mentor_nav_dashboard,
		fr: fr.mentor_nav_dashboard
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_nav_calls = (params = {}, options = {}) => {
	return {
		en: en.mentor_nav_calls,
		fr: fr.mentor_nav_calls
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_nav_codes = (params = {}, options = {}) => {
	return {
		en: en.mentor_nav_codes,
		fr: fr.mentor_nav_codes
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_title = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_title,
		fr: fr.mentor_dashboard_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_reservation_period = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_reservation_period,
		fr: fr.mentor_dashboard_reservation_period
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_reservation_period_indefinitely = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_reservation_period_indefinitely,
		fr: fr.mentor_dashboard_reservation_period_indefinitely
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_reservation_period_30days = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_reservation_period_30days,
		fr: fr.mentor_dashboard_reservation_period_30days
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_reservation_period_14days = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_reservation_period_14days,
		fr: fr.mentor_dashboard_reservation_period_14days
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_error_required = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_error_required,
		fr: fr.mentor_dashboard_error_required
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_monday = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_monday,
		fr: fr.mentor_dashboard_monday
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_tuesday = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_tuesday,
		fr: fr.mentor_dashboard_tuesday
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_wednesday = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_wednesday,
		fr: fr.mentor_dashboard_wednesday
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_thursday = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_thursday,
		fr: fr.mentor_dashboard_thursday
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_friday = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_friday,
		fr: fr.mentor_dashboard_friday
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_saturday = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_saturday,
		fr: fr.mentor_dashboard_saturday
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_sunday = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_sunday,
		fr: fr.mentor_dashboard_sunday
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_to = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_to,
		fr: fr.mentor_dashboard_to
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_not_available = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_not_available,
		fr: fr.mentor_dashboard_not_available
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_time_before_reservation = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_time_before_reservation,
		fr: fr.mentor_dashboard_time_before_reservation
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_before = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_before,
		fr: fr.mentor_dashboard_before
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_no = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_no,
		fr: fr.mentor_dashboard_no
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_yes = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_yes,
		fr: fr.mentor_dashboard_yes
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_after = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_after,
		fr: fr.mentor_dashboard_after
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_duration_5_minutes = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_duration_5_minutes,
		fr: fr.mentor_dashboard_duration_5_minutes
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_duration_10_minutes = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_duration_10_minutes,
		fr: fr.mentor_dashboard_duration_10_minutes
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_duration_15_minutes = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_duration_15_minutes,
		fr: fr.mentor_dashboard_duration_15_minutes
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_duration_20_minutes = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_duration_20_minutes,
		fr: fr.mentor_dashboard_duration_20_minutes
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_duration_25_minutes = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_duration_25_minutes,
		fr: fr.mentor_dashboard_duration_25_minutes
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_duration_30_minutes = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_duration_30_minutes,
		fr: fr.mentor_dashboard_duration_30_minutes
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_duration_45_minutes = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_duration_45_minutes,
		fr: fr.mentor_dashboard_duration_45_minutes
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_duration_1_hour = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_duration_1_hour,
		fr: fr.mentor_dashboard_duration_1_hour
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_duration_1_hour_30_minutes = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_duration_1_hour_30_minutes,
		fr: fr.mentor_dashboard_duration_1_hour_30_minutes
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_duration_2_hours = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_duration_2_hours,
		fr: fr.mentor_dashboard_duration_2_hours
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_duration_2_hours_30_minutes = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_duration_2_hours_30_minutes,
		fr: fr.mentor_dashboard_duration_2_hours_30_minutes
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_duration_3_hours = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_duration_3_hours,
		fr: fr.mentor_dashboard_duration_3_hours
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_timezone = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_timezone,
		fr: fr.mentor_dashboard_timezone
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_minimum_hours_of_notice = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_minimum_hours_of_notice,
		fr: fr.mentor_dashboard_minimum_hours_of_notice
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_minimum_hours_of_notice_placeholder = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_minimum_hours_of_notice_placeholder,
		fr: fr.mentor_dashboard_minimum_hours_of_notice_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_open_reservations = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_open_reservations,
		fr: fr.mentor_dashboard_open_reservations
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_open_reservations_description = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_open_reservations_description,
		fr: fr.mentor_dashboard_open_reservations_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_message = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_message,
		fr: fr.mentor_dashboard_message
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_button_save = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_button_save,
		fr: fr.mentor_dashboard_button_save
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_google_calendar_title = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_google_calendar_title,
		fr: fr.mentor_dashboard_google_calendar_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_google_calendar_description = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_google_calendar_description,
		fr: fr.mentor_dashboard_google_calendar_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_google_calendar_button_connect = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_google_calendar_button_connect,
		fr: fr.mentor_dashboard_google_calendar_button_connect
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_google_calendar_button_disconnect = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_google_calendar_button_disconnect,
		fr: fr.mentor_dashboard_google_calendar_button_disconnect
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_personal_informations_title = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_personal_informations_title,
		fr: fr.mentor_dashboard_personal_informations_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_personal_informations_titles = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_personal_informations_titles,
		fr: fr.mentor_dashboard_personal_informations_titles
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_personal_informations_titles_placeholder = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_personal_informations_titles_placeholder,
		fr: fr.mentor_dashboard_personal_informations_titles_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_personal_informations_presentation = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_personal_informations_presentation,
		fr: fr.mentor_dashboard_personal_informations_presentation
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_personal_informations_presentation_placeholder = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_personal_informations_presentation_placeholder,
		fr: fr.mentor_dashboard_personal_informations_presentation_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_personal_informations_personalizedLink = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_personal_informations_personalizedLink,
		fr: fr.mentor_dashboard_personal_informations_personalizedLink
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_personal_informations_personalizedLink_placeholder = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_personal_informations_personalizedLink_placeholder,
		fr: fr.mentor_dashboard_personal_informations_personalizedLink_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_personal_informations_domains = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_personal_informations_domains,
		fr: fr.mentor_dashboard_personal_informations_domains
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_personal_informations_price = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_personal_informations_price,
		fr: fr.mentor_dashboard_personal_informations_price
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_personal_informations_price_placeholder = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_personal_informations_price_placeholder,
		fr: fr.mentor_dashboard_personal_informations_price_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_personal_informations_numberOfMinutesPerCall = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_personal_informations_numberOfMinutesPerCall,
		fr: fr.mentor_dashboard_personal_informations_numberOfMinutesPerCall
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_personal_informations_numberOfMinutesPerCall_placeholder = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_personal_informations_numberOfMinutesPerCall_placeholder,
		fr: fr.mentor_dashboard_personal_informations_numberOfMinutesPerCall_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_branding_title = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_branding_title,
		fr: fr.mentor_dashboard_branding_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_branding_portrait_title = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_branding_portrait_title,
		fr: fr.mentor_dashboard_branding_portrait_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_branding_portrait_description = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_branding_portrait_description,
		fr: fr.mentor_dashboard_branding_portrait_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_guarantees_title = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_guarantees_title,
		fr: fr.mentor_dashboard_guarantees_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_guarantees_canBeCancelled = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_guarantees_canBeCancelled,
		fr: fr.mentor_dashboard_guarantees_canBeCancelled
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_guarantees_minimumHoursToCancel = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_guarantees_minimumHoursToCancel,
		fr: fr.mentor_dashboard_guarantees_minimumHoursToCancel
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_guarantees_minimumHoursToCancel_placeholder = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_guarantees_minimumHoursToCancel_placeholder,
		fr: fr.mentor_dashboard_guarantees_minimumHoursToCancel_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_guarantees_refundRate = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_guarantees_refundRate,
		fr: fr.mentor_dashboard_guarantees_refundRate
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_guarantees_refundRate_placeholder = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_guarantees_refundRate_placeholder,
		fr: fr.mentor_dashboard_guarantees_refundRate_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_guarantees_canBeMoved = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_guarantees_canBeMoved,
		fr: fr.mentor_dashboard_guarantees_canBeMoved
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_guarantees_minimumHoursToMove = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_guarantees_minimumHoursToMove,
		fr: fr.mentor_dashboard_guarantees_minimumHoursToMove
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_guarantees_minimumHoursToMove_placeholder = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_guarantees_minimumHoursToMove_placeholder,
		fr: fr.mentor_dashboard_guarantees_minimumHoursToMove_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_guarantees_numberOfDaysAfterInitial = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_guarantees_numberOfDaysAfterInitial,
		fr: fr.mentor_dashboard_guarantees_numberOfDaysAfterInitial
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_guarantees_numberOfDaysAfterInitial_placeholder = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_guarantees_numberOfDaysAfterInitial_placeholder,
		fr: fr.mentor_dashboard_guarantees_numberOfDaysAfterInitial_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_guarantees_maximumChangeCall = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_guarantees_maximumChangeCall,
		fr: fr.mentor_dashboard_guarantees_maximumChangeCall
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_guarantees_maximumChangeCall_placeholder = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_guarantees_maximumChangeCall_placeholder,
		fr: fr.mentor_dashboard_guarantees_maximumChangeCall_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_revenue_title = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_revenue_title,
		fr: fr.mentor_dashboard_revenue_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_revenue_see_details = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_revenue_see_details,
		fr: fr.mentor_dashboard_revenue_see_details
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_personal_referent_title = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_personal_referent_title,
		fr: fr.mentor_dashboard_personal_referent_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_personal_referent_description = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_personal_referent_description,
		fr: fr.mentor_dashboard_personal_referent_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_personal_referent_yourReferent = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_personal_referent_yourReferent,
		fr: fr.mentor_dashboard_personal_referent_yourReferent
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_personal_referent_contact = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_personal_referent_contact,
		fr: fr.mentor_dashboard_personal_referent_contact
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_informations_updated = (params = {}, options = {}) => {
	return {
		en: en.mentor_informations_updated,
		fr: fr.mentor_informations_updated
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_maximum_change_call_invalid = (params = {}, options = {}) => {
	return {
		en: en.mentor_maximum_change_call_invalid,
		fr: fr.mentor_maximum_change_call_invalid
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_reservationInvalidForMonday_egual = (params = {}, options = {}) => {
	return {
		en: en.mentor_reservationInvalidForMonday_egual,
		fr: fr.mentor_reservationInvalidForMonday_egual
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_reservationInvalidForMonday_firstGreaterThandSecond = (params = {}, options = {}) => {
	return {
		en: en.mentor_reservationInvalidForMonday_firstGreaterThandSecond,
		fr: fr.mentor_reservationInvalidForMonday_firstGreaterThandSecond
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_reservationInvalidForTuesday_egual = (params = {}, options = {}) => {
	return {
		en: en.mentor_reservationInvalidForTuesday_egual,
		fr: fr.mentor_reservationInvalidForTuesday_egual
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_reservationInvalidForTuesday_firstGreaterThandSecond = (params = {}, options = {}) => {
	return {
		en: en.mentor_reservationInvalidForTuesday_firstGreaterThandSecond,
		fr: fr.mentor_reservationInvalidForTuesday_firstGreaterThandSecond
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_reservationInvalidForWednesday_egual = (params = {}, options = {}) => {
	return {
		en: en.mentor_reservationInvalidForWednesday_egual,
		fr: fr.mentor_reservationInvalidForWednesday_egual
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_reservationInvalidForWednesday_firstGreaterThandSecond = (params = {}, options = {}) => {
	return {
		en: en.mentor_reservationInvalidForWednesday_firstGreaterThandSecond,
		fr: fr.mentor_reservationInvalidForWednesday_firstGreaterThandSecond
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_reservationInvalidForThursday_egual = (params = {}, options = {}) => {
	return {
		en: en.mentor_reservationInvalidForThursday_egual,
		fr: fr.mentor_reservationInvalidForThursday_egual
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_reservationInvalidForThursday_firstGreaterThandSecond = (params = {}, options = {}) => {
	return {
		en: en.mentor_reservationInvalidForThursday_firstGreaterThandSecond,
		fr: fr.mentor_reservationInvalidForThursday_firstGreaterThandSecond
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_reservationInvalidForFriday_egual = (params = {}, options = {}) => {
	return {
		en: en.mentor_reservationInvalidForFriday_egual,
		fr: fr.mentor_reservationInvalidForFriday_egual
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_reservationInvalidForFriday_firstGreaterThandSecond = (params = {}, options = {}) => {
	return {
		en: en.mentor_reservationInvalidForFriday_firstGreaterThandSecond,
		fr: fr.mentor_reservationInvalidForFriday_firstGreaterThandSecond
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_reservationInvalidForSaturday_egual = (params = {}, options = {}) => {
	return {
		en: en.mentor_reservationInvalidForSaturday_egual,
		fr: fr.mentor_reservationInvalidForSaturday_egual
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_reservationInvalidForSaturday_firstGreaterThandSecond = (params = {}, options = {}) => {
	return {
		en: en.mentor_reservationInvalidForSaturday_firstGreaterThandSecond,
		fr: fr.mentor_reservationInvalidForSaturday_firstGreaterThandSecond
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_reservationInvalidForSunday_egual = (params = {}, options = {}) => {
	return {
		en: en.mentor_reservationInvalidForSunday_egual,
		fr: fr.mentor_reservationInvalidForSunday_egual
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_reservationInvalidForSunday_firstGreaterThandSecond = (params = {}, options = {}) => {
	return {
		en: en.mentor_reservationInvalidForSunday_firstGreaterThandSecond,
		fr: fr.mentor_reservationInvalidForSunday_firstGreaterThandSecond
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_code_price_invalid = (params = {}, options = {}) => {
	return {
		en: en.mentor_code_price_invalid,
		fr: fr.mentor_code_price_invalid
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_code_price_negative = (params = {}, options = {}) => {
	return {
		en: en.mentor_code_price_negative,
		fr: fr.mentor_code_price_negative
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_code_maxUses_invalid = (params = {}, options = {}) => {
	return {
		en: en.mentor_code_maxUses_invalid,
		fr: fr.mentor_code_maxUses_invalid
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_code_limitDate_invalid = (params = {}, options = {}) => {
	return {
		en: en.mentor_code_limitDate_invalid,
		fr: fr.mentor_code_limitDate_invalid
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_code_name_maxLenght_invalid = (params = {}, options = {}) => {
	return {
		en: en.mentor_code_name_maxLenght_invalid,
		fr: fr.mentor_code_name_maxLenght_invalid
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_code_created = (params = {}, options = {}) => {
	return {
		en: en.mentor_code_created,
		fr: fr.mentor_code_created
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_google_disconnect_askConfirmation = (params = {}, options = {}) => {
	return {
		en: en.mentor_google_disconnect_askConfirmation,
		fr: fr.mentor_google_disconnect_askConfirmation
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_google_disconnect_success = (params = {}, options = {}) => {
	return {
		en: en.mentor_google_disconnect_success,
		fr: fr.mentor_google_disconnect_success
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_google_connected = (params = {}, options = {}) => {
	return {
		en: en.mentor_google_connected,
		fr: fr.mentor_google_connected
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_google_error = (params = {}, options = {}) => {
	return {
		en: en.mentor_google_error,
		fr: fr.mentor_google_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_code_archived = (params = {}, options = {}) => {
	return {
		en: en.mentor_code_archived,
		fr: fr.mentor_code_archived
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_code_unarchived = (params = {}, options = {}) => {
	return {
		en: en.mentor_code_unarchived,
		fr: fr.mentor_code_unarchived
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const thumbnail_type_error = (params = {}, options = {}) => {
	return {
		en: en.thumbnail_type_error,
		fr: fr.thumbnail_type_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_picture_error = (params = {}, options = {}) => {
	return {
		en: en.mentor_picture_error,
		fr: fr.mentor_picture_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_picture_updated = (params = {}, options = {}) => {
	return {
		en: en.mentor_picture_updated,
		fr: fr.mentor_picture_updated
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_stripe_account_verified = (params = {}, options = {}) => {
	return {
		en: en.mentor_stripe_account_verified,
		fr: fr.mentor_stripe_account_verified
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_coming_calls_title = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_coming_calls_title,
		fr: fr.mentor_dashboard_coming_calls_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_coming_calls_empty = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_coming_calls_empty,
		fr: fr.mentor_dashboard_coming_calls_empty
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_toEnd_calls_title = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_toEnd_calls_title,
		fr: fr.mentor_dashboard_toEnd_calls_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_past_calls_title = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_past_calls_title,
		fr: fr.mentor_dashboard_past_calls_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_past_calls_empty = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_past_calls_empty,
		fr: fr.mentor_dashboard_past_calls_empty
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_confirm_paid = (params = {}, options = {}) => {
	return {
		en: en.Call_confirm_paid,
		fr: fr.Call_confirm_paid
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_paid_success = (params = {}, options = {}) => {
	return {
		en: en.Call_paid_success,
		fr: fr.Call_paid_success
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_with = (params = {}, options = {}) => {
	return {
		en: en.Call_with,
		fr: fr.Call_with
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_yourRevenue = (params = {}, options = {}) => {
	return {
		en: en.Call_yourRevenue,
		fr: fr.Call_yourRevenue
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_revenue = (params = {}, options = {}) => {
	return {
		en: en.Call_revenue,
		fr: fr.Call_revenue
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_inclVAT = (params = {}, options = {}) => {
	return {
		en: en.Call_inclVAT,
		fr: fr.Call_inclVAT
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_exclVAT = (params = {}, options = {}) => {
	return {
		en: en.Call_exclVAT,
		fr: fr.Call_exclVAT
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_codeUsed = (params = {}, options = {}) => {
	return {
		en: en.Call_codeUsed,
		fr: fr.Call_codeUsed
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_paid_at = (params = {}, options = {}) => {
	return {
		en: en.Call_paid_at,
		fr: fr.Call_paid_at
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_payment_pending = (params = {}, options = {}) => {
	return {
		en: en.Call_payment_pending,
		fr: fr.Call_payment_pending
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_hide_details = (params = {}, options = {}) => {
	return {
		en: en.Call_hide_details,
		fr: fr.Call_hide_details
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_show_details = (params = {}, options = {}) => {
	return {
		en: en.Call_show_details,
		fr: fr.Call_show_details
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_mark_paid = (params = {}, options = {}) => {
	return {
		en: en.Call_mark_paid,
		fr: fr.Call_mark_paid
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_check_call = (params = {}, options = {}) => {
	return {
		en: en.Call_check_call,
		fr: fr.Call_check_call
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_total_paid = (params = {}, options = {}) => {
	return {
		en: en.Call_total_paid,
		fr: fr.Call_total_paid
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ percent: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_banking_fees_percent = (params , options = {}) => {
	return {
		en: en.Call_banking_fees_percent,
		fr: fr.Call_banking_fees_percent
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ percent: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_commission_believemy = (params , options = {}) => {
	return {
		en: en.Call_commission_believemy,
		fr: fr.Call_commission_believemy
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_commission_bankingFees = (params = {}, options = {}) => {
	return {
		en: en.Call_commission_bankingFees,
		fr: fr.Call_commission_bankingFees
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_original_revenue = (params = {}, options = {}) => {
	return {
		en: en.Call_original_revenue,
		fr: fr.Call_original_revenue
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ percent: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_revenue_after_refund = (params , options = {}) => {
	return {
		en: en.Call_revenue_after_refund,
		fr: fr.Call_revenue_after_refund
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_refund_amount = (params = {}, options = {}) => {
	return {
		en: en.Call_refund_amount,
		fr: fr.Call_refund_amount
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ 'percent %': NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_refund_by_you = (params , options = {}) => {
	return {
		en: en.Call_refund_by_you,
		fr: fr.Call_refund_by_you
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ 'percent %': NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_refund_by_believemy = (params , options = {}) => {
	return {
		en: en.Call_refund_by_believemy,
		fr: fr.Call_refund_by_believemy
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_final_revenue = (params = {}, options = {}) => {
	return {
		en: en.Call_final_revenue,
		fr: fr.Call_final_revenue
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_cancelled = (params = {}, options = {}) => {
	return {
		en: en.Call_cancelled,
		fr: fr.Call_cancelled
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_title = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_codes_title,
		fr: fr.mentor_dashboard_codes_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_create = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_codes_create,
		fr: fr.mentor_dashboard_codes_create
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_form_name = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_codes_form_name,
		fr: fr.mentor_dashboard_codes_form_name
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_form_name_placeholder = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_codes_form_name_placeholder,
		fr: fr.mentor_dashboard_codes_form_name_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_form_maxUses = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_codes_form_maxUses,
		fr: fr.mentor_dashboard_codes_form_maxUses
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_form_maxUses_placeholder = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_codes_form_maxUses_placeholder,
		fr: fr.mentor_dashboard_codes_form_maxUses_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_form_type = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_codes_form_type,
		fr: fr.mentor_dashboard_codes_form_type
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_form_type_price = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_codes_form_type_price,
		fr: fr.mentor_dashboard_codes_form_type_price
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_form_type_free = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_codes_form_type_free,
		fr: fr.mentor_dashboard_codes_form_type_free
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_form_price = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_codes_form_price,
		fr: fr.mentor_dashboard_codes_form_price
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_form_price_placeholder = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_codes_form_price_placeholder,
		fr: fr.mentor_dashboard_codes_form_price_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_form_limitDate = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_codes_form_limitDate,
		fr: fr.mentor_dashboard_codes_form_limitDate
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_form_limitDate_placeholder = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_codes_form_limitDate_placeholder,
		fr: fr.mentor_dashboard_codes_form_limitDate_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_form_button_create = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_codes_form_button_create,
		fr: fr.mentor_dashboard_codes_form_button_create
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_active = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_codes_active,
		fr: fr.mentor_dashboard_codes_active
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_expired = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_codes_expired,
		fr: fr.mentor_dashboard_codes_expired
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_valid = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_codes_valid,
		fr: fr.mentor_dashboard_codes_valid
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_free = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_codes_free,
		fr: fr.mentor_dashboard_codes_free
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_valid_until = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_codes_valid_until,
		fr: fr.mentor_dashboard_codes_valid_until
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const clipboard_copied = (params = {}, options = {}) => {
	return {
		en: en.clipboard_copied,
		fr: fr.clipboard_copied
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_copy = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_codes_copy,
		fr: fr.mentor_dashboard_codes_copy
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_disable = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_codes_disable,
		fr: fr.mentor_dashboard_codes_disable
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_enable = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_codes_enable,
		fr: fr.mentor_dashboard_codes_enable
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_disabled = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_codes_disabled,
		fr: fr.mentor_dashboard_codes_disabled
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_empty = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_codes_empty,
		fr: fr.mentor_dashboard_codes_empty
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_aside_title = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_codes_aside_title,
		fr: fr.mentor_dashboard_codes_aside_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_aside_description1 = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_codes_aside_description1,
		fr: fr.mentor_dashboard_codes_aside_description1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_aside_description2 = (params = {}, options = {}) => {
	return {
		en: en.mentor_dashboard_codes_aside_description2,
		fr: fr.mentor_dashboard_codes_aside_description2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ title: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_meta_title = (params , options = {}) => {
	return {
		en: en.writer_article_meta_title,
		fr: fr.writer_article_meta_title
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_meta_description = (params = {}, options = {}) => {
	return {
		en: en.writer_article_meta_description,
		fr: fr.writer_article_meta_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_form_description = (params = {}, options = {}) => {
	return {
		en: en.writer_form_description,
		fr: fr.writer_form_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_updated_successfully = (params = {}, options = {}) => {
	return {
		en: en.writer_article_updated_successfully,
		fr: fr.writer_article_updated_successfully
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_language_required = (params = {}, options = {}) => {
	return {
		en: en.writer_article_language_required,
		fr: fr.writer_article_language_required
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_translation_for_this_language_already_exists = (params = {}, options = {}) => {
	return {
		en: en.writer_article_translation_for_this_language_already_exists,
		fr: fr.writer_article_translation_for_this_language_already_exists
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_title_required = (params = {}, options = {}) => {
	return {
		en: en.writer_article_title_required,
		fr: fr.writer_article_title_required
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_created_successfully = (params = {}, options = {}) => {
	return {
		en: en.writer_article_created_successfully,
		fr: fr.writer_article_created_successfully
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_error_occured = (params = {}, options = {}) => {
	return {
		en: en.writer_article_error_occured,
		fr: fr.writer_article_error_occured
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_thumbnail_updated = (params = {}, options = {}) => {
	return {
		en: en.writer_article_thumbnail_updated,
		fr: fr.writer_article_thumbnail_updated
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_send_article = (params = {}, options = {}) => {
	return {
		en: en.writer_article_send_article,
		fr: fr.writer_article_send_article
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_sent_successfully = (params = {}, options = {}) => {
	return {
		en: en.writer_article_sent_successfully,
		fr: fr.writer_article_sent_successfully
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const please_verify_your_email = (params = {}, options = {}) => {
	return {
		en: en.please_verify_your_email,
		fr: fr.please_verify_your_email
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_about_this_article = (params = {}, options = {}) => {
	return {
		en: en.writer_article_about_this_article,
		fr: fr.writer_article_about_this_article
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_createdAt = (params = {}, options = {}) => {
	return {
		en: en.writer_article_createdAt,
		fr: fr.writer_article_createdAt
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_updatedAt = (params = {}, options = {}) => {
	return {
		en: en.writer_article_updatedAt,
		fr: fr.writer_article_updatedAt
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_translations = (params = {}, options = {}) => {
	return {
		en: en.writer_article_translations,
		fr: fr.writer_article_translations
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_update_title = (params = {}, options = {}) => {
	return {
		en: en.writer_article_update_title,
		fr: fr.writer_article_update_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_update_description = (params = {}, options = {}) => {
	return {
		en: en.writer_article_update_description,
		fr: fr.writer_article_update_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_update_content = (params = {}, options = {}) => {
	return {
		en: en.writer_article_update_content,
		fr: fr.writer_article_update_content
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_update_slug = (params = {}, options = {}) => {
	return {
		en: en.writer_article_update_slug,
		fr: fr.writer_article_update_slug
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_cancel_edition = (params = {}, options = {}) => {
	return {
		en: en.writer_article_cancel_edition,
		fr: fr.writer_article_cancel_edition
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_close = (params = {}, options = {}) => {
	return {
		en: en.writer_article_close,
		fr: fr.writer_article_close
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_update_button = (params = {}, options = {}) => {
	return {
		en: en.writer_article_update_button,
		fr: fr.writer_article_update_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ date: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_waiting_for_validation = (params , options = {}) => {
	return {
		en: en.writer_article_waiting_for_validation,
		fr: fr.writer_article_waiting_for_validation
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_edit_link = (params = {}, options = {}) => {
	return {
		en: en.writer_article_edit_link,
		fr: fr.writer_article_edit_link
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_send_validation = (params = {}, options = {}) => {
	return {
		en: en.writer_article_send_validation,
		fr: fr.writer_article_send_validation
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_badge_online = (params = {}, options = {}) => {
	return {
		en: en.writer_article_badge_online,
		fr: fr.writer_article_badge_online
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_badge_waiting_validation = (params = {}, options = {}) => {
	return {
		en: en.writer_article_badge_waiting_validation,
		fr: fr.writer_article_badge_waiting_validation
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_badge_writing = (params = {}, options = {}) => {
	return {
		en: en.writer_article_badge_writing,
		fr: fr.writer_article_badge_writing
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ fullname: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_author = (params , options = {}) => {
	return {
		en: en.writer_article_author,
		fr: fr.writer_article_author
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_look_online = (params = {}, options = {}) => {
	return {
		en: en.writer_article_look_online,
		fr: fr.writer_article_look_online
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_previsualisation = (params = {}, options = {}) => {
	return {
		en: en.writer_article_previsualisation,
		fr: fr.writer_article_previsualisation
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_no_translations = (params = {}, options = {}) => {
	return {
		en: en.writer_article_no_translations,
		fr: fr.writer_article_no_translations
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_add_new_translation = (params = {}, options = {}) => {
	return {
		en: en.writer_article_add_new_translation,
		fr: fr.writer_article_add_new_translation
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_add_translation_language = (params = {}, options = {}) => {
	return {
		en: en.writer_article_add_translation_language,
		fr: fr.writer_article_add_translation_language
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_select_language = (params = {}, options = {}) => {
	return {
		en: en.writer_article_select_language,
		fr: fr.writer_article_select_language
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_add_translation_title = (params = {}, options = {}) => {
	return {
		en: en.writer_article_add_translation_title,
		fr: fr.writer_article_add_translation_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_create_translation_button = (params = {}, options = {}) => {
	return {
		en: en.writer_article_create_translation_button,
		fr: fr.writer_article_create_translation_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_add_translation_button = (params = {}, options = {}) => {
	return {
		en: en.writer_article_add_translation_button,
		fr: fr.writer_article_add_translation_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_waiting_title = (params = {}, options = {}) => {
	return {
		en: en.writer_waiting_title,
		fr: fr.writer_waiting_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ date: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ArticleDate_updated_at = (params , options = {}) => {
	return {
		en: en.ArticleDate_updated_at,
		fr: fr.ArticleDate_updated_at
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const article_categories = (params = {}, options = {}) => {
	return {
		en: en.article_categories,
		fr: fr.article_categories
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const article_category = (params = {}, options = {}) => {
	return {
		en: en.article_category,
		fr: fr.article_category
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const translateCategories_developers = (params = {}, options = {}) => {
	return {
		en: en.translateCategories_developers,
		fr: fr.translateCategories_developers
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const translateCategories_designers = (params = {}, options = {}) => {
	return {
		en: en.translateCategories_designers,
		fr: fr.translateCategories_designers
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const translateCategories_marketers = (params = {}, options = {}) => {
	return {
		en: en.translateCategories_marketers,
		fr: fr.translateCategories_marketers
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const translateCategories_entrepreneurs = (params = {}, options = {}) => {
	return {
		en: en.translateCategories_entrepreneurs,
		fr: fr.translateCategories_entrepreneurs
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const translateCategories_other = (params = {}, options = {}) => {
	return {
		en: en.translateCategories_other,
		fr: fr.translateCategories_other
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CreatorCard_link_to_profile = (params = {}, options = {}) => {
	return {
		en: en.CreatorCard_link_to_profile,
		fr: fr.CreatorCard_link_to_profile
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const article_loading = (params = {}, options = {}) => {
	return {
		en: en.article_loading,
		fr: fr.article_loading
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Comments_title = (params = {}, options = {}) => {
	return {
		en: en.Comments_title,
		fr: fr.Comments_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Comments_shouldBeAuthToComment = (params = {}, options = {}) => {
	return {
		en: en.Comments_shouldBeAuthToComment,
		fr: fr.Comments_shouldBeAuthToComment
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Comments_shouldBeAuthToComment_login = (params = {}, options = {}) => {
	return {
		en: en.Comments_shouldBeAuthToComment_login,
		fr: fr.Comments_shouldBeAuthToComment_login
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Comments_shouldBeAuthToComment_or = (params = {}, options = {}) => {
	return {
		en: en.Comments_shouldBeAuthToComment_or,
		fr: fr.Comments_shouldBeAuthToComment_or
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Comments_shouldBeAuthToComment_register = (params = {}, options = {}) => {
	return {
		en: en.Comments_shouldBeAuthToComment_register,
		fr: fr.Comments_shouldBeAuthToComment_register
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddComment_failedToAddComment = (params = {}, options = {}) => {
	return {
		en: en.AddComment_failedToAddComment,
		fr: fr.AddComment_failedToAddComment
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddComment_commentAddedSuccessfully = (params = {}, options = {}) => {
	return {
		en: en.AddComment_commentAddedSuccessfully,
		fr: fr.AddComment_commentAddedSuccessfully
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddComment_cancel = (params = {}, options = {}) => {
	return {
		en: en.AddComment_cancel,
		fr: fr.AddComment_cancel
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddComment_submit = (params = {}, options = {}) => {
	return {
		en: en.AddComment_submit,
		fr: fr.AddComment_submit
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Comment_reply = (params = {}, options = {}) => {
	return {
		en: en.Comment_reply,
		fr: fr.Comment_reply
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EndCTA_default_title = (params = {}, options = {}) => {
	return {
		en: en.EndCTA_default_title,
		fr: fr.EndCTA_default_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EndCTA_default_description = (params = {}, options = {}) => {
	return {
		en: en.EndCTA_default_description,
		fr: fr.EndCTA_default_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EndCTA_default_button = (params = {}, options = {}) => {
	return {
		en: en.EndCTA_default_button,
		fr: fr.EndCTA_default_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EndCTA_join_movement_title = (params = {}, options = {}) => {
	return {
		en: en.EndCTA_join_movement_title,
		fr: fr.EndCTA_join_movement_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EndCTA_join_movement_description_1 = (params = {}, options = {}) => {
	return {
		en: en.EndCTA_join_movement_description_1,
		fr: fr.EndCTA_join_movement_description_1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EndCTA_join_movement_description_2 = (params = {}, options = {}) => {
	return {
		en: en.EndCTA_join_movement_description_2,
		fr: fr.EndCTA_join_movement_description_2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EndCTA_join_movement_button = (params = {}, options = {}) => {
	return {
		en: en.EndCTA_join_movement_button,
		fr: fr.EndCTA_join_movement_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EndCTA_mentoring_title = (params = {}, options = {}) => {
	return {
		en: en.EndCTA_mentoring_title,
		fr: fr.EndCTA_mentoring_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EndCTA_mentoring_description_1 = (params = {}, options = {}) => {
	return {
		en: en.EndCTA_mentoring_description_1,
		fr: fr.EndCTA_mentoring_description_1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EndCTA_mentoring_description_2 = (params = {}, options = {}) => {
	return {
		en: en.EndCTA_mentoring_description_2,
		fr: fr.EndCTA_mentoring_description_2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EndCTA_mentoring_button = (params = {}, options = {}) => {
	return {
		en: en.EndCTA_mentoring_button,
		fr: fr.EndCTA_mentoring_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EndCTA_apprenticeship_title = (params = {}, options = {}) => {
	return {
		en: en.EndCTA_apprenticeship_title,
		fr: fr.EndCTA_apprenticeship_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EndCTA_apprenticeship_description_1 = (params = {}, options = {}) => {
	return {
		en: en.EndCTA_apprenticeship_description_1,
		fr: fr.EndCTA_apprenticeship_description_1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EndCTA_apprenticeship_description_2 = (params = {}, options = {}) => {
	return {
		en: en.EndCTA_apprenticeship_description_2,
		fr: fr.EndCTA_apprenticeship_description_2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EndCTA_apprenticeship_button_1 = (params = {}, options = {}) => {
	return {
		en: en.EndCTA_apprenticeship_button_1,
		fr: fr.EndCTA_apprenticeship_button_1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EndCTA_apprenticeship_button_2 = (params = {}, options = {}) => {
	return {
		en: en.EndCTA_apprenticeship_button_2,
		fr: fr.EndCTA_apprenticeship_button_2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_badge_validated_but_not_online = (params = {}, options = {}) => {
	return {
		en: en.writer_article_badge_validated_but_not_online,
		fr: fr.writer_article_badge_validated_but_not_online
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_validated_but_not_online_title = (params = {}, options = {}) => {
	return {
		en: en.writer_validated_but_not_online_title,
		fr: fr.writer_validated_but_not_online_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_update_video = (params = {}, options = {}) => {
	return {
		en: en.writer_article_update_video,
		fr: fr.writer_article_update_video
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_NOT_AUTHOR = (params = {}, options = {}) => {
	return {
		en: en.error_NOT_AUTHOR,
		fr: fr.error_NOT_AUTHOR
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_categories_title = (params = {}, options = {}) => {
	return {
		en: en.writer_article_categories_title,
		fr: fr.writer_article_categories_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_categories_description = (params = {}, options = {}) => {
	return {
		en: en.writer_article_categories_description,
		fr: fr.writer_article_categories_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_input_placeholder = (params = {}, options = {}) => {
	return {
		en: en.writer_article_input_placeholder,
		fr: fr.writer_article_input_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_remove_category = (params = {}, options = {}) => {
	return {
		en: en.writer_article_remove_category,
		fr: fr.writer_article_remove_category
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_add_category = (params = {}, options = {}) => {
	return {
		en: en.writer_article_add_category,
		fr: fr.writer_article_add_category
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_error_addCategoryName = (params = {}, options = {}) => {
	return {
		en: en.writer_article_error_addCategoryName,
		fr: fr.writer_article_error_addCategoryName
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_error_reservedName = (params = {}, options = {}) => {
	return {
		en: en.writer_article_error_reservedName,
		fr: fr.writer_article_error_reservedName
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_categories_updated = (params = {}, options = {}) => {
	return {
		en: en.writer_article_categories_updated,
		fr: fr.writer_article_categories_updated
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_meta_title = (params = {}, options = {}) => {
	return {
		en: en.instructor_meta_title,
		fr: fr.instructor_meta_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_meta_description = (params = {}, options = {}) => {
	return {
		en: en.instructor_meta_description,
		fr: fr.instructor_meta_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_title = (params = {}, options = {}) => {
	return {
		en: en.instructor_title,
		fr: fr.instructor_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_headline = (params = {}, options = {}) => {
	return {
		en: en.instructor_headline,
		fr: fr.instructor_headline
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_nav_courses = (params = {}, options = {}) => {
	return {
		en: en.instructor_nav_courses,
		fr: fr.instructor_nav_courses
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_nav_learners = (params = {}, options = {}) => {
	return {
		en: en.instructor_nav_learners,
		fr: fr.instructor_nav_learners
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_statistics_title = (params = {}, options = {}) => {
	return {
		en: en.instructor_statistics_title,
		fr: fr.instructor_statistics_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_mainChart_revenues = (params = {}, options = {}) => {
	return {
		en: en.instructor_mainChart_revenues,
		fr: fr.instructor_mainChart_revenues
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_mainChart_learners = (params = {}, options = {}) => {
	return {
		en: en.instructor_mainChart_learners,
		fr: fr.instructor_mainChart_learners
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_mainChart_minutesConsumed = (params = {}, options = {}) => {
	return {
		en: en.instructor_mainChart_minutesConsumed,
		fr: fr.instructor_mainChart_minutesConsumed
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_course_created = (params = {}, options = {}) => {
	return {
		en: en.instructor_course_created,
		fr: fr.instructor_course_created
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_no_courses_title = (params = {}, options = {}) => {
	return {
		en: en.instructor_no_courses_title,
		fr: fr.instructor_no_courses_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_no_courses_description = (params = {}, options = {}) => {
	return {
		en: en.instructor_no_courses_description,
		fr: fr.instructor_no_courses_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_add_course_button = (params = {}, options = {}) => {
	return {
		en: en.instructor_add_course_button,
		fr: fr.instructor_add_course_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_aside_statistics_title = (params = {}, options = {}) => {
	return {
		en: en.instructor_aside_statistics_title,
		fr: fr.instructor_aside_statistics_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ count: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_numberOfStudents = (params , options = {}) => {
	return {
		en: en.instructor_numberOfStudents,
		fr: fr.instructor_numberOfStudents
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_one_student = (params = {}, options = {}) => {
	return {
		en: en.instructor_one_student,
		fr: fr.instructor_one_student
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ count: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_numberOfCourses = (params , options = {}) => {
	return {
		en: en.instructor_numberOfCourses,
		fr: fr.instructor_numberOfCourses
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_one_course = (params = {}, options = {}) => {
	return {
		en: en.instructor_one_course,
		fr: fr.instructor_one_course
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ count: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_lifetimeRevenues = (params , options = {}) => {
	return {
		en: en.instructor_lifetimeRevenues,
		fr: fr.instructor_lifetimeRevenues
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ count: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_lifetimeMins = (params , options = {}) => {
	return {
		en: en.instructor_lifetimeMins,
		fr: fr.instructor_lifetimeMins
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_aside_coming_revenues_title = (params = {}, options = {}) => {
	return {
		en: en.instructor_aside_coming_revenues_title,
		fr: fr.instructor_aside_coming_revenues_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_students_title = (params = {}, options = {}) => {
	return {
		en: en.instructor_students_title,
		fr: fr.instructor_students_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_students_followed_courses = (params = {}, options = {}) => {
	return {
		en: en.instructor_students_followed_courses,
		fr: fr.instructor_students_followed_courses
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_students_no_students = (params = {}, options = {}) => {
	return {
		en: en.instructor_students_no_students,
		fr: fr.instructor_students_no_students
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_cancel = (params = {}, options = {}) => {
	return {
		en: en.instructor_cancel,
		fr: fr.instructor_cancel
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_new_course_title = (params = {}, options = {}) => {
	return {
		en: en.instructor_new_course_title,
		fr: fr.instructor_new_course_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_new_course_title_placeholder = (params = {}, options = {}) => {
	return {
		en: en.instructor_new_course_title_placeholder,
		fr: fr.instructor_new_course_title_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_new_course_title_required = (params = {}, options = {}) => {
	return {
		en: en.instructor_new_course_title_required,
		fr: fr.instructor_new_course_title_required
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_new_course_subtitle = (params = {}, options = {}) => {
	return {
		en: en.instructor_new_course_subtitle,
		fr: fr.instructor_new_course_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_new_course_subtitle_placeholder = (params = {}, options = {}) => {
	return {
		en: en.instructor_new_course_subtitle_placeholder,
		fr: fr.instructor_new_course_subtitle_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_new_course_subtitle_required = (params = {}, options = {}) => {
	return {
		en: en.instructor_new_course_subtitle_required,
		fr: fr.instructor_new_course_subtitle_required
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_new_course_description = (params = {}, options = {}) => {
	return {
		en: en.instructor_new_course_description,
		fr: fr.instructor_new_course_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_new_course_prerequisites = (params = {}, options = {}) => {
	return {
		en: en.instructor_new_course_prerequisites,
		fr: fr.instructor_new_course_prerequisites
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_new_course_button = (params = {}, options = {}) => {
	return {
		en: en.instructor_new_course_button,
		fr: fr.instructor_new_course_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_course_updated = (params = {}, options = {}) => {
	return {
		en: en.instructor_course_updated,
		fr: fr.instructor_course_updated
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_sociale_type_error = (params = {}, options = {}) => {
	return {
		en: en.instructor_sociale_type_error,
		fr: fr.instructor_sociale_type_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_error_occured = (params = {}, options = {}) => {
	return {
		en: en.instructor_error_occured,
		fr: fr.instructor_error_occured
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_updated_successfully = (params = {}, options = {}) => {
	return {
		en: en.instructor_updated_successfully,
		fr: fr.instructor_updated_successfully
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_storyart_type_error = (params = {}, options = {}) => {
	return {
		en: en.instructor_storyart_type_error,
		fr: fr.instructor_storyart_type_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_logo_type_error = (params = {}, options = {}) => {
	return {
		en: en.instructor_logo_type_error,
		fr: fr.instructor_logo_type_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_video_required = (params = {}, options = {}) => {
	return {
		en: en.instructor_video_required,
		fr: fr.instructor_video_required
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_video_delete_error = (params = {}, options = {}) => {
	return {
		en: en.instructor_video_delete_error,
		fr: fr.instructor_video_delete_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_course_sended_for_validation = (params = {}, options = {}) => {
	return {
		en: en.instructor_course_sended_for_validation,
		fr: fr.instructor_course_sended_for_validation
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_nav_presentation = (params = {}, options = {}) => {
	return {
		en: en.instructor_nav_presentation,
		fr: fr.instructor_nav_presentation
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_nav_summary = (params = {}, options = {}) => {
	return {
		en: en.instructor_nav_summary,
		fr: fr.instructor_nav_summary
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_nav_settings = (params = {}, options = {}) => {
	return {
		en: en.instructor_nav_settings,
		fr: fr.instructor_nav_settings
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_nav_sendForValidation = (params = {}, options = {}) => {
	return {
		en: en.instructor_nav_sendForValidation,
		fr: fr.instructor_nav_sendForValidation
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_nav_preview = (params = {}, options = {}) => {
	return {
		en: en.instructor_nav_preview,
		fr: fr.instructor_nav_preview
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_presentation = (params = {}, options = {}) => {
	return {
		en: en.instructor_presentation,
		fr: fr.instructor_presentation
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_presentation_form_title = (params = {}, options = {}) => {
	return {
		en: en.instructor_presentation_form_title,
		fr: fr.instructor_presentation_form_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_presentation_form_title_placeholder = (params = {}, options = {}) => {
	return {
		en: en.instructor_presentation_form_title_placeholder,
		fr: fr.instructor_presentation_form_title_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_presentation_form_title_required = (params = {}, options = {}) => {
	return {
		en: en.instructor_presentation_form_title_required,
		fr: fr.instructor_presentation_form_title_required
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_presentation_form_subtitle = (params = {}, options = {}) => {
	return {
		en: en.instructor_presentation_form_subtitle,
		fr: fr.instructor_presentation_form_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_presentation_form_subtitle_placeholder = (params = {}, options = {}) => {
	return {
		en: en.instructor_presentation_form_subtitle_placeholder,
		fr: fr.instructor_presentation_form_subtitle_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_presentation_form_subtitle_required = (params = {}, options = {}) => {
	return {
		en: en.instructor_presentation_form_subtitle_required,
		fr: fr.instructor_presentation_form_subtitle_required
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_presentation_form_description = (params = {}, options = {}) => {
	return {
		en: en.instructor_presentation_form_description,
		fr: fr.instructor_presentation_form_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_presentation_form_description_placeholder = (params = {}, options = {}) => {
	return {
		en: en.instructor_presentation_form_description_placeholder,
		fr: fr.instructor_presentation_form_description_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_presentation_form_prerequisites = (params = {}, options = {}) => {
	return {
		en: en.instructor_presentation_form_prerequisites,
		fr: fr.instructor_presentation_form_prerequisites
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_presentation_form_prerequisites_placeholder = (params = {}, options = {}) => {
	return {
		en: en.instructor_presentation_form_prerequisites_placeholder,
		fr: fr.instructor_presentation_form_prerequisites_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_button_save = (params = {}, options = {}) => {
	return {
		en: en.instructor_button_save,
		fr: fr.instructor_button_save
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_branding_title = (params = {}, options = {}) => {
	return {
		en: en.instructor_branding_title,
		fr: fr.instructor_branding_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_branding_logo_title = (params = {}, options = {}) => {
	return {
		en: en.instructor_branding_logo_title,
		fr: fr.instructor_branding_logo_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_branding_logo_description = (params = {}, options = {}) => {
	return {
		en: en.instructor_branding_logo_description,
		fr: fr.instructor_branding_logo_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_branding_thumbnail_title = (params = {}, options = {}) => {
	return {
		en: en.instructor_branding_thumbnail_title,
		fr: fr.instructor_branding_thumbnail_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_branding_thumbnail_description = (params = {}, options = {}) => {
	return {
		en: en.instructor_branding_thumbnail_description,
		fr: fr.instructor_branding_thumbnail_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_branding_storyart_title = (params = {}, options = {}) => {
	return {
		en: en.instructor_branding_storyart_title,
		fr: fr.instructor_branding_storyart_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_branding_storyart_description = (params = {}, options = {}) => {
	return {
		en: en.instructor_branding_storyart_description,
		fr: fr.instructor_branding_storyart_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_video_in_process = (params = {}, options = {}) => {
	return {
		en: en.instructor_video_in_process,
		fr: fr.instructor_video_in_process
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_branding_video_title = (params = {}, options = {}) => {
	return {
		en: en.instructor_branding_video_title,
		fr: fr.instructor_branding_video_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_branding_video_description = (params = {}, options = {}) => {
	return {
		en: en.instructor_branding_video_description,
		fr: fr.instructor_branding_video_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_summary_title = (params = {}, options = {}) => {
	return {
		en: en.instructor_summary_title,
		fr: fr.instructor_summary_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_summary_description = (params = {}, options = {}) => {
	return {
		en: en.instructor_summary_description,
		fr: fr.instructor_summary_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddPart_title_empty = (params = {}, options = {}) => {
	return {
		en: en.AddPart_title_empty,
		fr: fr.AddPart_title_empty
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddPart_part_created = (params = {}, options = {}) => {
	return {
		en: en.AddPart_part_created,
		fr: fr.AddPart_part_created
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddPart_quiz_created = (params = {}, options = {}) => {
	return {
		en: en.AddPart_quiz_created,
		fr: fr.AddPart_quiz_created
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddPart_formation_empty = (params = {}, options = {}) => {
	return {
		en: en.AddPart_formation_empty,
		fr: fr.AddPart_formation_empty
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddPart_formation_added_with_program = (params = {}, options = {}) => {
	return {
		en: en.AddPart_formation_added_with_program,
		fr: fr.AddPart_formation_added_with_program
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddPart_title = (params = {}, options = {}) => {
	return {
		en: en.AddPart_title,
		fr: fr.AddPart_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddPart_title_placeholder = (params = {}, options = {}) => {
	return {
		en: en.AddPart_title_placeholder,
		fr: fr.AddPart_title_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddPart_add_content_button = (params = {}, options = {}) => {
	return {
		en: en.AddPart_add_content_button,
		fr: fr.AddPart_add_content_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddPart_quiz_title = (params = {}, options = {}) => {
	return {
		en: en.AddPart_quiz_title,
		fr: fr.AddPart_quiz_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddPart_quiz_description_placeholder = (params = {}, options = {}) => {
	return {
		en: en.AddPart_quiz_description_placeholder,
		fr: fr.AddPart_quiz_description_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddPart_add_quiz_button = (params = {}, options = {}) => {
	return {
		en: en.AddPart_add_quiz_button,
		fr: fr.AddPart_add_quiz_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddPart_formation_select = (params = {}, options = {}) => {
	return {
		en: en.AddPart_formation_select,
		fr: fr.AddPart_formation_select
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddPart_add_formation_button = (params = {}, options = {}) => {
	return {
		en: en.AddPart_add_formation_button,
		fr: fr.AddPart_add_formation_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddPart_select_type = (params = {}, options = {}) => {
	return {
		en: en.AddPart_select_type,
		fr: fr.AddPart_select_type
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddPart_type_content_title = (params = {}, options = {}) => {
	return {
		en: en.AddPart_type_content_title,
		fr: fr.AddPart_type_content_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddPart_type_content_description = (params = {}, options = {}) => {
	return {
		en: en.AddPart_type_content_description,
		fr: fr.AddPart_type_content_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddPart_type_quiz_title = (params = {}, options = {}) => {
	return {
		en: en.AddPart_type_quiz_title,
		fr: fr.AddPart_type_quiz_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddPart_type_quiz_description = (params = {}, options = {}) => {
	return {
		en: en.AddPart_type_quiz_description,
		fr: fr.AddPart_type_quiz_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddPart_next = (params = {}, options = {}) => {
	return {
		en: en.AddPart_next,
		fr: fr.AddPart_next
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddPart_add_part = (params = {}, options = {}) => {
	return {
		en: en.AddPart_add_part,
		fr: fr.AddPart_add_part
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_summary_empty = (params = {}, options = {}) => {
	return {
		en: en.instructor_summary_empty,
		fr: fr.instructor_summary_empty
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_parameters_title = (params = {}, options = {}) => {
	return {
		en: en.instructor_parameters_title,
		fr: fr.instructor_parameters_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_parameters_description = (params = {}, options = {}) => {
	return {
		en: en.instructor_parameters_description,
		fr: fr.instructor_parameters_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_parameters_duration = (params = {}, options = {}) => {
	return {
		en: en.instructor_parameters_duration,
		fr: fr.instructor_parameters_duration
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_parameters_duration_placeholder = (params = {}, options = {}) => {
	return {
		en: en.instructor_parameters_duration_placeholder,
		fr: fr.instructor_parameters_duration_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_parameters_language = (params = {}, options = {}) => {
	return {
		en: en.instructor_parameters_language,
		fr: fr.instructor_parameters_language
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_parameters_language_placeholder = (params = {}, options = {}) => {
	return {
		en: en.instructor_parameters_language_placeholder,
		fr: fr.instructor_parameters_language_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_parameters_subtitles = (params = {}, options = {}) => {
	return {
		en: en.instructor_parameters_subtitles,
		fr: fr.instructor_parameters_subtitles
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_parameters_subtitles_placeholder = (params = {}, options = {}) => {
	return {
		en: en.instructor_parameters_subtitles_placeholder,
		fr: fr.instructor_parameters_subtitles_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_parameters_has_4Kresolution = (params = {}, options = {}) => {
	return {
		en: en.instructor_parameters_has_4Kresolution,
		fr: fr.instructor_parameters_has_4Kresolution
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_parameters_has_4Kresolution_yes = (params = {}, options = {}) => {
	return {
		en: en.instructor_parameters_has_4Kresolution_yes,
		fr: fr.instructor_parameters_has_4Kresolution_yes
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_parameters_has_4Kresolution_no = (params = {}, options = {}) => {
	return {
		en: en.instructor_parameters_has_4Kresolution_no,
		fr: fr.instructor_parameters_has_4Kresolution_no
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ date: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_parameters_is_up_to_date = (params , options = {}) => {
	return {
		en: en.instructor_parameters_is_up_to_date,
		fr: fr.instructor_parameters_is_up_to_date
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_parameters_is_up_to_date_yes = (params = {}, options = {}) => {
	return {
		en: en.instructor_parameters_is_up_to_date_yes,
		fr: fr.instructor_parameters_is_up_to_date_yes
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_parameters_is_up_to_date_no = (params = {}, options = {}) => {
	return {
		en: en.instructor_parameters_is_up_to_date_no,
		fr: fr.instructor_parameters_is_up_to_date_no
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_parameters_instructor = (params = {}, options = {}) => {
	return {
		en: en.instructor_parameters_instructor,
		fr: fr.instructor_parameters_instructor
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_send_for_evaluation_title = (params = {}, options = {}) => {
	return {
		en: en.instructor_send_for_evaluation_title,
		fr: fr.instructor_send_for_evaluation_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ date: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_send_for_evaluation_description = (params , options = {}) => {
	return {
		en: en.instructor_send_for_evaluation_description,
		fr: fr.instructor_send_for_evaluation_description
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_send_for_evaluation_description2 = (params = {}, options = {}) => {
	return {
		en: en.instructor_send_for_evaluation_description2,
		fr: fr.instructor_send_for_evaluation_description2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_send_for_evaluation_description3 = (params = {}, options = {}) => {
	return {
		en: en.instructor_send_for_evaluation_description3,
		fr: fr.instructor_send_for_evaluation_description3
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_send_for_evaluation_description4 = (params = {}, options = {}) => {
	return {
		en: en.instructor_send_for_evaluation_description4,
		fr: fr.instructor_send_for_evaluation_description4
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_send_for_evaluation_description5 = (params = {}, options = {}) => {
	return {
		en: en.instructor_send_for_evaluation_description5,
		fr: fr.instructor_send_for_evaluation_description5
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_button_send_for_evaluation = (params = {}, options = {}) => {
	return {
		en: en.instructor_button_send_for_evaluation,
		fr: fr.instructor_button_send_for_evaluation
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_title_required = (params = {}, options = {}) => {
	return {
		en: en.Part_title_required,
		fr: fr.Part_title_required
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_updated = (params = {}, options = {}) => {
	return {
		en: en.Part_updated,
		fr: fr.Part_updated
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_delete_ask_confirmation = (params = {}, options = {}) => {
	return {
		en: en.Part_delete_ask_confirmation,
		fr: fr.Part_delete_ask_confirmation
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_deleted = (params = {}, options = {}) => {
	return {
		en: en.Part_deleted,
		fr: fr.Part_deleted
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_generic_error = (params = {}, options = {}) => {
	return {
		en: en.Part_generic_error,
		fr: fr.Part_generic_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_summary_placeholder = (params = {}, options = {}) => {
	return {
		en: en.Part_summary_placeholder,
		fr: fr.Part_summary_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_description_for_quiz_placeholder = (params = {}, options = {}) => {
	return {
		en: en.Part_description_for_quiz_placeholder,
		fr: fr.Part_description_for_quiz_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_should_plan_this_part = (params = {}, options = {}) => {
	return {
		en: en.Part_should_plan_this_part,
		fr: fr.Part_should_plan_this_part
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_yes = (params = {}, options = {}) => {
	return {
		en: en.Part_yes,
		fr: fr.Part_yes
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_no = (params = {}, options = {}) => {
	return {
		en: en.Part_no,
		fr: fr.Part_no
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_should_plan_this_part_for_week = (params = {}, options = {}) => {
	return {
		en: en.Part_should_plan_this_part_for_week,
		fr: fr.Part_should_plan_this_part_for_week
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_week_example = (params = {}, options = {}) => {
	return {
		en: en.Part_week_example,
		fr: fr.Part_week_example
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_module = (params = {}, options = {}) => {
	return {
		en: en.Part_module,
		fr: fr.Part_module
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_module_placeholder = (params = {}, options = {}) => {
	return {
		en: en.Part_module_placeholder,
		fr: fr.Part_module_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_week = (params = {}, options = {}) => {
	return {
		en: en.Part_week,
		fr: fr.Part_week
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_should_be_included_in_accelerators = (params = {}, options = {}) => {
	return {
		en: en.Part_should_be_included_in_accelerators,
		fr: fr.Part_should_be_included_in_accelerators
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_delete = (params = {}, options = {}) => {
	return {
		en: en.Part_delete,
		fr: fr.Part_delete
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_takeOff = (params = {}, options = {}) => {
	return {
		en: en.Part_takeOff,
		fr: fr.Part_takeOff
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_putOnline = (params = {}, options = {}) => {
	return {
		en: en.Part_putOnline,
		fr: fr.Part_putOnline
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_restrict_attempts = (params = {}, options = {}) => {
	return {
		en: en.Part_restrict_attempts,
		fr: fr.Part_restrict_attempts
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_without_restrictions = (params = {}, options = {}) => {
	return {
		en: en.Part_without_restrictions,
		fr: fr.Part_without_restrictions
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_save = (params = {}, options = {}) => {
	return {
		en: en.Part_save,
		fr: fr.Part_save
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ number: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_number = (params , options = {}) => {
	return {
		en: en.Part_number,
		fr: fr.Part_number
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_notPublished = (params = {}, options = {}) => {
	return {
		en: en.Part_notPublished,
		fr: fr.Part_notPublished
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddSession_title_empty = (params = {}, options = {}) => {
	return {
		en: en.AddSession_title_empty,
		fr: fr.AddSession_title_empty
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddSession_should_have_one_correct_answer_for_quiz = (params = {}, options = {}) => {
	return {
		en: en.AddSession_should_have_one_correct_answer_for_quiz,
		fr: fr.AddSession_should_have_one_correct_answer_for_quiz
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddSession_add_quiz = (params = {}, options = {}) => {
	return {
		en: en.AddSession_add_quiz,
		fr: fr.AddSession_add_quiz
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddSession_add_session = (params = {}, options = {}) => {
	return {
		en: en.AddSession_add_session,
		fr: fr.AddSession_add_session
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddSession_question_placeholder = (params = {}, options = {}) => {
	return {
		en: en.AddSession_question_placeholder,
		fr: fr.AddSession_question_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddSession_quiz_answers = (params = {}, options = {}) => {
	return {
		en: en.AddSession_quiz_answers,
		fr: fr.AddSession_quiz_answers
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ number: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddSession_answer_number = (params , options = {}) => {
	return {
		en: en.AddSession_answer_number,
		fr: fr.AddSession_answer_number
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddSession_explanation_placeholder = (params = {}, options = {}) => {
	return {
		en: en.AddSession_explanation_placeholder,
		fr: fr.AddSession_explanation_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddSession_you_can_add_other_answers = (params = {}, options = {}) => {
	return {
		en: en.AddSession_you_can_add_other_answers,
		fr: fr.AddSession_you_can_add_other_answers
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddSession_add_another_answer = (params = {}, options = {}) => {
	return {
		en: en.AddSession_add_another_answer,
		fr: fr.AddSession_add_another_answer
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddSession_add_question_button = (params = {}, options = {}) => {
	return {
		en: en.AddSession_add_question_button,
		fr: fr.AddSession_add_question_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddSession_title_placeholder = (params = {}, options = {}) => {
	return {
		en: en.AddSession_title_placeholder,
		fr: fr.AddSession_title_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddSession_add_session_button = (params = {}, options = {}) => {
	return {
		en: en.AddSession_add_session_button,
		fr: fr.AddSession_add_session_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_page_description_required = (params = {}, options = {}) => {
	return {
		en: en.instructor_page_description_required,
		fr: fr.instructor_page_description_required
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_title_cannot_be_empty = (params = {}, options = {}) => {
	return {
		en: en.Session_title_cannot_be_empty,
		fr: fr.Session_title_cannot_be_empty
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_updated = (params = {}, options = {}) => {
	return {
		en: en.Session_updated,
		fr: fr.Session_updated
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_at_least_one_correct_answer = (params = {}, options = {}) => {
	return {
		en: en.Session_at_least_one_correct_answer,
		fr: fr.Session_at_least_one_correct_answer
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_error_occured = (params = {}, options = {}) => {
	return {
		en: en.Session_error_occured,
		fr: fr.Session_error_occured
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_ask_confirm_deletion = (params = {}, options = {}) => {
	return {
		en: en.Session_ask_confirm_deletion,
		fr: fr.Session_ask_confirm_deletion
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_deleted = (params = {}, options = {}) => {
	return {
		en: en.Session_deleted,
		fr: fr.Session_deleted
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_error_occured_deleting_video = (params = {}, options = {}) => {
	return {
		en: en.Session_error_occured_deleting_video,
		fr: fr.Session_error_occured_deleting_video
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_no_video_selected = (params = {}, options = {}) => {
	return {
		en: en.Session_no_video_selected,
		fr: fr.Session_no_video_selected
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_ask_confirm_deletion_video = (params = {}, options = {}) => {
	return {
		en: en.Session_ask_confirm_deletion_video,
		fr: fr.Session_ask_confirm_deletion_video
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_video_deleted = (params = {}, options = {}) => {
	return {
		en: en.Session_video_deleted,
		fr: fr.Session_video_deleted
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ number: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_question_number = (params , options = {}) => {
	return {
		en: en.Session_question_number,
		fr: fr.Session_question_number
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_free_access = (params = {}, options = {}) => {
	return {
		en: en.Session_free_access,
		fr: fr.Session_free_access
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_offline = (params = {}, options = {}) => {
	return {
		en: en.Session_offline,
		fr: fr.Session_offline
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_question_title = (params = {}, options = {}) => {
	return {
		en: en.Session_question_title,
		fr: fr.Session_question_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_question_title_placeholder = (params = {}, options = {}) => {
	return {
		en: en.Session_question_title_placeholder,
		fr: fr.Session_question_title_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_question_answers = (params = {}, options = {}) => {
	return {
		en: en.Session_question_answers,
		fr: fr.Session_question_answers
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ number: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_answer_number = (params , options = {}) => {
	return {
		en: en.Session_answer_number,
		fr: fr.Session_answer_number
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_explanation_placeholder = (params = {}, options = {}) => {
	return {
		en: en.Session_explanation_placeholder,
		fr: fr.Session_explanation_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_you_can_add_other_answers = (params = {}, options = {}) => {
	return {
		en: en.Session_you_can_add_other_answers,
		fr: fr.Session_you_can_add_other_answers
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_add_another_answer = (params = {}, options = {}) => {
	return {
		en: en.Session_add_another_answer,
		fr: fr.Session_add_another_answer
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_delete = (params = {}, options = {}) => {
	return {
		en: en.Session_delete,
		fr: fr.Session_delete
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_make_quiz_not_free = (params = {}, options = {}) => {
	return {
		en: en.Session_make_quiz_not_free,
		fr: fr.Session_make_quiz_not_free
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_make_quiz_free = (params = {}, options = {}) => {
	return {
		en: en.Session_make_quiz_free,
		fr: fr.Session_make_quiz_free
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_takeOff = (params = {}, options = {}) => {
	return {
		en: en.Session_takeOff,
		fr: fr.Session_takeOff
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_putOnline = (params = {}, options = {}) => {
	return {
		en: en.Session_putOnline,
		fr: fr.Session_putOnline
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_save = (params = {}, options = {}) => {
	return {
		en: en.Session_save,
		fr: fr.Session_save
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_title = (params = {}, options = {}) => {
	return {
		en: en.Session_title,
		fr: fr.Session_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_content = (params = {}, options = {}) => {
	return {
		en: en.Session_content,
		fr: fr.Session_content
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_content_placeholder = (params = {}, options = {}) => {
	return {
		en: en.Session_content_placeholder,
		fr: fr.Session_content_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_video_in_process = (params = {}, options = {}) => {
	return {
		en: en.Session_video_in_process,
		fr: fr.Session_video_in_process
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_video = (params = {}, options = {}) => {
	return {
		en: en.Session_video,
		fr: fr.Session_video
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_update = (params = {}, options = {}) => {
	return {
		en: en.Session_update,
		fr: fr.Session_update
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_add = (params = {}, options = {}) => {
	return {
		en: en.Session_add,
		fr: fr.Session_add
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_make_session_not_free = (params = {}, options = {}) => {
	return {
		en: en.Session_make_session_not_free,
		fr: fr.Session_make_session_not_free
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_make_session_free = (params = {}, options = {}) => {
	return {
		en: en.Session_make_session_free,
		fr: fr.Session_make_session_free
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const courses_title = (params = {}, options = {}) => {
	return {
		en: en.courses_title,
		fr: fr.courses_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const courses_headline = (params = {}, options = {}) => {
	return {
		en: en.courses_headline,
		fr: fr.courses_headline
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const courses_meta_title = (params = {}, options = {}) => {
	return {
		en: en.courses_meta_title,
		fr: fr.courses_meta_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const courses_meta_description = (params = {}, options = {}) => {
	return {
		en: en.courses_meta_description,
		fr: fr.courses_meta_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const courses_languageNotSupportedYet = (params = {}, options = {}) => {
	return {
		en: en.courses_languageNotSupportedYet,
		fr: fr.courses_languageNotSupportedYet
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const courses_courseNotInYourLanguage = (params = {}, options = {}) => {
	return {
		en: en.courses_courseNotInYourLanguage,
		fr: fr.courses_courseNotInYourLanguage
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const courses_noCourses = (params = {}, options = {}) => {
	return {
		en: en.courses_noCourses,
		fr: fr.courses_noCourses
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const courses_noCoursesMessage = (params = {}, options = {}) => {
	return {
		en: en.courses_noCoursesMessage,
		fr: fr.courses_noCoursesMessage
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const courses_noCoursesMessageContactUs = (params = {}, options = {}) => {
	return {
		en: en.courses_noCoursesMessageContactUs,
		fr: fr.courses_noCoursesMessageContactUs
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const courses_noCoursesMessageOr = (params = {}, options = {}) => {
	return {
		en: en.courses_noCoursesMessageOr,
		fr: fr.courses_noCoursesMessageOr
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const courses_noCoursesMessageBecomeInstructor = (params = {}, options = {}) => {
	return {
		en: en.courses_noCoursesMessageBecomeInstructor,
		fr: fr.courses_noCoursesMessageBecomeInstructor
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CourseVideoHeader_formation = (params = {}, options = {}) => {
	return {
		en: en.CourseVideoHeader_formation,
		fr: fr.CourseVideoHeader_formation
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CourseVideoHeader_entrepreneurs = (params = {}, options = {}) => {
	return {
		en: en.CourseVideoHeader_entrepreneurs,
		fr: fr.CourseVideoHeader_entrepreneurs
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CourseVideoHeader_developers = (params = {}, options = {}) => {
	return {
		en: en.CourseVideoHeader_developers,
		fr: fr.CourseVideoHeader_developers
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CourseVideoHeader_start_button_label = (params = {}, options = {}) => {
	return {
		en: en.CourseVideoHeader_start_button_label,
		fr: fr.CourseVideoHeader_start_button_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_presentation_title = (params = {}, options = {}) => {
	return {
		en: en.course_presentation_title,
		fr: fr.course_presentation_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_plan_title = (params = {}, options = {}) => {
	return {
		en: en.course_plan_title,
		fr: fr.course_plan_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ number: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const LookPart_title = (params , options = {}) => {
	return {
		en: en.LookPart_title,
		fr: fr.LookPart_title
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const LookPart_preview = (params = {}, options = {}) => {
	return {
		en: en.LookPart_preview,
		fr: fr.LookPart_preview
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_instructor_title = (params = {}, options = {}) => {
	return {
		en: en.course_instructor_title,
		fr: fr.course_instructor_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_prerequisite_title = (params = {}, options = {}) => {
	return {
		en: en.course_prerequisite_title,
		fr: fr.course_prerequisite_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_up_to_date = (params = {}, options = {}) => {
	return {
		en: en.course_up_to_date,
		fr: fr.course_up_to_date
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ count: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_number_interactive_quizs = (params , options = {}) => {
	return {
		en: en.course_number_interactive_quizs,
		fr: fr.course_number_interactive_quizs
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_number_interactive_quiz = (params = {}, options = {}) => {
	return {
		en: en.course_number_interactive_quiz,
		fr: fr.course_number_interactive_quiz
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_category = (params = {}, options = {}) => {
	return {
		en: en.course_category,
		fr: fr.course_category
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_no_category = (params = {}, options = {}) => {
	return {
		en: en.course_no_category,
		fr: fr.course_no_category
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_duration = (params = {}, options = {}) => {
	return {
		en: en.course_duration,
		fr: fr.course_duration
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_level = (params = {}, options = {}) => {
	return {
		en: en.course_level,
		fr: fr.course_level
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_allLevels = (params = {}, options = {}) => {
	return {
		en: en.course_allLevels,
		fr: fr.course_allLevels
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_language = (params = {}, options = {}) => {
	return {
		en: en.course_language,
		fr: fr.course_language
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_subtitles = (params = {}, options = {}) => {
	return {
		en: en.course_subtitles,
		fr: fr.course_subtitles
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_no_subtitles = (params = {}, options = {}) => {
	return {
		en: en.course_no_subtitles,
		fr: fr.course_no_subtitles
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_resolution = (params = {}, options = {}) => {
	return {
		en: en.course_resolution,
		fr: fr.course_resolution
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_fullHD = (params = {}, options = {}) => {
	return {
		en: en.course_fullHD,
		fr: fr.course_fullHD
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_4K = (params = {}, options = {}) => {
	return {
		en: en.course_4K,
		fr: fr.course_4K
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BuyCourse_button = (params = {}, options = {}) => {
	return {
		en: en.BuyCourse_button,
		fr: fr.BuyCourse_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_course_title = (params = {}, options = {}) => {
	return {
		en: en.course_course_title,
		fr: fr.course_course_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const courses_filters_title = (params = {}, options = {}) => {
	return {
		en: en.courses_filters_title,
		fr: fr.courses_filters_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const courses_filters_all = (params = {}, options = {}) => {
	return {
		en: en.courses_filters_all,
		fr: fr.courses_filters_all
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const courses_filters_developers = (params = {}, options = {}) => {
	return {
		en: en.courses_filters_developers,
		fr: fr.courses_filters_developers
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const courses_filters_entrepreneurs = (params = {}, options = {}) => {
	return {
		en: en.courses_filters_entrepreneurs,
		fr: fr.courses_filters_entrepreneurs
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const courses_filters_designers = (params = {}, options = {}) => {
	return {
		en: en.courses_filters_designers,
		fr: fr.courses_filters_designers
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const courses_where_to_start = (params = {}, options = {}) => {
	return {
		en: en.courses_where_to_start,
		fr: fr.courses_where_to_start
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_levels_all = (params = {}, options = {}) => {
	return {
		en: en.course_levels_all,
		fr: fr.course_levels_all
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ count: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_testimonialsCount = (params , options = {}) => {
	return {
		en: en.course_testimonialsCount,
		fr: fr.course_testimonialsCount
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ count: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_number_of_students = (params , options = {}) => {
	return {
		en: en.course_number_of_students,
		fr: fr.course_number_of_students
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_what_you_will_done = (params = {}, options = {}) => {
	return {
		en: en.course_what_you_will_done,
		fr: fr.course_what_you_will_done
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_summary = (params = {}, options = {}) => {
	return {
		en: en.course_summary,
		fr: fr.course_summary
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ number: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_projectNumber = (params , options = {}) => {
	return {
		en: en.course_projectNumber,
		fr: fr.course_projectNumber
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ number: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ShowPart_partNumber = (params , options = {}) => {
	return {
		en: en.ShowPart_partNumber,
		fr: fr.ShowPart_partNumber
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ShowPart_interactiveQuiz = (params = {}, options = {}) => {
	return {
		en: en.ShowPart_interactiveQuiz,
		fr: fr.ShowPart_interactiveQuiz
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ShowPart_challenge_question = (params = {}, options = {}) => {
	return {
		en: en.ShowPart_challenge_question,
		fr: fr.ShowPart_challenge_question
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ number: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ShowPart_challenge_questions = (params , options = {}) => {
	return {
		en: en.ShowPart_challenge_questions,
		fr: fr.ShowPart_challenge_questions
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_certification_title = (params = {}, options = {}) => {
	return {
		en: en.course_certification_title,
		fr: fr.course_certification_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ subject: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_certification_description_subject = (params , options = {}) => {
	return {
		en: en.course_certification_description_subject,
		fr: fr.course_certification_description_subject
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_certification_description_subject_for_hostinger = (params = {}, options = {}) => {
	return {
		en: en.course_certification_description_subject_for_hostinger,
		fr: fr.course_certification_description_subject_for_hostinger
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ subject: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_certification_image_alt_subject = (params , options = {}) => {
	return {
		en: en.course_certification_image_alt_subject,
		fr: fr.course_certification_image_alt_subject
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ date: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_up_to_date = (params , options = {}) => {
	return {
		en: en.course_content_up_to_date,
		fr: fr.course_content_up_to_date
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_language_name = (params = {}, options = {}) => {
	return {
		en: en.course_language_name,
		fr: fr.course_language_name
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_shareable_certification = (params = {}, options = {}) => {
	return {
		en: en.course_content_shareable_certification,
		fr: fr.course_content_shareable_certification
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_mentors_assistance = (params = {}, options = {}) => {
	return {
		en: en.course_content_mentors_assistance,
		fr: fr.course_content_mentors_assistance
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_sourceCode_available = (params = {}, options = {}) => {
	return {
		en: en.course_content_sourceCode_available,
		fr: fr.course_content_sourceCode_available
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ subject: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_testimonials_subject = (params , options = {}) => {
	return {
		en: en.course_testimonials_subject,
		fr: fr.course_testimonials_subject
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ count: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_testimonialsCountWithPlatforms = (params , options = {}) => {
	return {
		en: en.course_testimonialsCountWithPlatforms,
		fr: fr.course_testimonialsCountWithPlatforms
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_prerequisite_htmlandcss = (params = {}, options = {}) => {
	return {
		en: en.course_prerequisite_htmlandcss,
		fr: fr.course_prerequisite_htmlandcss
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_prerequisite_javascript = (params = {}, options = {}) => {
	return {
		en: en.course_prerequisite_javascript,
		fr: fr.course_prerequisite_javascript
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_prerequisite_php = (params = {}, options = {}) => {
	return {
		en: en.course_prerequisite_php,
		fr: fr.course_prerequisite_php
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_prerequisite_react = (params = {}, options = {}) => {
	return {
		en: en.course_prerequisite_react,
		fr: fr.course_prerequisite_react
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_testimonial_verifiedByTrustpilot = (params = {}, options = {}) => {
	return {
		en: en.course_testimonial_verifiedByTrustpilot,
		fr: fr.course_testimonial_verifiedByTrustpilot
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_instructor = (params = {}, options = {}) => {
	return {
		en: en.course_instructor,
		fr: fr.course_instructor
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faqs = (params = {}, options = {}) => {
	return {
		en: en.course_faqs,
		fr: fr.course_faqs
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ name: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_ourArticlesAboutThis = (params , options = {}) => {
	return {
		en: en.course_ourArticlesAboutThis,
		fr: fr.course_ourArticlesAboutThis
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_ourArticlesAboutThisSubject = (params = {}, options = {}) => {
	return {
		en: en.course_ourArticlesAboutThisSubject,
		fr: fr.course_ourArticlesAboutThisSubject
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_related = (params = {}, options = {}) => {
	return {
		en: en.course_related,
		fr: fr.course_related
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_breadcrumb_home = (params = {}, options = {}) => {
	return {
		en: en.course_breadcrumb_home,
		fr: fr.course_breadcrumb_home
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_breadcrumb_courses = (params = {}, options = {}) => {
	return {
		en: en.course_breadcrumb_courses,
		fr: fr.course_breadcrumb_courses
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_breadcrumb_articles = (params = {}, options = {}) => {
	return {
		en: en.course_breadcrumb_articles,
		fr: fr.course_breadcrumb_articles
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_breadcrumb_this_page = (params = {}, options = {}) => {
	return {
		en: en.course_breadcrumb_this_page,
		fr: fr.course_breadcrumb_this_page
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_summary_show_confidential = (params = {}, options = {}) => {
	return {
		en: en.course_summary_show_confidential,
		fr: fr.course_summary_show_confidential
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const WaitCourse_soon = (params = {}, options = {}) => {
	return {
		en: en.WaitCourse_soon,
		fr: fr.WaitCourse_soon
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const WaitCourse_alert = (params = {}, options = {}) => {
	return {
		en: en.WaitCourse_alert,
		fr: fr.WaitCourse_alert
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const WaitCourse_sign_tobe_alerted = (params = {}, options = {}) => {
	return {
		en: en.WaitCourse_sign_tobe_alerted,
		fr: fr.WaitCourse_sign_tobe_alerted
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whatIsReact = (params = {}, options = {}) => {
	return {
		en: en.course_faq_whatIsReact,
		fr: fr.course_faq_whatIsReact
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whatIsReact_answer = (params = {}, options = {}) => {
	return {
		en: en.course_faq_whatIsReact_answer,
		fr: fr.course_faq_whatIsReact_answer
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whatIsHTMLCSS = (params = {}, options = {}) => {
	return {
		en: en.course_faq_whatIsHTMLCSS,
		fr: fr.course_faq_whatIsHTMLCSS
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whatIsHTMLCSS_answer_1 = (params = {}, options = {}) => {
	return {
		en: en.course_faq_whatIsHTMLCSS_answer_1,
		fr: fr.course_faq_whatIsHTMLCSS_answer_1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whatIsHTMLCSS_answer_2 = (params = {}, options = {}) => {
	return {
		en: en.course_faq_whatIsHTMLCSS_answer_2,
		fr: fr.course_faq_whatIsHTMLCSS_answer_2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whatIsPython = (params = {}, options = {}) => {
	return {
		en: en.course_faq_whatIsPython,
		fr: fr.course_faq_whatIsPython
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whatIsPython_answer_1 = (params = {}, options = {}) => {
	return {
		en: en.course_faq_whatIsPython_answer_1,
		fr: fr.course_faq_whatIsPython_answer_1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whatIsPython_answer_2 = (params = {}, options = {}) => {
	return {
		en: en.course_faq_whatIsPython_answer_2,
		fr: fr.course_faq_whatIsPython_answer_2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whatIsNODE = (params = {}, options = {}) => {
	return {
		en: en.course_faq_whatIsNODE,
		fr: fr.course_faq_whatIsNODE
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whatIsNODE_answer_1 = (params = {}, options = {}) => {
	return {
		en: en.course_faq_whatIsNODE_answer_1,
		fr: fr.course_faq_whatIsNODE_answer_1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whatIsNODE_answer_2 = (params = {}, options = {}) => {
	return {
		en: en.course_faq_whatIsNODE_answer_2,
		fr: fr.course_faq_whatIsNODE_answer_2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whatIsANGULAR = (params = {}, options = {}) => {
	return {
		en: en.course_faq_whatIsANGULAR,
		fr: fr.course_faq_whatIsANGULAR
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whatIsANGULAR_answer_1 = (params = {}, options = {}) => {
	return {
		en: en.course_faq_whatIsANGULAR_answer_1,
		fr: fr.course_faq_whatIsANGULAR_answer_1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whatIsANGULAR_answer_2 = (params = {}, options = {}) => {
	return {
		en: en.course_faq_whatIsANGULAR_answer_2,
		fr: fr.course_faq_whatIsANGULAR_answer_2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whatIsJAVASCRIPT = (params = {}, options = {}) => {
	return {
		en: en.course_faq_whatIsJAVASCRIPT,
		fr: fr.course_faq_whatIsJAVASCRIPT
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whatIsJAVASCRIPT_answer_1 = (params = {}, options = {}) => {
	return {
		en: en.course_faq_whatIsJAVASCRIPT_answer_1,
		fr: fr.course_faq_whatIsJAVASCRIPT_answer_1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whatIsJAVASCRIPT_answer_2 = (params = {}, options = {}) => {
	return {
		en: en.course_faq_whatIsJAVASCRIPT_answer_2,
		fr: fr.course_faq_whatIsJAVASCRIPT_answer_2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_timeForAll = (params = {}, options = {}) => {
	return {
		en: en.course_faq_timeForAll,
		fr: fr.course_faq_timeForAll
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ time: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_timeForAll_answer = (params , options = {}) => {
	return {
		en: en.course_faq_timeForAll_answer,
		fr: fr.course_faq_timeForAll_answer
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_calendar = (params = {}, options = {}) => {
	return {
		en: en.course_faq_calendar,
		fr: fr.course_faq_calendar
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_calendar_answer = (params = {}, options = {}) => {
	return {
		en: en.course_faq_calendar_answer,
		fr: fr.course_faq_calendar_answer
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_level = (params = {}, options = {}) => {
	return {
		en: en.course_faq_level,
		fr: fr.course_faq_level
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ subject: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_level_answer_1_subject = (params , options = {}) => {
	return {
		en: en.course_faq_level_answer_1_subject,
		fr: fr.course_faq_level_answer_1_subject
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ subject: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_level_answer_2_subject = (params , options = {}) => {
	return {
		en: en.course_faq_level_answer_2_subject,
		fr: fr.course_faq_level_answer_2_subject
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_level_answer_3_subject = (params = {}, options = {}) => {
	return {
		en: en.course_faq_level_answer_3_subject,
		fr: fr.course_faq_level_answer_3_subject
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_questions = (params = {}, options = {}) => {
	return {
		en: en.course_faq_questions,
		fr: fr.course_faq_questions
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_questions_answer_1 = (params = {}, options = {}) => {
	return {
		en: en.course_faq_questions_answer_1,
		fr: fr.course_faq_questions_answer_1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_questions_answer_2 = (params = {}, options = {}) => {
	return {
		en: en.course_faq_questions_answer_2,
		fr: fr.course_faq_questions_answer_2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_certification = (params = {}, options = {}) => {
	return {
		en: en.course_faq_certification,
		fr: fr.course_faq_certification
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_certification_answer = (params = {}, options = {}) => {
	return {
		en: en.course_faq_certification_answer,
		fr: fr.course_faq_certification_answer
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_uptodate = (params = {}, options = {}) => {
	return {
		en: en.course_faq_uptodate,
		fr: fr.course_faq_uptodate
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ subject: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_uptodate_answer_subject = (params , options = {}) => {
	return {
		en: en.course_faq_uptodate_answer_subject,
		fr: fr.course_faq_uptodate_answer_subject
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ subject: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whyLearnWithAIDominatingTheWorld_subject = (params , options = {}) => {
	return {
		en: en.course_faq_whyLearnWithAIDominatingTheWorld_subject,
		fr: fr.course_faq_whyLearnWithAIDominatingTheWorld_subject
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whyLearnWithAIDominatingTheWorld_answer_1 = (params = {}, options = {}) => {
	return {
		en: en.course_faq_whyLearnWithAIDominatingTheWorld_answer_1,
		fr: fr.course_faq_whyLearnWithAIDominatingTheWorld_answer_1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whyLearnWithAIDominatingTheWorld_answer_2 = (params = {}, options = {}) => {
	return {
		en: en.course_faq_whyLearnWithAIDominatingTheWorld_answer_2,
		fr: fr.course_faq_whyLearnWithAIDominatingTheWorld_answer_2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whyLearnWithAIDominatingTheWorld_answer_3 = (params = {}, options = {}) => {
	return {
		en: en.course_faq_whyLearnWithAIDominatingTheWorld_answer_3,
		fr: fr.course_faq_whyLearnWithAIDominatingTheWorld_answer_3
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whatIsTypescript = (params = {}, options = {}) => {
	return {
		en: en.course_faq_whatIsTypescript,
		fr: fr.course_faq_whatIsTypescript
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whatIsTypescript_answer_1 = (params = {}, options = {}) => {
	return {
		en: en.course_faq_whatIsTypescript_answer_1,
		fr: fr.course_faq_whatIsTypescript_answer_1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whatIsTypescript_answer_2 = (params = {}, options = {}) => {
	return {
		en: en.course_faq_whatIsTypescript_answer_2,
		fr: fr.course_faq_whatIsTypescript_answer_2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whatIsSymfony = (params = {}, options = {}) => {
	return {
		en: en.course_faq_whatIsSymfony,
		fr: fr.course_faq_whatIsSymfony
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whatIsSymfony_answer_1 = (params = {}, options = {}) => {
	return {
		en: en.course_faq_whatIsSymfony_answer_1,
		fr: fr.course_faq_whatIsSymfony_answer_1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whatIsSymfony_answer_2 = (params = {}, options = {}) => {
	return {
		en: en.course_faq_whatIsSymfony_answer_2,
		fr: fr.course_faq_whatIsSymfony_answer_2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whatIsNextJS = (params = {}, options = {}) => {
	return {
		en: en.course_faq_whatIsNextJS,
		fr: fr.course_faq_whatIsNextJS
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whatIsNextJS_answer_1 = (params = {}, options = {}) => {
	return {
		en: en.course_faq_whatIsNextJS_answer_1,
		fr: fr.course_faq_whatIsNextJS_answer_1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whatIsNextJS_answer_2 = (params = {}, options = {}) => {
	return {
		en: en.course_faq_whatIsNextJS_answer_2,
		fr: fr.course_faq_whatIsNextJS_answer_2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const articles_meta_title = (params = {}, options = {}) => {
	return {
		en: en.articles_meta_title,
		fr: fr.articles_meta_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const articles_meta_description = (params = {}, options = {}) => {
	return {
		en: en.articles_meta_description,
		fr: fr.articles_meta_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const articles_title = (params = {}, options = {}) => {
	return {
		en: en.articles_title,
		fr: fr.articles_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const articles_headline = (params = {}, options = {}) => {
	return {
		en: en.articles_headline,
		fr: fr.articles_headline
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const articles_write = (params = {}, options = {}) => {
	return {
		en: en.articles_write,
		fr: fr.articles_write
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const articles_noArticles = (params = {}, options = {}) => {
	return {
		en: en.articles_noArticles,
		fr: fr.articles_noArticles
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const articles_noArticlesMessage = (params = {}, options = {}) => {
	return {
		en: en.articles_noArticlesMessage,
		fr: fr.articles_noArticlesMessage
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const articles_noArticlesMessageBecomeWriter = (params = {}, options = {}) => {
	return {
		en: en.articles_noArticlesMessageBecomeWriter,
		fr: fr.articles_noArticlesMessageBecomeWriter
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const articles_languageNotSupportedYet = (params = {}, options = {}) => {
	return {
		en: en.articles_languageNotSupportedYet,
		fr: fr.articles_languageNotSupportedYet
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ fullname: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_meta_title = (params , options = {}) => {
	return {
		en: en.call_meta_title,
		fr: fr.call_meta_title
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ mentorFullname: NonNullable<unknown>, menteeFullname: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_meta_description = (params , options = {}) => {
	return {
		en: en.call_meta_description,
		fr: fr.call_meta_description
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_message_empty = (params = {}, options = {}) => {
	return {
		en: en.call_message_empty,
		fr: fr.call_message_empty
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_confirm_completion = (params = {}, options = {}) => {
	return {
		en: en.call_confirm_completion,
		fr: fr.call_confirm_completion
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_completed = (params = {}, options = {}) => {
	return {
		en: en.call_completed,
		fr: fr.call_completed
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_testimonial_empty = (params = {}, options = {}) => {
	return {
		en: en.call_testimonial_empty,
		fr: fr.call_testimonial_empty
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_testimonial_rating_empty = (params = {}, options = {}) => {
	return {
		en: en.call_testimonial_rating_empty,
		fr: fr.call_testimonial_rating_empty
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_testimonial_added = (params = {}, options = {}) => {
	return {
		en: en.call_testimonial_added,
		fr: fr.call_testimonial_added
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ refundRate: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_confirm_cancellation = (params , options = {}) => {
	return {
		en: en.call_confirm_cancellation,
		fr: fr.call_confirm_cancellation
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_cancelled = (params = {}, options = {}) => {
	return {
		en: en.call_cancelled,
		fr: fr.call_cancelled
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_cancellation_not_allowed = (params = {}, options = {}) => {
	return {
		en: en.call_cancellation_not_allowed,
		fr: fr.call_cancellation_not_allowed
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_change_not_allowed = (params = {}, options = {}) => {
	return {
		en: en.call_change_not_allowed,
		fr: fr.call_change_not_allowed
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_select_slot = (params = {}, options = {}) => {
	return {
		en: en.call_select_slot,
		fr: fr.call_select_slot
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_slot_already_taken = (params = {}, options = {}) => {
	return {
		en: en.call_slot_already_taken,
		fr: fr.call_slot_already_taken
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_updated = (params = {}, options = {}) => {
	return {
		en: en.call_updated,
		fr: fr.call_updated
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_video_empty = (params = {}, options = {}) => {
	return {
		en: en.call_video_empty,
		fr: fr.call_video_empty
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_video_error = (params = {}, options = {}) => {
	return {
		en: en.call_video_error,
		fr: fr.call_video_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_video_delete_error = (params = {}, options = {}) => {
	return {
		en: en.call_video_delete_error,
		fr: fr.call_video_delete_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_no_slot_available = (params = {}, options = {}) => {
	return {
		en: en.call_no_slot_available,
		fr: fr.call_no_slot_available
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_prepare_your_call = (params = {}, options = {}) => {
	return {
		en: en.call_prepare_your_call,
		fr: fr.call_prepare_your_call
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ fullname: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_prepare_your_call_with_mentor = (params , options = {}) => {
	return {
		en: en.call_prepare_your_call_with_mentor,
		fr: fr.call_prepare_your_call_with_mentor
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_prepare_your_call_with_correspondent = (params = {}, options = {}) => {
	return {
		en: en.call_prepare_your_call_with_correspondent,
		fr: fr.call_prepare_your_call_with_correspondent
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_join_call = (params = {}, options = {}) => {
	return {
		en: en.call_join_call,
		fr: fr.call_join_call
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_join_call_title = (params = {}, options = {}) => {
	return {
		en: en.call_join_call_title,
		fr: fr.call_join_call_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ fullname: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_join_your_call_with_mentor = (params , options = {}) => {
	return {
		en: en.call_join_your_call_with_mentor,
		fr: fr.call_join_your_call_with_mentor
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_join_your_call_with_correspondent = (params = {}, options = {}) => {
	return {
		en: en.call_join_your_call_with_correspondent,
		fr: fr.call_join_your_call_with_correspondent
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_endBrief = (params = {}, options = {}) => {
	return {
		en: en.call_endBrief,
		fr: fr.call_endBrief
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_video_processing = (params = {}, options = {}) => {
	return {
		en: en.call_video_processing,
		fr: fr.call_video_processing
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_briefDescription = (params = {}, options = {}) => {
	return {
		en: en.call_briefDescription,
		fr: fr.call_briefDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_brief_video = (params = {}, options = {}) => {
	return {
		en: en.call_brief_video,
		fr: fr.call_brief_video
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_brief_send_button = (params = {}, options = {}) => {
	return {
		en: en.call_brief_send_button,
		fr: fr.call_brief_send_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_replay_title = (params = {}, options = {}) => {
	return {
		en: en.call_replay_title,
		fr: fr.call_replay_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_correspondentInfos = (params = {}, options = {}) => {
	return {
		en: en.call_correspondentInfos,
		fr: fr.call_correspondentInfos
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ amount: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_amountEarned = (params , options = {}) => {
	return {
		en: en.call_amountEarned,
		fr: fr.call_amountEarned
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_noCommissionForBelievemy = (params = {}, options = {}) => {
	return {
		en: en.call_noCommissionForBelievemy,
		fr: fr.call_noCommissionForBelievemy
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ commissionRate: NonNullable<unknown>, amountWithTaxes: NonNullable<unknown>, amountWithoutTaxes: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_commission = (params , options = {}) => {
	return {
		en: en.call_commission,
		fr: fr.call_commission
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ acceleratorName: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_acceleratedIn = (params , options = {}) => {
	return {
		en: en.call_acceleratedIn,
		fr: fr.call_acceleratedIn
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_onboardingSession = (params = {}, options = {}) => {
	return {
		en: en.call_onboardingSession,
		fr: fr.call_onboardingSession
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_cancellationAvailable = (params = {}, options = {}) => {
	return {
		en: en.call_cancellationAvailable,
		fr: fr.call_cancellationAvailable
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_canBeCancelledOneDayBefore = (params = {}, options = {}) => {
	return {
		en: en.call_canBeCancelledOneDayBefore,
		fr: fr.call_canBeCancelledOneDayBefore
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ days: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_canBeCancelledDaysBefore = (params , options = {}) => {
	return {
		en: en.call_canBeCancelledDaysBefore,
		fr: fr.call_canBeCancelledDaysBefore
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_canBeCancelledOneHourBefore = (params = {}, options = {}) => {
	return {
		en: en.call_canBeCancelledOneHourBefore,
		fr: fr.call_canBeCancelledOneHourBefore
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ hours: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_canBeCancelledHoursBefore = (params , options = {}) => {
	return {
		en: en.call_canBeCancelledHoursBefore,
		fr: fr.call_canBeCancelledHoursBefore
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_cancellationAvailableForFree = (params = {}, options = {}) => {
	return {
		en: en.call_cancellationAvailableForFree,
		fr: fr.call_cancellationAvailableForFree
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_fullRefund = (params = {}, options = {}) => {
	return {
		en: en.call_fullRefund,
		fr: fr.call_fullRefund
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ refundRate: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_partialRefund = (params , options = {}) => {
	return {
		en: en.call_partialRefund,
		fr: fr.call_partialRefund
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_cancellationNotAvailable = (params = {}, options = {}) => {
	return {
		en: en.call_cancellationNotAvailable,
		fr: fr.call_cancellationNotAvailable
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_cancellationNotAvailableDescription = (params = {}, options = {}) => {
	return {
		en: en.call_cancellationNotAvailableDescription,
		fr: fr.call_cancellationNotAvailableDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_modifiable = (params = {}, options = {}) => {
	return {
		en: en.call_modifiable,
		fr: fr.call_modifiable
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ maximumChangeCall: NonNullable<unknown>, minimumHoursToChangeCall: NonNullable<unknown>, maximumHoursWithNewCall: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_modifiable_description = (params , options = {}) => {
	return {
		en: en.call_modifiable_description,
		fr: fr.call_modifiable_description
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ maximumChangeCall: NonNullable<unknown>, minimumHoursToChangeCall: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_modifiable_description_without_delay = (params , options = {}) => {
	return {
		en: en.call_modifiable_description_without_delay,
		fr: fr.call_modifiable_description_without_delay
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_notModifiable = (params = {}, options = {}) => {
	return {
		en: en.call_notModifiable,
		fr: fr.call_notModifiable
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_notModifiable_description = (params = {}, options = {}) => {
	return {
		en: en.call_notModifiable_description,
		fr: fr.call_notModifiable_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_seeProfile = (params = {}, options = {}) => {
	return {
		en: en.call_seeProfile,
		fr: fr.call_seeProfile
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_discussion = (params = {}, options = {}) => {
	return {
		en: en.call_discussion,
		fr: fr.call_discussion
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_write_message_placeholder = (params = {}, options = {}) => {
	return {
		en: en.call_write_message_placeholder,
		fr: fr.call_write_message_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_send_message = (params = {}, options = {}) => {
	return {
		en: en.call_send_message,
		fr: fr.call_send_message
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_no_message = (params = {}, options = {}) => {
	return {
		en: en.call_no_message,
		fr: fr.call_no_message
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ fullname: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_testimonial_from = (params , options = {}) => {
	return {
		en: en.call_testimonial_from,
		fr: fr.call_testimonial_from
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_no_testimonial = (params = {}, options = {}) => {
	return {
		en: en.call_no_testimonial,
		fr: fr.call_no_testimonial
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ firstName: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_give_testimonial_toMentor = (params , options = {}) => {
	return {
		en: en.call_give_testimonial_toMentor,
		fr: fr.call_give_testimonial_toMentor
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_your_testimonial = (params = {}, options = {}) => {
	return {
		en: en.call_your_testimonial,
		fr: fr.call_your_testimonial
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_send_testimonial_button = (params = {}, options = {}) => {
	return {
		en: en.call_send_testimonial_button,
		fr: fr.call_send_testimonial_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_change_slot = (params = {}, options = {}) => {
	return {
		en: en.call_change_slot,
		fr: fr.call_change_slot
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ numberOfChangesAvailable: NonNullable<unknown>, maximumHoursWithNewCall: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_change_slot_description = (params , options = {}) => {
	return {
		en: en.call_change_slot_description,
		fr: fr.call_change_slot_description
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ numberOfChangesAvailable: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_change_slot_description_without_delay = (params , options = {}) => {
	return {
		en: en.call_change_slot_description_without_delay,
		fr: fr.call_change_slot_description_without_delay
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_change_slot_your_actualSlot = (params = {}, options = {}) => {
	return {
		en: en.call_change_slot_your_actualSlot,
		fr: fr.call_change_slot_your_actualSlot
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_change_slot_button_continue = (params = {}, options = {}) => {
	return {
		en: en.call_change_slot_button_continue,
		fr: fr.call_change_slot_button_continue
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_change_slot_newDate = (params = {}, options = {}) => {
	return {
		en: en.call_change_slot_newDate,
		fr: fr.call_change_slot_newDate
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_change_slot_newHour = (params = {}, options = {}) => {
	return {
		en: en.call_change_slot_newHour,
		fr: fr.call_change_slot_newHour
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_change_slot_confirmation = (params = {}, options = {}) => {
	return {
		en: en.call_change_slot_confirmation,
		fr: fr.call_change_slot_confirmation
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_change_slot_newSlot = (params = {}, options = {}) => {
	return {
		en: en.call_change_slot_newSlot,
		fr: fr.call_change_slot_newSlot
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_change_slot_button_confirm = (params = {}, options = {}) => {
	return {
		en: en.call_change_slot_button_confirm,
		fr: fr.call_change_slot_button_confirm
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_finished = (params = {}, options = {}) => {
	return {
		en: en.call_finished,
		fr: fr.call_finished
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ minutes: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_started = (params , options = {}) => {
	return {
		en: en.call_started,
		fr: fr.call_started
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_cancelled_pure = (params = {}, options = {}) => {
	return {
		en: en.call_cancelled_pure,
		fr: fr.call_cancelled_pure
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ minutes: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_minutesPerCall = (params , options = {}) => {
	return {
		en: en.call_minutesPerCall,
		fr: fr.call_minutesPerCall
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_minutes = (params = {}, options = {}) => {
	return {
		en: en.call_minutes,
		fr: fr.call_minutes
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ date: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_cancelledAt = (params , options = {}) => {
	return {
		en: en.call_cancelledAt,
		fr: fr.call_cancelledAt
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ date: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_originalDate = (params , options = {}) => {
	return {
		en: en.call_originalDate,
		fr: fr.call_originalDate
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_cannotMarkAsCompletedBeforeStart = (params = {}, options = {}) => {
	return {
		en: en.call_cannotMarkAsCompletedBeforeStart,
		fr: fr.call_cannotMarkAsCompletedBeforeStart
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_markAsCompleted = (params = {}, options = {}) => {
	return {
		en: en.call_markAsCompleted,
		fr: fr.call_markAsCompleted
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_changeSlot = (params = {}, options = {}) => {
	return {
		en: en.call_changeSlot,
		fr: fr.call_changeSlot
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_cancelCall = (params = {}, options = {}) => {
	return {
		en: en.call_cancelCall,
		fr: fr.call_cancelCall
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_participants = (params = {}, options = {}) => {
	return {
		en: en.call_participants,
		fr: fr.call_participants
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_progression = (params = {}, options = {}) => {
	return {
		en: en.call_progression,
		fr: fr.call_progression
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_questionnaires = (params = {}, options = {}) => {
	return {
		en: en.call_questionnaires,
		fr: fr.call_questionnaires
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const callProgression_no_token = (params = {}, options = {}) => {
	return {
		en: en.callProgression_no_token,
		fr: fr.callProgression_no_token
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const callProgression_title = (params = {}, options = {}) => {
	return {
		en: en.callProgression_title,
		fr: fr.callProgression_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const callProgression_confidential = (params = {}, options = {}) => {
	return {
		en: en.callProgression_confidential,
		fr: fr.callProgression_confidential
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const callProgression_student = (params = {}, options = {}) => {
	return {
		en: en.callProgression_student,
		fr: fr.callProgression_student
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ date: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const callProgression_updatedAt = (params , options = {}) => {
	return {
		en: en.callProgression_updatedAt,
		fr: fr.callProgression_updatedAt
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ count: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const callProgression_sessionsCompleted = (params , options = {}) => {
	return {
		en: en.callProgression_sessionsCompleted,
		fr: fr.callProgression_sessionsCompleted
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ count: NonNullable<unknown>, total: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const callProgression_projectsCompleted = (params , options = {}) => {
	return {
		en: en.callProgression_projectsCompleted,
		fr: fr.callProgression_projectsCompleted
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ count: NonNullable<unknown>, total: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const callProgression_questionnairesCompleted = (params , options = {}) => {
	return {
		en: en.callProgression_questionnairesCompleted,
		fr: fr.callProgression_questionnairesCompleted
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const callProgression_questionnairesList = (params = {}, options = {}) => {
	return {
		en: en.callProgression_questionnairesList,
		fr: fr.callProgression_questionnairesList
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ number: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const callProgression_questionnaire = (params , options = {}) => {
	return {
		en: en.callProgression_questionnaire,
		fr: fr.callProgression_questionnaire
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const callProgression_questionnaire_validated = (params = {}, options = {}) => {
	return {
		en: en.callProgression_questionnaire_validated,
		fr: fr.callProgression_questionnaire_validated
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const callProgression_questionnaire_notValidated = (params = {}, options = {}) => {
	return {
		en: en.callProgression_questionnaire_notValidated,
		fr: fr.callProgression_questionnaire_notValidated
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const callProgression_questionnaire_started = (params = {}, options = {}) => {
	return {
		en: en.callProgression_questionnaire_started,
		fr: fr.callProgression_questionnaire_started
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const callProgression_questionnaire_notStarted = (params = {}, options = {}) => {
	return {
		en: en.callProgression_questionnaire_notStarted,
		fr: fr.callProgression_questionnaire_notStarted
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const callProgression_sessionsList = (params = {}, options = {}) => {
	return {
		en: en.callProgression_sessionsList,
		fr: fr.callProgression_sessionsList
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const callQuestionnaires_title = (params = {}, options = {}) => {
	return {
		en: en.callQuestionnaires_title,
		fr: fr.callQuestionnaires_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quizReadOnly_notQuiz = (params = {}, options = {}) => {
	return {
		en: en.quizReadOnly_notQuiz,
		fr: fr.quizReadOnly_notQuiz
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quizReadOnly_pending = (params = {}, options = {}) => {
	return {
		en: en.quizReadOnly_pending,
		fr: fr.quizReadOnly_pending
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quizReadOnly_results = (params = {}, options = {}) => {
	return {
		en: en.quizReadOnly_results,
		fr: fr.quizReadOnly_results
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ numberOfValidQuestions: NonNullable<unknown>, total: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quizReadOnly_resultsDetails = (params , options = {}) => {
	return {
		en: en.quizReadOnly_resultsDetails,
		fr: fr.quizReadOnly_resultsDetails
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ number: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quizAnswer_questionNumber = (params , options = {}) => {
	return {
		en: en.quizAnswer_questionNumber,
		fr: fr.quizAnswer_questionNumber
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quizAnswer_goodAnswer = (params = {}, options = {}) => {
	return {
		en: en.quizAnswer_goodAnswer,
		fr: fr.quizAnswer_goodAnswer
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quizAnswer_badAnswer = (params = {}, options = {}) => {
	return {
		en: en.quizAnswer_badAnswer,
		fr: fr.quizAnswer_badAnswer
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quizAnswer_waitedAnswer = (params = {}, options = {}) => {
	return {
		en: en.quizAnswer_waitedAnswer,
		fr: fr.quizAnswer_waitedAnswer
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quizAnswer_yourAnswer = (params = {}, options = {}) => {
	return {
		en: en.quizAnswer_yourAnswer,
		fr: fr.quizAnswer_yourAnswer
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quizAnswer_explanation = (params = {}, options = {}) => {
	return {
		en: en.quizAnswer_explanation,
		fr: fr.quizAnswer_explanation
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quizAnswer_hideDetails = (params = {}, options = {}) => {
	return {
		en: en.quizAnswer_hideDetails,
		fr: fr.quizAnswer_hideDetails
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quizAnswer_showDetails = (params = {}, options = {}) => {
	return {
		en: en.quizAnswer_showDetails,
		fr: fr.quizAnswer_showDetails
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ count: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quizReadOnly_numberOfQuestions = (params , options = {}) => {
	return {
		en: en.quizReadOnly_numberOfQuestions,
		fr: fr.quizReadOnly_numberOfQuestions
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quizReadOnly_goal = (params = {}, options = {}) => {
	return {
		en: en.quizReadOnly_goal,
		fr: fr.quizReadOnly_goal
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quizReadOnly_itsTimeToTry = (params = {}, options = {}) => {
	return {
		en: en.quizReadOnly_itsTimeToTry,
		fr: fr.quizReadOnly_itsTimeToTry
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ count: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quizReadOnly_readCarefully = (params , options = {}) => {
	return {
		en: en.quizReadOnly_readCarefully,
		fr: fr.quizReadOnly_readCarefully
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quizReadOnly_start = (params = {}, options = {}) => {
	return {
		en: en.quizReadOnly_start,
		fr: fr.quizReadOnly_start
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_meta_title = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_meta_title,
		fr: fr.accelerator_dashboard_meta_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_meta_description = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_meta_description,
		fr: fr.accelerator_dashboard_meta_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const interfaceLayout_notification_deleted_successfully = (params = {}, options = {}) => {
	return {
		en: en.interfaceLayout_notification_deleted_successfully,
		fr: fr.interfaceLayout_notification_deleted_successfully
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const interfaceLayout_verificationEmailSent = (params = {}, options = {}) => {
	return {
		en: en.interfaceLayout_verificationEmailSent,
		fr: fr.interfaceLayout_verificationEmailSent
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_pleaseSelectYourMentor = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_pleaseSelectYourMentor,
		fr: fr.accelerator_dashboard_pleaseSelectYourMentor
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_mentorNotAvailable = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_mentorNotAvailable,
		fr: fr.accelerator_dashboard_mentorNotAvailable
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_mentoringStartedSuccessfully = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_mentoringStartedSuccessfully,
		fr: fr.accelerator_dashboard_mentoringStartedSuccessfully
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_soon = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_soon,
		fr: fr.accelerator_dashboard_soon
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_seeMore = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_seeMore,
		fr: fr.accelerator_dashboard_seeMore
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_recapAboutUpgradingWithMentor = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_recapAboutUpgradingWithMentor,
		fr: fr.accelerator_dashboard_recapAboutUpgradingWithMentor
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_incVAT = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_incVAT,
		fr: fr.accelerator_dashboard_incVAT
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_excVAT = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_excVAT,
		fr: fr.accelerator_dashboard_excVAT
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_unlockYourPersonalMentor = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_unlockYourPersonalMentor,
		fr: fr.accelerator_dashboard_unlockYourPersonalMentor
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_mensualRecurring = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_mensualRecurring,
		fr: fr.accelerator_dashboard_mensualRecurring
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_subtotal = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_subtotal,
		fr: fr.accelerator_dashboard_subtotal
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_totalWithVAT = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_totalWithVAT,
		fr: fr.accelerator_dashboard_totalWithVAT
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_priceByWeek = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_priceByWeek,
		fr: fr.accelerator_dashboard_priceByWeek
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_yourCanCancelAnytime = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_yourCanCancelAnytime,
		fr: fr.accelerator_dashboard_yourCanCancelAnytime
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_yourBankingInfos = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_yourBankingInfos,
		fr: fr.accelerator_dashboard_yourBankingInfos
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_yourBankingInfosDescription = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_yourBankingInfosDescription,
		fr: fr.accelerator_dashboard_yourBankingInfosDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_yourWillNotPayToday = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_yourWillNotPayToday,
		fr: fr.accelerator_dashboard_yourWillNotPayToday
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CheckoutFormMentor_success = (params = {}, options = {}) => {
	return {
		en: en.CheckoutFormMentor_success,
		fr: fr.CheckoutFormMentor_success
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CheckoutFormMentor_processing = (params = {}, options = {}) => {
	return {
		en: en.CheckoutFormMentor_processing,
		fr: fr.CheckoutFormMentor_processing
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CheckoutFormMentor_requires_payment_method = (params = {}, options = {}) => {
	return {
		en: en.CheckoutFormMentor_requires_payment_method,
		fr: fr.CheckoutFormMentor_requires_payment_method
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CheckoutFormMentor_try = (params = {}, options = {}) => {
	return {
		en: en.CheckoutFormMentor_try,
		fr: fr.CheckoutFormMentor_try
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CheckoutFormMentor_slot_taken = (params = {}, options = {}) => {
	return {
		en: en.CheckoutFormMentor_slot_taken,
		fr: fr.CheckoutFormMentor_slot_taken
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CheckoutFormMentor_invalidCode = (params = {}, options = {}) => {
	return {
		en: en.CheckoutFormMentor_invalidCode,
		fr: fr.CheckoutFormMentor_invalidCode
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CheckoutForm_pay = (params = {}, options = {}) => {
	return {
		en: en.CheckoutForm_pay,
		fr: fr.CheckoutForm_pay
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_chooseYourMentor = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_chooseYourMentor,
		fr: fr.accelerator_dashboard_chooseYourMentor
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ minutes: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_chooseYourMentor_description = (params , options = {}) => {
	return {
		en: en.accelerator_dashboard_chooseYourMentor_description,
		fr: fr.accelerator_dashboard_chooseYourMentor_description
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_oneCallEachWeek = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_oneCallEachWeek,
		fr: fr.accelerator_dashboard_oneCallEachWeek
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ minutes: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_minutesPerCall = (params , options = {}) => {
	return {
		en: en.accelerator_dashboard_minutesPerCall,
		fr: fr.accelerator_dashboard_minutesPerCall
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_onlyYouTwo = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_onlyYouTwo,
		fr: fr.accelerator_dashboard_onlyYouTwo
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_chooseYourMentor_description_2 = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_chooseYourMentor_description_2,
		fr: fr.accelerator_dashboard_chooseYourMentor_description_2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_iWantAMentorChooseForMe = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_iWantAMentorChooseForMe,
		fr: fr.accelerator_dashboard_iWantAMentorChooseForMe
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_iWantLNL = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_iWantLNL,
		fr: fr.accelerator_dashboard_iWantLNL
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_continue = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_continue,
		fr: fr.accelerator_dashboard_continue
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_yourBillingInfos = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_yourBillingInfos,
		fr: fr.accelerator_dashboard_yourBillingInfos
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_yourBillingInfosDescription = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_yourBillingInfosDescription,
		fr: fr.accelerator_dashboard_yourBillingInfosDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ date: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_studentSince = (params , options = {}) => {
	return {
		en: en.accelerator_dashboard_studentSince,
		fr: fr.accelerator_dashboard_studentSince
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_yourMentor = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_yourMentor,
		fr: fr.accelerator_dashboard_yourMentor
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_noMentor = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_noMentor,
		fr: fr.accelerator_dashboard_noMentor
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_bookACall = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_bookACall,
		fr: fr.accelerator_dashboard_bookACall
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_mentorship = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_mentorship,
		fr: fr.accelerator_dashboard_mentorship
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_mentorship_description = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_mentorship_description,
		fr: fr.accelerator_dashboard_mentorship_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_startMentorship = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_startMentorship,
		fr: fr.accelerator_dashboard_startMentorship
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ firstName: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_welcome = (params , options = {}) => {
	return {
		en: en.accelerator_dashboard_welcome,
		fr: fr.accelerator_dashboard_welcome
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_dashboard = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_dashboard,
		fr: fr.accelerator_dashboard_dashboard
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_sessionsPublished = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_sessionsPublished,
		fr: fr.accelerator_dashboard_sessionsPublished
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_sessionPublished = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_sessionPublished,
		fr: fr.accelerator_dashboard_sessionPublished
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_sessionsEnded = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_sessionsEnded,
		fr: fr.accelerator_dashboard_sessionsEnded
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_sessionEnded = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_sessionEnded,
		fr: fr.accelerator_dashboard_sessionEnded
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_projectsValidated = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_projectsValidated,
		fr: fr.accelerator_dashboard_projectsValidated
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_projectValidated = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_projectValidated,
		fr: fr.accelerator_dashboard_projectValidated
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_callsCompleted = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_callsCompleted,
		fr: fr.accelerator_dashboard_callsCompleted
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_callCompleted = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_callCompleted,
		fr: fr.accelerator_dashboard_callCompleted
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_modules = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_modules,
		fr: fr.accelerator_dashboard_modules
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ number: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_module = (params , options = {}) => {
	return {
		en: en.accelerator_dashboard_module,
		fr: fr.accelerator_dashboard_module
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_seeModule = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_seeModule,
		fr: fr.accelerator_dashboard_seeModule
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ date: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_noModule = (params , options = {}) => {
	return {
		en: en.accelerator_dashboard_noModule,
		fr: fr.accelerator_dashboard_noModule
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_yourMentoring = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_yourMentoring,
		fr: fr.accelerator_dashboard_yourMentoring
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_yourNextCoaching = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_yourNextCoaching,
		fr: fr.accelerator_dashboard_yourNextCoaching
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_firstCallDiscover = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_firstCallDiscover,
		fr: fr.accelerator_dashboard_firstCallDiscover
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_normalSession = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_normalSession,
		fr: fr.accelerator_dashboard_normalSession
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_with = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_with,
		fr: fr.accelerator_dashboard_with
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_accessCall = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_accessCall,
		fr: fr.accelerator_dashboard_accessCall
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_pastCalls = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_pastCalls,
		fr: fr.accelerator_dashboard_pastCalls
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_cancelledCalls = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_cancelledCalls,
		fr: fr.accelerator_dashboard_cancelledCalls
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_projects = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_projects,
		fr: fr.accelerator_dashboard_projects
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_close = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_close,
		fr: fr.accelerator_dashboard_close
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_validated = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_validated,
		fr: fr.accelerator_dashboard_validated
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_invalidated = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_invalidated,
		fr: fr.accelerator_dashboard_invalidated
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_seeProject = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_seeProject,
		fr: fr.accelerator_dashboard_seeProject
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_replays = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_replays,
		fr: fr.accelerator_dashboard_replays
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_seeReplay = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_seeReplay,
		fr: fr.accelerator_dashboard_seeReplay
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_documents = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_documents,
		fr: fr.accelerator_dashboard_documents
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_documentsDescription = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_documentsDescription,
		fr: fr.accelerator_dashboard_documentsDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_entryDocument = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_entryDocument,
		fr: fr.accelerator_dashboard_entryDocument
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_partners = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_partners,
		fr: fr.accelerator_dashboard_partners
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_getCoupon = (params = {}, options = {}) => {
	return {
		en: en.accelerator_dashboard_getCoupon,
		fr: fr.accelerator_dashboard_getCoupon
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const entryDocument_title = (params = {}, options = {}) => {
	return {
		en: en.entryDocument_title,
		fr: fr.entryDocument_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ studentFullname: NonNullable<unknown>, programName: NonNullable<unknown>, weeks: NonNullable<unknown>, startDate: NonNullable<unknown>, endDate: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const entryDocument_description1 = (params , options = {}) => {
	return {
		en: en.entryDocument_description1,
		fr: fr.entryDocument_description1
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const entryDocument_description2 = (params = {}, options = {}) => {
	return {
		en: en.entryDocument_description2,
		fr: fr.entryDocument_description2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const entryDocument_description3 = (params = {}, options = {}) => {
	return {
		en: en.entryDocument_description3,
		fr: fr.entryDocument_description3
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const entryDocument_signature = (params = {}, options = {}) => {
	return {
		en: en.entryDocument_signature,
		fr: fr.entryDocument_signature
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const entryDocument_signatureDescription = (params = {}, options = {}) => {
	return {
		en: en.entryDocument_signatureDescription,
		fr: fr.entryDocument_signatureDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ date: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const entryDocument_signatureDate = (params , options = {}) => {
	return {
		en: en.entryDocument_signatureDate,
		fr: fr.entryDocument_signatureDate
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const program_dashboard_meta_title = (params = {}, options = {}) => {
	return {
		en: en.program_dashboard_meta_title,
		fr: fr.program_dashboard_meta_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const program_dashboard_meta_description = (params = {}, options = {}) => {
	return {
		en: en.program_dashboard_meta_description,
		fr: fr.program_dashboard_meta_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_discussionCreatedSuccessfully = (params = {}, options = {}) => {
	return {
		en: en.course_content_discussionCreatedSuccessfully,
		fr: fr.course_content_discussionCreatedSuccessfully
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_pleaseEnterYourMessage = (params = {}, options = {}) => {
	return {
		en: en.course_content_pleaseEnterYourMessage,
		fr: fr.course_content_pleaseEnterYourMessage
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_answerPostedSuccessfully = (params = {}, options = {}) => {
	return {
		en: en.course_content_answerPostedSuccessfully,
		fr: fr.course_content_answerPostedSuccessfully
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_areYouSureYouWantToDeleteThisDiscussion = (params = {}, options = {}) => {
	return {
		en: en.course_content_areYouSureYouWantToDeleteThisDiscussion,
		fr: fr.course_content_areYouSureYouWantToDeleteThisDiscussion
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_discussionDeletedSuccessfully = (params = {}, options = {}) => {
	return {
		en: en.course_content_discussionDeletedSuccessfully,
		fr: fr.course_content_discussionDeletedSuccessfully
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_discussionEditedSuccessfully = (params = {}, options = {}) => {
	return {
		en: en.course_content_discussionEditedSuccessfully,
		fr: fr.course_content_discussionEditedSuccessfully
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_pleaseSelectAnErrorType = (params = {}, options = {}) => {
	return {
		en: en.course_content_pleaseSelectAnErrorType,
		fr: fr.course_content_pleaseSelectAnErrorType
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_pleaseEnterErrorDescription = (params = {}, options = {}) => {
	return {
		en: en.course_content_pleaseEnterErrorDescription,
		fr: fr.course_content_pleaseEnterErrorDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_errorSentSuccessfully = (params = {}, options = {}) => {
	return {
		en: en.course_content_errorSentSuccessfully,
		fr: fr.course_content_errorSentSuccessfully
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_helpDeleted = (params = {}, options = {}) => {
	return {
		en: en.course_content_helpDeleted,
		fr: fr.course_content_helpDeleted
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_helpAdded = (params = {}, options = {}) => {
	return {
		en: en.course_content_helpAdded,
		fr: fr.course_content_helpAdded
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_pleaseEnterYourTask = (params = {}, options = {}) => {
	return {
		en: en.course_content_pleaseEnterYourTask,
		fr: fr.course_content_pleaseEnterYourTask
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_youNeedToValidateAllTheQuizQuestions = (params = {}, options = {}) => {
	return {
		en: en.course_content_youNeedToValidateAllTheQuizQuestions,
		fr: fr.course_content_youNeedToValidateAllTheQuizQuestions
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_certificationSentSuccessfully = (params = {}, options = {}) => {
	return {
		en: en.course_content_certificationSentSuccessfully,
		fr: fr.course_content_certificationSentSuccessfully
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_quizSuccessfullyValidated = (params = {}, options = {}) => {
	return {
		en: en.course_content_quizSuccessfullyValidated,
		fr: fr.course_content_quizSuccessfullyValidated
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_quizLimitedRestart = (params = {}, options = {}) => {
	return {
		en: en.course_content_quizLimitedRestart,
		fr: fr.course_content_quizLimitedRestart
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_quizRestartedSuccessfully = (params = {}, options = {}) => {
	return {
		en: en.course_content_quizRestartedSuccessfully,
		fr: fr.course_content_quizRestartedSuccessfully
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_premiumUnlocked = (params = {}, options = {}) => {
	return {
		en: en.course_content_premiumUnlocked,
		fr: fr.course_content_premiumUnlocked
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_emailVerified = (params = {}, options = {}) => {
	return {
		en: en.course_content_emailVerified,
		fr: fr.course_content_emailVerified
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_firstCourse = (params = {}, options = {}) => {
	return {
		en: en.course_content_firstCourse,
		fr: fr.course_content_firstCourse
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_firstCourseBelievemyOriginals = (params = {}, options = {}) => {
	return {
		en: en.course_content_firstCourseBelievemyOriginals,
		fr: fr.course_content_firstCourseBelievemyOriginals
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_newCourse = (params = {}, options = {}) => {
	return {
		en: en.course_content_newCourse,
		fr: fr.course_content_newCourse
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_newCourseBelievemyOriginals = (params = {}, options = {}) => {
	return {
		en: en.course_content_newCourseBelievemyOriginals,
		fr: fr.course_content_newCourseBelievemyOriginals
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_firstCourseQuoteCitation = (params = {}, options = {}) => {
	return {
		en: en.course_content_firstCourseQuoteCitation,
		fr: fr.course_content_firstCourseQuoteCitation
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_firstCourseQuoteAuthor = (params = {}, options = {}) => {
	return {
		en: en.course_content_firstCourseQuoteAuthor,
		fr: fr.course_content_firstCourseQuoteAuthor
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_firstCourseAttention = (params = {}, options = {}) => {
	return {
		en: en.course_content_firstCourseAttention,
		fr: fr.course_content_firstCourseAttention
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_firstCourseDiscord = (params = {}, options = {}) => {
	return {
		en: en.course_content_firstCourseDiscord,
		fr: fr.course_content_firstCourseDiscord
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_firstCourseBestWay = (params = {}, options = {}) => {
	return {
		en: en.course_content_firstCourseBestWay,
		fr: fr.course_content_firstCourseBestWay
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_firstCourseDoIt = (params = {}, options = {}) => {
	return {
		en: en.course_content_firstCourseDoIt,
		fr: fr.course_content_firstCourseDoIt
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_firstCourseStart = (params = {}, options = {}) => {
	return {
		en: en.course_content_firstCourseStart,
		fr: fr.course_content_firstCourseStart
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_newCourseWelcome = (params = {}, options = {}) => {
	return {
		en: en.course_content_newCourseWelcome,
		fr: fr.course_content_newCourseWelcome
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_lockedCourse = (params = {}, options = {}) => {
	return {
		en: en.course_content_lockedCourse,
		fr: fr.course_content_lockedCourse
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_lockedCourseDescription1 = (params = {}, options = {}) => {
	return {
		en: en.course_content_lockedCourseDescription1,
		fr: fr.course_content_lockedCourseDescription1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_lockedCourseDescription2 = (params = {}, options = {}) => {
	return {
		en: en.course_content_lockedCourseDescription2,
		fr: fr.course_content_lockedCourseDescription2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_lockedCourseReason1 = (params = {}, options = {}) => {
	return {
		en: en.course_content_lockedCourseReason1,
		fr: fr.course_content_lockedCourseReason1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_lockedCourseReason1Description = (params = {}, options = {}) => {
	return {
		en: en.course_content_lockedCourseReason1Description,
		fr: fr.course_content_lockedCourseReason1Description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_lockedCourseReason2 = (params = {}, options = {}) => {
	return {
		en: en.course_content_lockedCourseReason2,
		fr: fr.course_content_lockedCourseReason2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_lockedCourseReason2Description = (params = {}, options = {}) => {
	return {
		en: en.course_content_lockedCourseReason2Description,
		fr: fr.course_content_lockedCourseReason2Description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_lockedCourseReason3 = (params = {}, options = {}) => {
	return {
		en: en.course_content_lockedCourseReason3,
		fr: fr.course_content_lockedCourseReason3
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_lockedCourseReason3Description = (params = {}, options = {}) => {
	return {
		en: en.course_content_lockedCourseReason3Description,
		fr: fr.course_content_lockedCourseReason3Description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_lockedCourseTrial = (params = {}, options = {}) => {
	return {
		en: en.course_content_lockedCourseTrial,
		fr: fr.course_content_lockedCourseTrial
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_lockedCourseTrialDescription = (params = {}, options = {}) => {
	return {
		en: en.course_content_lockedCourseTrialDescription,
		fr: fr.course_content_lockedCourseTrialDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_startMyPremium = (params = {}, options = {}) => {
	return {
		en: en.course_content_startMyPremium,
		fr: fr.course_content_startMyPremium
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_startMyTrial = (params = {}, options = {}) => {
	return {
		en: en.course_content_startMyTrial,
		fr: fr.course_content_startMyTrial
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_quizGoodAnswer = (params = {}, options = {}) => {
	return {
		en: en.course_content_quizGoodAnswer,
		fr: fr.course_content_quizGoodAnswer
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_quizBadAnswer = (params = {}, options = {}) => {
	return {
		en: en.course_content_quizBadAnswer,
		fr: fr.course_content_quizBadAnswer
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_answerWasHere = (params = {}, options = {}) => {
	return {
		en: en.course_content_answerWasHere,
		fr: fr.course_content_answerWasHere
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_quizNext = (params = {}, options = {}) => {
	return {
		en: en.course_content_quizNext,
		fr: fr.course_content_quizNext
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_quizConfirm = (params = {}, options = {}) => {
	return {
		en: en.course_content_quizConfirm,
		fr: fr.course_content_quizConfirm
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_quizFinish = (params = {}, options = {}) => {
	return {
		en: en.course_content_quizFinish,
		fr: fr.course_content_quizFinish
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_quizResults = (params = {}, options = {}) => {
	return {
		en: en.course_content_quizResults,
		fr: fr.course_content_quizResults
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_quizRestart = (params = {}, options = {}) => {
	return {
		en: en.course_content_quizRestart,
		fr: fr.course_content_quizRestart
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_unlockCourseTitle = (params = {}, options = {}) => {
	return {
		en: en.course_content_unlockCourseTitle,
		fr: fr.course_content_unlockCourseTitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_unlockCourseSubtitle = (params = {}, options = {}) => {
	return {
		en: en.course_content_unlockCourseSubtitle,
		fr: fr.course_content_unlockCourseSubtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_unlockCourseDescription = (params = {}, options = {}) => {
	return {
		en: en.course_content_unlockCourseDescription,
		fr: fr.course_content_unlockCourseDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_unlockCourseButton = (params = {}, options = {}) => {
	return {
		en: en.course_content_unlockCourseButton,
		fr: fr.course_content_unlockCourseButton
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_nav_progression = (params = {}, options = {}) => {
	return {
		en: en.course_content_nav_progression,
		fr: fr.course_content_nav_progression
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_nav_content = (params = {}, options = {}) => {
	return {
		en: en.course_content_nav_content,
		fr: fr.course_content_nav_content
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_nav_sources = (params = {}, options = {}) => {
	return {
		en: en.course_content_nav_sources,
		fr: fr.course_content_nav_sources
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_nav_questions = (params = {}, options = {}) => {
	return {
		en: en.course_content_nav_questions,
		fr: fr.course_content_nav_questions
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_nav_tasks = (params = {}, options = {}) => {
	return {
		en: en.course_content_nav_tasks,
		fr: fr.course_content_nav_tasks
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ fullname: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_instructor = (params , options = {}) => {
	return {
		en: en.course_content_instructor,
		fr: fr.course_content_instructor
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_questions = (params = {}, options = {}) => {
	return {
		en: en.course_content_questions,
		fr: fr.course_content_questions
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_askQuestionTitle = (params = {}, options = {}) => {
	return {
		en: en.course_content_askQuestionTitle,
		fr: fr.course_content_askQuestionTitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_askQuestionDescription = (params = {}, options = {}) => {
	return {
		en: en.course_content_askQuestionDescription,
		fr: fr.course_content_askQuestionDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_askQuestionAdvice1 = (params = {}, options = {}) => {
	return {
		en: en.course_content_askQuestionAdvice1,
		fr: fr.course_content_askQuestionAdvice1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_askQuestionAdvice2 = (params = {}, options = {}) => {
	return {
		en: en.course_content_askQuestionAdvice2,
		fr: fr.course_content_askQuestionAdvice2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_askQuestionAdvice3 = (params = {}, options = {}) => {
	return {
		en: en.course_content_askQuestionAdvice3,
		fr: fr.course_content_askQuestionAdvice3
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_timecode = (params = {}, options = {}) => {
	return {
		en: en.course_content_timecode,
		fr: fr.course_content_timecode
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_questionTitle = (params = {}, options = {}) => {
	return {
		en: en.course_content_questionTitle,
		fr: fr.course_content_questionTitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_questionTitlePlaceholder = (params = {}, options = {}) => {
	return {
		en: en.course_content_questionTitlePlaceholder,
		fr: fr.course_content_questionTitlePlaceholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_questionContent = (params = {}, options = {}) => {
	return {
		en: en.course_content_questionContent,
		fr: fr.course_content_questionContent
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_questionContentPlaceholder = (params = {}, options = {}) => {
	return {
		en: en.course_content_questionContentPlaceholder,
		fr: fr.course_content_questionContentPlaceholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_cancel = (params = {}, options = {}) => {
	return {
		en: en.course_content_cancel,
		fr: fr.course_content_cancel
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_send = (params = {}, options = {}) => {
	return {
		en: en.course_content_send,
		fr: fr.course_content_send
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_backToQuestions = (params = {}, options = {}) => {
	return {
		en: en.course_content_backToQuestions,
		fr: fr.course_content_backToQuestions
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ date: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_discussion = (params , options = {}) => {
	return {
		en: en.course_content_discussion,
		fr: fr.course_content_discussion
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ count: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_numberOfParticipants = (params , options = {}) => {
	return {
		en: en.course_content_numberOfParticipants,
		fr: fr.course_content_numberOfParticipants
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_discussionEdit = (params = {}, options = {}) => {
	return {
		en: en.course_content_discussionEdit,
		fr: fr.course_content_discussionEdit
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_discussionDelete = (params = {}, options = {}) => {
	return {
		en: en.course_content_discussionDelete,
		fr: fr.course_content_discussionDelete
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_markAsGuide = (params = {}, options = {}) => {
	return {
		en: en.course_content_markAsGuide,
		fr: fr.course_content_markAsGuide
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_unmarkAsGuide = (params = {}, options = {}) => {
	return {
		en: en.course_content_unmarkAsGuide,
		fr: fr.course_content_unmarkAsGuide
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_questionAnswerPlaceholder = (params = {}, options = {}) => {
	return {
		en: en.course_content_questionAnswerPlaceholder,
		fr: fr.course_content_questionAnswerPlaceholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_save = (params = {}, options = {}) => {
	return {
		en: en.course_content_save,
		fr: fr.course_content_save
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_helpBadge = (params = {}, options = {}) => {
	return {
		en: en.course_content_helpBadge,
		fr: fr.course_content_helpBadge
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ count: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_numberOfMessages = (params , options = {}) => {
	return {
		en: en.course_content_numberOfMessages,
		fr: fr.course_content_numberOfMessages
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_markAsGuide = (params = {}, options = {}) => {
	return {
		en: en.Message_markAsGuide,
		fr: fr.Message_markAsGuide
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_areYouSureYouWantToDeleteThisMessage = (params = {}, options = {}) => {
	return {
		en: en.Message_areYouSureYouWantToDeleteThisMessage,
		fr: fr.Message_areYouSureYouWantToDeleteThisMessage
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_messageDeleted = (params = {}, options = {}) => {
	return {
		en: en.Message_messageDeleted,
		fr: fr.Message_messageDeleted
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_messageUpdated = (params = {}, options = {}) => {
	return {
		en: en.Message_messageUpdated,
		fr: fr.Message_messageUpdated
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_edit = (params = {}, options = {}) => {
	return {
		en: en.Message_edit,
		fr: fr.Message_edit
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_delete = (params = {}, options = {}) => {
	return {
		en: en.Message_delete,
		fr: fr.Message_delete
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_markAsBestAnswerButton = (params = {}, options = {}) => {
	return {
		en: en.Message_markAsBestAnswerButton,
		fr: fr.Message_markAsBestAnswerButton
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_bestAnswerBadge = (params = {}, options = {}) => {
	return {
		en: en.Message_bestAnswerBadge,
		fr: fr.Message_bestAnswerBadge
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_sendAnswer = (params = {}, options = {}) => {
	return {
		en: en.course_content_sendAnswer,
		fr: fr.course_content_sendAnswer
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_currentSession = (params = {}, options = {}) => {
	return {
		en: en.course_content_currentSession,
		fr: fr.course_content_currentSession
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_allSessions = (params = {}, options = {}) => {
	return {
		en: en.course_content_allSessions,
		fr: fr.course_content_allSessions
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_noQuestions = (params = {}, options = {}) => {
	return {
		en: en.course_content_noQuestions,
		fr: fr.course_content_noQuestions
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_noAccess = (params = {}, options = {}) => {
	return {
		en: en.course_content_noAccess,
		fr: fr.course_content_noAccess
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_askQuestionButton = (params = {}, options = {}) => {
	return {
		en: en.course_content_askQuestionButton,
		fr: fr.course_content_askQuestionButton
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_sources = (params = {}, options = {}) => {
	return {
		en: en.course_content_sources,
		fr: fr.course_content_sources
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_sourcesDescription = (params = {}, options = {}) => {
	return {
		en: en.course_content_sourcesDescription,
		fr: fr.course_content_sourcesDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_downloadSources = (params = {}, options = {}) => {
	return {
		en: en.course_content_downloadSources,
		fr: fr.course_content_downloadSources
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_tasks = (params = {}, options = {}) => {
	return {
		en: en.course_content_tasks,
		fr: fr.course_content_tasks
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_tasksDescription = (params = {}, options = {}) => {
	return {
		en: en.course_content_tasksDescription,
		fr: fr.course_content_tasksDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_taskPlaceholder = (params = {}, options = {}) => {
	return {
		en: en.course_content_taskPlaceholder,
		fr: fr.course_content_taskPlaceholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_noTasks = (params = {}, options = {}) => {
	return {
		en: en.course_content_noTasks,
		fr: fr.course_content_noTasks
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_certificationProgression = (params = {}, options = {}) => {
	return {
		en: en.course_content_certificationProgression,
		fr: fr.course_content_certificationProgression
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_certification = (params = {}, options = {}) => {
	return {
		en: en.course_content_certification,
		fr: fr.course_content_certification
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_certificationLinkedin = (params = {}, options = {}) => {
	return {
		en: en.course_content_certificationLinkedin,
		fr: fr.course_content_certificationLinkedin
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_certificationLinkedinButton = (params = {}, options = {}) => {
	return {
		en: en.course_content_certificationLinkedinButton,
		fr: fr.course_content_certificationLinkedinButton
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_certificationMalt = (params = {}, options = {}) => {
	return {
		en: en.course_content_certificationMalt,
		fr: fr.course_content_certificationMalt
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_certificationPending = (params = {}, options = {}) => {
	return {
		en: en.course_content_certificationPending,
		fr: fr.course_content_certificationPending
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_certificationProgressionPercent = (params = {}, options = {}) => {
	return {
		en: en.course_content_certificationProgressionPercent,
		fr: fr.course_content_certificationProgressionPercent
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_certificationComplete = (params = {}, options = {}) => {
	return {
		en: en.course_content_certificationComplete,
		fr: fr.course_content_certificationComplete
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_seeCertification = (params = {}, options = {}) => {
	return {
		en: en.course_content_seeCertification,
		fr: fr.course_content_seeCertification
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_getCertification = (params = {}, options = {}) => {
	return {
		en: en.course_content_getCertification,
		fr: fr.course_content_getCertification
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_expertise = (params = {}, options = {}) => {
	return {
		en: en.course_content_expertise,
		fr: fr.course_content_expertise
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_reportError = (params = {}, options = {}) => {
	return {
		en: en.course_content_reportError,
		fr: fr.course_content_reportError
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_reportErrorDescription = (params = {}, options = {}) => {
	return {
		en: en.course_content_reportErrorDescription,
		fr: fr.course_content_reportErrorDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_errorType = (params = {}, options = {}) => {
	return {
		en: en.course_content_errorType,
		fr: fr.course_content_errorType
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_selectErrorType = (params = {}, options = {}) => {
	return {
		en: en.course_content_selectErrorType,
		fr: fr.course_content_selectErrorType
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_inappropriateContent = (params = {}, options = {}) => {
	return {
		en: en.course_content_inappropriateContent,
		fr: fr.course_content_inappropriateContent
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_deppreciatedContent = (params = {}, options = {}) => {
	return {
		en: en.course_content_deppreciatedContent,
		fr: fr.course_content_deppreciatedContent
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_other = (params = {}, options = {}) => {
	return {
		en: en.course_content_other,
		fr: fr.course_content_other
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_errorDescription = (params = {}, options = {}) => {
	return {
		en: en.course_content_errorDescription,
		fr: fr.course_content_errorDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_sendError = (params = {}, options = {}) => {
	return {
		en: en.course_content_sendError,
		fr: fr.course_content_sendError
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_reinitializeProgression = (params = {}, options = {}) => {
	return {
		en: en.course_content_reinitializeProgression,
		fr: fr.course_content_reinitializeProgression
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_checkProgression = (params = {}, options = {}) => {
	return {
		en: en.course_content_checkProgression,
		fr: fr.course_content_checkProgression
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ number: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const PartInterface_part = (params , options = {}) => {
	return {
		en: en.PartInterface_part,
		fr: fr.PartInterface_part
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Badge_new = (params = {}, options = {}) => {
	return {
		en: en.Badge_new,
		fr: fr.Badge_new
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Badge_updated = (params = {}, options = {}) => {
	return {
		en: en.Badge_updated,
		fr: fr.Badge_updated
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const PartInterface_questions = (params = {}, options = {}) => {
	return {
		en: en.PartInterface_questions,
		fr: fr.PartInterface_questions
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const PartInterface_question = (params = {}, options = {}) => {
	return {
		en: en.PartInterface_question,
		fr: fr.PartInterface_question
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const PartInterface_quiz = (params = {}, options = {}) => {
	return {
		en: en.PartInterface_quiz,
		fr: fr.PartInterface_quiz
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_content_certificationWillBeenSent = (params = {}, options = {}) => {
	return {
		en: en.accelerator_content_certificationWillBeenSent,
		fr: fr.accelerator_content_certificationWillBeenSent
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ number: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const certification_meta_title = (params , options = {}) => {
	return {
		en: en.certification_meta_title,
		fr: fr.certification_meta_title
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ fullname: NonNullable<unknown>, courseName: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const certification_meta_description = (params , options = {}) => {
	return {
		en: en.certification_meta_description,
		fr: fr.certification_meta_description
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ number: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const certification_title = (params , options = {}) => {
	return {
		en: en.certification_title,
		fr: fr.certification_title
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const certification_seeCourse = (params = {}, options = {}) => {
	return {
		en: en.certification_seeCourse,
		fr: fr.certification_seeCourse
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ number: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const certification_description = (params , options = {}) => {
	return {
		en: en.certification_description,
		fr: fr.certification_description
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const certification_share = (params = {}, options = {}) => {
	return {
		en: en.certification_share,
		fr: fr.certification_share
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const certification_shareDescription = (params = {}, options = {}) => {
	return {
		en: en.certification_shareDescription,
		fr: fr.certification_shareDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const certification_shareLinkedin = (params = {}, options = {}) => {
	return {
		en: en.certification_shareLinkedin,
		fr: fr.certification_shareLinkedin
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_certificationFromBelievemy = (params = {}, options = {}) => {
	return {
		en: en.course_content_certificationFromBelievemy,
		fr: fr.course_content_certificationFromBelievemy
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_certificationDescription = (params = {}, options = {}) => {
	return {
		en: en.course_content_certificationDescription,
		fr: fr.course_content_certificationDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_meta_title = (params = {}, options = {}) => {
	return {
		en: en.error_meta_title,
		fr: fr.error_meta_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_meta_description = (params = {}, options = {}) => {
	return {
		en: en.error_meta_description,
		fr: fr.error_meta_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_button = (params = {}, options = {}) => {
	return {
		en: en.error_button,
		fr: fr.error_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const messenger_meta_title = (params = {}, options = {}) => {
	return {
		en: en.messenger_meta_title,
		fr: fr.messenger_meta_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const messenger_meta_description = (params = {}, options = {}) => {
	return {
		en: en.messenger_meta_description,
		fr: fr.messenger_meta_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const messenger_empty_answer = (params = {}, options = {}) => {
	return {
		en: en.messenger_empty_answer,
		fr: fr.messenger_empty_answer
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const messenger_delete_answer_confirmation = (params = {}, options = {}) => {
	return {
		en: en.messenger_delete_answer_confirmation,
		fr: fr.messenger_delete_answer_confirmation
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const messenger_answer_deleted = (params = {}, options = {}) => {
	return {
		en: en.messenger_answer_deleted,
		fr: fr.messenger_answer_deleted
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const messenger_delete_discussion_confirmation = (params = {}, options = {}) => {
	return {
		en: en.messenger_delete_discussion_confirmation,
		fr: fr.messenger_delete_discussion_confirmation
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const messenger_discussion_deleted = (params = {}, options = {}) => {
	return {
		en: en.messenger_discussion_deleted,
		fr: fr.messenger_discussion_deleted
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const messenger_title = (params = {}, options = {}) => {
	return {
		en: en.messenger_title,
		fr: fr.messenger_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const messenger_no_message = (params = {}, options = {}) => {
	return {
		en: en.messenger_no_message,
		fr: fr.messenger_no_message
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const messenger_load_more = (params = {}, options = {}) => {
	return {
		en: en.messenger_load_more,
		fr: fr.messenger_load_more
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const messenger_participants = (params = {}, options = {}) => {
	return {
		en: en.messenger_participants,
		fr: fr.messenger_participants
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const messenger_participant = (params = {}, options = {}) => {
	return {
		en: en.messenger_participant,
		fr: fr.messenger_participant
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const messenger_private_discussion = (params = {}, options = {}) => {
	return {
		en: en.messenger_private_discussion,
		fr: fr.messenger_private_discussion
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const messenger_reply = (params = {}, options = {}) => {
	return {
		en: en.messenger_reply,
		fr: fr.messenger_reply
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const messenger_no_discussion_selected = (params = {}, options = {}) => {
	return {
		en: en.messenger_no_discussion_selected,
		fr: fr.messenger_no_discussion_selected
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ fullname: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_meta_title = (params , options = {}) => {
	return {
		en: en.profile_meta_title,
		fr: fr.profile_meta_title
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ fullname: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_meta_description = (params , options = {}) => {
	return {
		en: en.profile_meta_description,
		fr: fr.profile_meta_description
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_send_message_notVerifiedEmail = (params = {}, options = {}) => {
	return {
		en: en.profile_send_message_notVerifiedEmail,
		fr: fr.profile_send_message_notVerifiedEmail
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_send_message_success = (params = {}, options = {}) => {
	return {
		en: en.profile_send_message_success,
		fr: fr.profile_send_message_success
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ fullname: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_cover_alt = (params , options = {}) => {
	return {
		en: en.profile_cover_alt,
		fr: fr.profile_cover_alt
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ fullname: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_photo_alt = (params , options = {}) => {
	return {
		en: en.profile_photo_alt,
		fr: fr.profile_photo_alt
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_send_message_button = (params = {}, options = {}) => {
	return {
		en: en.profile_send_message_button,
		fr: fr.profile_send_message_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_edit_profile_button = (params = {}, options = {}) => {
	return {
		en: en.profile_edit_profile_button,
		fr: fr.profile_edit_profile_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_nav_about = (params = {}, options = {}) => {
	return {
		en: en.profile_nav_about,
		fr: fr.profile_nav_about
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_nav_articles = (params = {}, options = {}) => {
	return {
		en: en.profile_nav_articles,
		fr: fr.profile_nav_articles
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_nav_courses = (params = {}, options = {}) => {
	return {
		en: en.profile_nav_courses,
		fr: fr.profile_nav_courses
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_portrait = (params = {}, options = {}) => {
	return {
		en: en.profile_portrait,
		fr: fr.profile_portrait
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_projects = (params = {}, options = {}) => {
	return {
		en: en.profile_projects,
		fr: fr.profile_projects
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_projects_empty = (params = {}, options = {}) => {
	return {
		en: en.profile_projects_empty,
		fr: fr.profile_projects_empty
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_projects_no_description1 = (params = {}, options = {}) => {
	return {
		en: en.profile_projects_no_description1,
		fr: fr.profile_projects_no_description1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_projects_no_description2 = (params = {}, options = {}) => {
	return {
		en: en.profile_projects_no_description2,
		fr: fr.profile_projects_no_description2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_certifications = (params = {}, options = {}) => {
	return {
		en: en.profile_certifications,
		fr: fr.profile_certifications
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ date: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_certification_delivered = (params , options = {}) => {
	return {
		en: en.profile_certification_delivered,
		fr: fr.profile_certification_delivered
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_certifications_empty = (params = {}, options = {}) => {
	return {
		en: en.profile_certifications_empty,
		fr: fr.profile_certifications_empty
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_articles = (params = {}, options = {}) => {
	return {
		en: en.profile_articles,
		fr: fr.profile_articles
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_articles_empty = (params = {}, options = {}) => {
	return {
		en: en.profile_articles_empty,
		fr: fr.profile_articles_empty
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_courses = (params = {}, options = {}) => {
	return {
		en: en.profile_courses,
		fr: fr.profile_courses
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_courses_empty = (params = {}, options = {}) => {
	return {
		en: en.profile_courses_empty,
		fr: fr.profile_courses_empty
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_infos = (params = {}, options = {}) => {
	return {
		en: en.profile_infos,
		fr: fr.profile_infos
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ date: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_inscriptionDate = (params , options = {}) => {
	return {
		en: en.profile_inscriptionDate,
		fr: fr.profile_inscriptionDate
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ date: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_lastActivity = (params , options = {}) => {
	return {
		en: en.profile_lastActivity,
		fr: fr.profile_lastActivity
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_fragments = (params = {}, options = {}) => {
	return {
		en: en.profile_fragments,
		fr: fr.profile_fragments
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ fullname: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_send_message = (params , options = {}) => {
	return {
		en: en.profile_send_message,
		fr: fr.profile_send_message
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_send_message_placeholder = (params = {}, options = {}) => {
	return {
		en: en.profile_send_message_placeholder,
		fr: fr.profile_send_message_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const believemy_originals = (params = {}, options = {}) => {
	return {
		en: en.believemy_originals,
		fr: fr.believemy_originals
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ fullname: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const DisplayArticle_write = (params , options = {}) => {
	return {
		en: en.DisplayArticle_write,
		fr: fr.DisplayArticle_write
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_meta_title = (params = {}, options = {}) => {
	return {
		en: en.account_meta_title,
		fr: fr.account_meta_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_meta_description = (params = {}, options = {}) => {
	return {
		en: en.account_meta_description,
		fr: fr.account_meta_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_pseudo_error = (params = {}, options = {}) => {
	return {
		en: en.account_pseudo_error,
		fr: fr.account_pseudo_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_data_updated = (params = {}, options = {}) => {
	return {
		en: en.account_data_updated,
		fr: fr.account_data_updated
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_password_updated = (params = {}, options = {}) => {
	return {
		en: en.account_password_updated,
		fr: fr.account_password_updated
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_message_error = (params = {}, options = {}) => {
	return {
		en: en.account_message_error,
		fr: fr.account_message_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscription_canceled = (params = {}, options = {}) => {
	return {
		en: en.account_subscription_canceled,
		fr: fr.account_subscription_canceled
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_code_error = (params = {}, options = {}) => {
	return {
		en: en.account_code_error,
		fr: fr.account_code_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_code = (params = {}, options = {}) => {
	return {
		en: en.account_code,
		fr: fr.account_code
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_photo_error = (params = {}, options = {}) => {
	return {
		en: en.account_photo_error,
		fr: fr.account_photo_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_project_added = (params = {}, options = {}) => {
	return {
		en: en.account_project_added,
		fr: fr.account_project_added
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_project_removed = (params = {}, options = {}) => {
	return {
		en: en.account_project_removed,
		fr: fr.account_project_removed
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_error = (params = {}, options = {}) => {
	return {
		en: en.account_error,
		fr: fr.account_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_photo_updated = (params = {}, options = {}) => {
	return {
		en: en.account_photo_updated,
		fr: fr.account_photo_updated
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_cover_updated = (params = {}, options = {}) => {
	return {
		en: en.account_cover_updated,
		fr: fr.account_cover_updated
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_redirect = (params = {}, options = {}) => {
	return {
		en: en.account_redirect,
		fr: fr.account_redirect
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_cancel_subscription = (params = {}, options = {}) => {
	return {
		en: en.account_cancel_subscription,
		fr: fr.account_cancel_subscription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_mentoring = (params = {}, options = {}) => {
	return {
		en: en.account_mentoring,
		fr: fr.account_mentoring
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_premium = (params = {}, options = {}) => {
	return {
		en: en.account_premium,
		fr: fr.account_premium
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_cancel_subscription_description = (params = {}, options = {}) => {
	return {
		en: en.account_cancel_subscription_description,
		fr: fr.account_cancel_subscription_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_cancel_subscription_description_bis = (params = {}, options = {}) => {
	return {
		en: en.account_cancel_subscription_description_bis,
		fr: fr.account_cancel_subscription_description_bis
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_mentoring_description = (params = {}, options = {}) => {
	return {
		en: en.account_mentoring_description,
		fr: fr.account_mentoring_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_premium_description = (params = {}, options = {}) => {
	return {
		en: en.account_premium_description,
		fr: fr.account_premium_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_cancel_subscription_message = (params = {}, options = {}) => {
	return {
		en: en.account_cancel_subscription_message,
		fr: fr.account_cancel_subscription_message
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_cancel_subscription_trial = (params = {}, options = {}) => {
	return {
		en: en.account_cancel_subscription_trial,
		fr: fr.account_cancel_subscription_trial
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_cancel_subscription_message_placeholder = (params = {}, options = {}) => {
	return {
		en: en.account_cancel_subscription_message_placeholder,
		fr: fr.account_cancel_subscription_message_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_cancel_subscription_button = (params = {}, options = {}) => {
	return {
		en: en.account_cancel_subscription_button,
		fr: fr.account_cancel_subscription_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_title = (params = {}, options = {}) => {
	return {
		en: en.account_title,
		fr: fr.account_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_description = (params = {}, options = {}) => {
	return {
		en: en.account_description,
		fr: fr.account_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_nav_informations = (params = {}, options = {}) => {
	return {
		en: en.account_nav_informations,
		fr: fr.account_nav_informations
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_nav_invoices = (params = {}, options = {}) => {
	return {
		en: en.account_nav_invoices,
		fr: fr.account_nav_invoices
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_nav_subscriptions = (params = {}, options = {}) => {
	return {
		en: en.account_nav_subscriptions,
		fr: fr.account_nav_subscriptions
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations = (params = {}, options = {}) => {
	return {
		en: en.account_informations,
		fr: fr.account_informations
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_description = (params = {}, options = {}) => {
	return {
		en: en.account_informations_description,
		fr: fr.account_informations_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_email = (params = {}, options = {}) => {
	return {
		en: en.account_informations_email,
		fr: fr.account_informations_email
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_email_placeholder = (params = {}, options = {}) => {
	return {
		en: en.account_informations_email_placeholder,
		fr: fr.account_informations_email_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_email_required = (params = {}, options = {}) => {
	return {
		en: en.account_informations_email_required,
		fr: fr.account_informations_email_required
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_email_error = (params = {}, options = {}) => {
	return {
		en: en.account_informations_email_error,
		fr: fr.account_informations_email_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_firstName = (params = {}, options = {}) => {
	return {
		en: en.account_informations_firstName,
		fr: fr.account_informations_firstName
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_firstName_placeholder = (params = {}, options = {}) => {
	return {
		en: en.account_informations_firstName_placeholder,
		fr: fr.account_informations_firstName_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_firstName_required = (params = {}, options = {}) => {
	return {
		en: en.account_informations_firstName_required,
		fr: fr.account_informations_firstName_required
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_lastName = (params = {}, options = {}) => {
	return {
		en: en.account_informations_lastName,
		fr: fr.account_informations_lastName
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_lastName_placeholder = (params = {}, options = {}) => {
	return {
		en: en.account_informations_lastName_placeholder,
		fr: fr.account_informations_lastName_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_lastName_required = (params = {}, options = {}) => {
	return {
		en: en.account_informations_lastName_required,
		fr: fr.account_informations_lastName_required
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_timezone = (params = {}, options = {}) => {
	return {
		en: en.account_informations_timezone,
		fr: fr.account_informations_timezone
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_pseudo = (params = {}, options = {}) => {
	return {
		en: en.account_informations_pseudo,
		fr: fr.account_informations_pseudo
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_pseudo_placeholder = (params = {}, options = {}) => {
	return {
		en: en.account_informations_pseudo_placeholder,
		fr: fr.account_informations_pseudo_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_pseudo_error = (params = {}, options = {}) => {
	return {
		en: en.account_informations_pseudo_error,
		fr: fr.account_informations_pseudo_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_presentation = (params = {}, options = {}) => {
	return {
		en: en.account_informations_presentation,
		fr: fr.account_informations_presentation
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_presentation_placeholder = (params = {}, options = {}) => {
	return {
		en: en.account_informations_presentation_placeholder,
		fr: fr.account_informations_presentation_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_presentation_error = (params = {}, options = {}) => {
	return {
		en: en.account_informations_presentation_error,
		fr: fr.account_informations_presentation_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_website = (params = {}, options = {}) => {
	return {
		en: en.account_informations_website,
		fr: fr.account_informations_website
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_website_placeholder = (params = {}, options = {}) => {
	return {
		en: en.account_informations_website_placeholder,
		fr: fr.account_informations_website_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_twitter = (params = {}, options = {}) => {
	return {
		en: en.account_informations_twitter,
		fr: fr.account_informations_twitter
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_twitter_placeholder = (params = {}, options = {}) => {
	return {
		en: en.account_informations_twitter_placeholder,
		fr: fr.account_informations_twitter_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_github = (params = {}, options = {}) => {
	return {
		en: en.account_informations_github,
		fr: fr.account_informations_github
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_github_placeholder = (params = {}, options = {}) => {
	return {
		en: en.account_informations_github_placeholder,
		fr: fr.account_informations_github_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_linkedin = (params = {}, options = {}) => {
	return {
		en: en.account_informations_linkedin,
		fr: fr.account_informations_linkedin
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_linkedin_placeholder = (params = {}, options = {}) => {
	return {
		en: en.account_informations_linkedin_placeholder,
		fr: fr.account_informations_linkedin_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_birthday = (params = {}, options = {}) => {
	return {
		en: en.account_informations_birthday,
		fr: fr.account_informations_birthday
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_january = (params = {}, options = {}) => {
	return {
		en: en.account_informations_january,
		fr: fr.account_informations_january
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_february = (params = {}, options = {}) => {
	return {
		en: en.account_informations_february,
		fr: fr.account_informations_february
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_march = (params = {}, options = {}) => {
	return {
		en: en.account_informations_march,
		fr: fr.account_informations_march
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_april = (params = {}, options = {}) => {
	return {
		en: en.account_informations_april,
		fr: fr.account_informations_april
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_may = (params = {}, options = {}) => {
	return {
		en: en.account_informations_may,
		fr: fr.account_informations_may
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_june = (params = {}, options = {}) => {
	return {
		en: en.account_informations_june,
		fr: fr.account_informations_june
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_july = (params = {}, options = {}) => {
	return {
		en: en.account_informations_july,
		fr: fr.account_informations_july
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_august = (params = {}, options = {}) => {
	return {
		en: en.account_informations_august,
		fr: fr.account_informations_august
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_september = (params = {}, options = {}) => {
	return {
		en: en.account_informations_september,
		fr: fr.account_informations_september
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_october = (params = {}, options = {}) => {
	return {
		en: en.account_informations_october,
		fr: fr.account_informations_october
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_november = (params = {}, options = {}) => {
	return {
		en: en.account_informations_november,
		fr: fr.account_informations_november
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_december = (params = {}, options = {}) => {
	return {
		en: en.account_informations_december,
		fr: fr.account_informations_december
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_discord = (params = {}, options = {}) => {
	return {
		en: en.account_informations_discord,
		fr: fr.account_informations_discord
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_discord_placeholder = (params = {}, options = {}) => {
	return {
		en: en.account_informations_discord_placeholder,
		fr: fr.account_informations_discord_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_discord_description = (params = {}, options = {}) => {
	return {
		en: en.account_informations_discord_description,
		fr: fr.account_informations_discord_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_password_security = (params = {}, options = {}) => {
	return {
		en: en.account_informations_password_security,
		fr: fr.account_informations_password_security
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_password_required = (params = {}, options = {}) => {
	return {
		en: en.account_informations_password_required,
		fr: fr.account_informations_password_required
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_save = (params = {}, options = {}) => {
	return {
		en: en.account_informations_save,
		fr: fr.account_informations_save
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_projects = (params = {}, options = {}) => {
	return {
		en: en.account_projects,
		fr: fr.account_projects
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_projects_description = (params = {}, options = {}) => {
	return {
		en: en.account_projects_description,
		fr: fr.account_projects_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_projects_add = (params = {}, options = {}) => {
	return {
		en: en.account_projects_add,
		fr: fr.account_projects_add
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_projects_add_placeholder = (params = {}, options = {}) => {
	return {
		en: en.account_projects_add_placeholder,
		fr: fr.account_projects_add_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_billing = (params = {}, options = {}) => {
	return {
		en: en.account_billing,
		fr: fr.account_billing
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_billing_description = (params = {}, options = {}) => {
	return {
		en: en.account_billing_description,
		fr: fr.account_billing_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_country_required = (params = {}, options = {}) => {
	return {
		en: en.address_country_required,
		fr: fr.address_country_required
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_billing_save = (params = {}, options = {}) => {
	return {
		en: en.account_billing_save,
		fr: fr.account_billing_save
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_security = (params = {}, options = {}) => {
	return {
		en: en.account_security,
		fr: fr.account_security
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_security_description = (params = {}, options = {}) => {
	return {
		en: en.account_security_description,
		fr: fr.account_security_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_security_oldPassword = (params = {}, options = {}) => {
	return {
		en: en.account_security_oldPassword,
		fr: fr.account_security_oldPassword
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_security_oldPassword_placeholder = (params = {}, options = {}) => {
	return {
		en: en.account_security_oldPassword_placeholder,
		fr: fr.account_security_oldPassword_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_security_oldPassword_required = (params = {}, options = {}) => {
	return {
		en: en.account_security_oldPassword_required,
		fr: fr.account_security_oldPassword_required
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_security_newPassword = (params = {}, options = {}) => {
	return {
		en: en.account_security_newPassword,
		fr: fr.account_security_newPassword
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_security_newPassword_placeholder = (params = {}, options = {}) => {
	return {
		en: en.account_security_newPassword_placeholder,
		fr: fr.account_security_newPassword_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_security_newPassword_required = (params = {}, options = {}) => {
	return {
		en: en.account_security_newPassword_required,
		fr: fr.account_security_newPassword_required
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_security_save = (params = {}, options = {}) => {
	return {
		en: en.account_security_save,
		fr: fr.account_security_save
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_profilePicture = (params = {}, options = {}) => {
	return {
		en: en.account_profilePicture,
		fr: fr.account_profilePicture
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_profilePicture_chooseFile = (params = {}, options = {}) => {
	return {
		en: en.account_profilePicture_chooseFile,
		fr: fr.account_profilePicture_chooseFile
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_profilePicture_save = (params = {}, options = {}) => {
	return {
		en: en.account_profilePicture_save,
		fr: fr.account_profilePicture_save
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_coverPicture = (params = {}, options = {}) => {
	return {
		en: en.account_coverPicture,
		fr: fr.account_coverPicture
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_coverPicture_chooseFile = (params = {}, options = {}) => {
	return {
		en: en.account_coverPicture_chooseFile,
		fr: fr.account_coverPicture_chooseFile
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_coverPicture_save = (params = {}, options = {}) => {
	return {
		en: en.account_coverPicture_save,
		fr: fr.account_coverPicture_save
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ orderNumber: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_invoice_orderNumber = (params , options = {}) => {
	return {
		en: en.account_invoice_orderNumber,
		fr: fr.account_invoice_orderNumber
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_invoice_refunded = (params = {}, options = {}) => {
	return {
		en: en.account_invoice_refunded,
		fr: fr.account_invoice_refunded
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ total: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_invoice_total = (params , options = {}) => {
	return {
		en: en.account_invoice_total,
		fr: fr.account_invoice_total
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_noInvoices = (params = {}, options = {}) => {
	return {
		en: en.account_noInvoices,
		fr: fr.account_noInvoices
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions = (params = {}, options = {}) => {
	return {
		en: en.account_subscriptions,
		fr: fr.account_subscriptions
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_current = (params = {}, options = {}) => {
	return {
		en: en.account_subscriptions_current,
		fr: fr.account_subscriptions_current
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_current_description = (params = {}, options = {}) => {
	return {
		en: en.account_subscriptions_current_description,
		fr: fr.account_subscriptions_current_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_current_mensual = (params = {}, options = {}) => {
	return {
		en: en.account_subscriptions_current_mensual,
		fr: fr.account_subscriptions_current_mensual
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_current_euro = (params = {}, options = {}) => {
	return {
		en: en.account_subscriptions_current_euro,
		fr: fr.account_subscriptions_current_euro
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_current_sixMonths = (params = {}, options = {}) => {
	return {
		en: en.account_subscriptions_current_sixMonths,
		fr: fr.account_subscriptions_current_sixMonths
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_current_annually = (params = {}, options = {}) => {
	return {
		en: en.account_subscriptions_current_annually,
		fr: fr.account_subscriptions_current_annually
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ date: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_trial = (params , options = {}) => {
	return {
		en: en.account_subscriptions_trial,
		fr: fr.account_subscriptions_trial
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ date: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_trialEnd = (params , options = {}) => {
	return {
		en: en.account_subscriptions_trialEnd,
		fr: fr.account_subscriptions_trialEnd
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_cancelTrial = (params = {}, options = {}) => {
	return {
		en: en.account_subscriptions_cancelTrial,
		fr: fr.account_subscriptions_cancelTrial
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ date: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_started = (params , options = {}) => {
	return {
		en: en.account_subscriptions_started,
		fr: fr.account_subscriptions_started
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ date: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_nextBilling = (params , options = {}) => {
	return {
		en: en.account_subscriptions_nextBilling,
		fr: fr.account_subscriptions_nextBilling
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ date: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_engagement = (params , options = {}) => {
	return {
		en: en.account_subscriptions_engagement,
		fr: fr.account_subscriptions_engagement
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_month = (params = {}, options = {}) => {
	return {
		en: en.account_subscriptions_month,
		fr: fr.account_subscriptions_month
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_year = (params = {}, options = {}) => {
	return {
		en: en.account_subscriptions_year,
		fr: fr.account_subscriptions_year
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_cancel = (params = {}, options = {}) => {
	return {
		en: en.account_subscriptions_cancel,
		fr: fr.account_subscriptions_cancel
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_noSubscriptions = (params = {}, options = {}) => {
	return {
		en: en.account_subscriptions_noSubscriptions,
		fr: fr.account_subscriptions_noSubscriptions
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_noSubscriptions_description = (params = {}, options = {}) => {
	return {
		en: en.account_subscriptions_noSubscriptions_description,
		fr: fr.account_subscriptions_noSubscriptions_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_noSubscriptions_why1 = (params = {}, options = {}) => {
	return {
		en: en.account_subscriptions_noSubscriptions_why1,
		fr: fr.account_subscriptions_noSubscriptions_why1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_noSubscriptions_why2 = (params = {}, options = {}) => {
	return {
		en: en.account_subscriptions_noSubscriptions_why2,
		fr: fr.account_subscriptions_noSubscriptions_why2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_noSubscriptions_why3 = (params = {}, options = {}) => {
	return {
		en: en.account_subscriptions_noSubscriptions_why3,
		fr: fr.account_subscriptions_noSubscriptions_why3
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_noSubscriptions_why4 = (params = {}, options = {}) => {
	return {
		en: en.account_subscriptions_noSubscriptions_why4,
		fr: fr.account_subscriptions_noSubscriptions_why4
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_noSubscriptions_button = (params = {}, options = {}) => {
	return {
		en: en.account_subscriptions_noSubscriptions_button,
		fr: fr.account_subscriptions_noSubscriptions_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ number: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoice_title = (params , options = {}) => {
	return {
		en: en.invoice_title,
		fr: fr.invoice_title
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ date: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoice_created = (params , options = {}) => {
	return {
		en: en.invoice_created,
		fr: fr.invoice_created
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ date: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoice_paid = (params , options = {}) => {
	return {
		en: en.invoice_paid,
		fr: fr.invoice_paid
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoice_company = (params = {}, options = {}) => {
	return {
		en: en.invoice_company,
		fr: fr.invoice_company
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoice_customer = (params = {}, options = {}) => {
	return {
		en: en.invoice_customer,
		fr: fr.invoice_customer
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoice_paymentMethod = (params = {}, options = {}) => {
	return {
		en: en.invoice_paymentMethod,
		fr: fr.invoice_paymentMethod
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoice_creditCard = (params = {}, options = {}) => {
	return {
		en: en.invoice_creditCard,
		fr: fr.invoice_creditCard
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoice_designation = (params = {}, options = {}) => {
	return {
		en: en.invoice_designation,
		fr: fr.invoice_designation
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoice_amountExclVAT = (params = {}, options = {}) => {
	return {
		en: en.invoice_amountExclVAT,
		fr: fr.invoice_amountExclVAT
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoice_totalExclVAT = (params = {}, options = {}) => {
	return {
		en: en.invoice_totalExclVAT,
		fr: fr.invoice_totalExclVAT
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoice_expedition = (params = {}, options = {}) => {
	return {
		en: en.invoice_expedition,
		fr: fr.invoice_expedition
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoice_amountVAT = (params = {}, options = {}) => {
	return {
		en: en.invoice_amountVAT,
		fr: fr.invoice_amountVAT
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoice_totalInclVAT = (params = {}, options = {}) => {
	return {
		en: en.invoice_totalInclVAT,
		fr: fr.invoice_totalInclVAT
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoice_amountRefunded = (params = {}, options = {}) => {
	return {
		en: en.invoice_amountRefunded,
		fr: fr.invoice_amountRefunded
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoice_VATNotApplicable = (params = {}, options = {}) => {
	return {
		en: en.invoice_VATNotApplicable,
		fr: fr.invoice_VATNotApplicable
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoice_VATExempted = (params = {}, options = {}) => {
	return {
		en: en.invoice_VATExempted,
		fr: fr.invoice_VATExempted
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoice_legalNotice = (params = {}, options = {}) => {
	return {
		en: en.invoice_legalNotice,
		fr: fr.invoice_legalNotice
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_notConnected_meta_title = (params = {}, options = {}) => {
	return {
		en: en.home_notConnected_meta_title,
		fr: fr.home_notConnected_meta_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_notConnected_meta_description = (params = {}, options = {}) => {
	return {
		en: en.home_notConnected_meta_description,
		fr: fr.home_notConnected_meta_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_connected_meta_title = (params = {}, options = {}) => {
	return {
		en: en.home_connected_meta_title,
		fr: fr.home_connected_meta_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_connected_meta_description = (params = {}, options = {}) => {
	return {
		en: en.home_connected_meta_description,
		fr: fr.home_connected_meta_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_connected_see_button = (params = {}, options = {}) => {
	return {
		en: en.home_connected_see_button,
		fr: fr.home_connected_see_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_new_title = (params = {}, options = {}) => {
	return {
		en: en.home_new_title,
		fr: fr.home_new_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ time: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ProgressionCard_minutesPassed = (params , options = {}) => {
	return {
		en: en.ProgressionCard_minutesPassed,
		fr: fr.ProgressionCard_minutesPassed
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ time: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ProgressionCard_minutePassed = (params , options = {}) => {
	return {
		en: en.ProgressionCard_minutePassed,
		fr: fr.ProgressionCard_minutePassed
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Highlight_episode = (params = {}, options = {}) => {
	return {
		en: en.Highlight_episode,
		fr: fr.Highlight_episode
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Highlight_course = (params = {}, options = {}) => {
	return {
		en: en.Highlight_course,
		fr: fr.Highlight_course
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Highlight_announcement = (params = {}, options = {}) => {
	return {
		en: en.Highlight_announcement,
		fr: fr.Highlight_announcement
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Episode_episode = (params = {}, options = {}) => {
	return {
		en: en.Episode_episode,
		fr: fr.Episode_episode
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookedEvent_live = (params = {}, options = {}) => {
	return {
		en: en.BookedEvent_live,
		fr: fr.BookedEvent_live
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookedEvent_call = (params = {}, options = {}) => {
	return {
		en: en.BookedEvent_call,
		fr: fr.BookedEvent_call
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ fullname: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookedEvent_call_title = (params , options = {}) => {
	return {
		en: en.BookedEvent_call_title,
		fr: fr.BookedEvent_call_title
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ date: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookedEvent_today = (params , options = {}) => {
	return {
		en: en.BookedEvent_today,
		fr: fr.BookedEvent_today
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ date: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookedEvent_tomorrow = (params , options = {}) => {
	return {
		en: en.BookedEvent_tomorrow,
		fr: fr.BookedEvent_tomorrow
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ day: NonNullable<unknown>, hour: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookedEvent_nextWeek = (params , options = {}) => {
	return {
		en: en.BookedEvent_nextWeek,
		fr: fr.BookedEvent_nextWeek
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ date: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookedEvent_yesterday = (params , options = {}) => {
	return {
		en: en.BookedEvent_yesterday,
		fr: fr.BookedEvent_yesterday
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ minutesPerCall: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookedEvent_minutesPerCall = (params , options = {}) => {
	return {
		en: en.BookedEvent_minutesPerCall,
		fr: fr.BookedEvent_minutesPerCall
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookedEvent_join = (params = {}, options = {}) => {
	return {
		en: en.BookedEvent_join,
		fr: fr.BookedEvent_join
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SerieModale_seasons = (params = {}, options = {}) => {
	return {
		en: en.SerieModale_seasons,
		fr: fr.SerieModale_seasons
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SerieModale_season = (params = {}, options = {}) => {
	return {
		en: en.SerieModale_season,
		fr: fr.SerieModale_season
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SerieModale_serie = (params = {}, options = {}) => {
	return {
		en: en.SerieModale_serie,
		fr: fr.SerieModale_serie
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SerieModale_watch = (params = {}, options = {}) => {
	return {
		en: en.SerieModale_watch,
		fr: fr.SerieModale_watch
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SerieModale_presentation = (params = {}, options = {}) => {
	return {
		en: en.SerieModale_presentation,
		fr: fr.SerieModale_presentation
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SerieModale_episodes = (params = {}, options = {}) => {
	return {
		en: en.SerieModale_episodes,
		fr: fr.SerieModale_episodes
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ number: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SerieModale_seasonNumber = (params , options = {}) => {
	return {
		en: en.SerieModale_seasonNumber,
		fr: fr.SerieModale_seasonNumber
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_connected_yourCalls_title = (params = {}, options = {}) => {
	return {
		en: en.home_connected_yourCalls_title,
		fr: fr.home_connected_yourCalls_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_connected_progressions_title = (params = {}, options = {}) => {
	return {
		en: en.home_connected_progressions_title,
		fr: fr.home_connected_progressions_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_connected_courses_title = (params = {}, options = {}) => {
	return {
		en: en.home_connected_courses_title,
		fr: fr.home_connected_courses_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_connected_articles_title = (params = {}, options = {}) => {
	return {
		en: en.home_connected_articles_title,
		fr: fr.home_connected_articles_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_connected_believemyOriginals_title = (params = {}, options = {}) => {
	return {
		en: en.home_connected_believemyOriginals_title,
		fr: fr.home_connected_believemyOriginals_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_connected_mentoring_title = (params = {}, options = {}) => {
	return {
		en: en.home_connected_mentoring_title,
		fr: fr.home_connected_mentoring_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const serie_noEpisodes = (params = {}, options = {}) => {
	return {
		en: en.serie_noEpisodes,
		fr: fr.serie_noEpisodes
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const serie_watch_youAreWatching = (params = {}, options = {}) => {
	return {
		en: en.serie_watch_youAreWatching,
		fr: fr.serie_watch_youAreWatching
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ number: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const serie_watch_season = (params , options = {}) => {
	return {
		en: en.serie_watch_season,
		fr: fr.serie_watch_season
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ number: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const serie_watch_episode = (params , options = {}) => {
	return {
		en: en.serie_watch_episode,
		fr: fr.serie_watch_episode
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ number: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const serie_watch_shortEpisode = (params , options = {}) => {
	return {
		en: en.serie_watch_shortEpisode,
		fr: fr.serie_watch_shortEpisode
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const serie_watch_noSubtitles = (params = {}, options = {}) => {
	return {
		en: en.serie_watch_noSubtitles,
		fr: fr.serie_watch_noSubtitles
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const serie_language = (params = {}, options = {}) => {
	return {
		en: en.serie_language,
		fr: fr.serie_language
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SideHeader_createAccountButton = (params = {}, options = {}) => {
	return {
		en: en.SideHeader_createAccountButton,
		fr: fr.SideHeader_createAccountButton
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SideHeader_loginButton = (params = {}, options = {}) => {
	return {
		en: en.SideHeader_loginButton,
		fr: fr.SideHeader_loginButton
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const UserLayout_login = (params = {}, options = {}) => {
	return {
		en: en.UserLayout_login,
		fr: fr.UserLayout_login
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const UserLayout_signup = (params = {}, options = {}) => {
	return {
		en: en.UserLayout_signup,
		fr: fr.UserLayout_signup
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CourseCard_soon_title = (params = {}, options = {}) => {
	return {
		en: en.CourseCard_soon_title,
		fr: fr.CourseCard_soon_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CourseCard_soon_summary = (params = {}, options = {}) => {
	return {
		en: en.CourseCard_soon_summary,
		fr: fr.CourseCard_soon_summary
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ArticleCard_soon_title = (params = {}, options = {}) => {
	return {
		en: en.ArticleCard_soon_title,
		fr: fr.ArticleCard_soon_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ArticleCard_soon_summary = (params = {}, options = {}) => {
	return {
		en: en.ArticleCard_soon_summary,
		fr: fr.ArticleCard_soon_summary
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_noLanguageFoundWelcome1 = (params = {}, options = {}) => {
	return {
		en: en.home_noLanguageFoundWelcome1,
		fr: fr.home_noLanguageFoundWelcome1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_noLanguageFoundWelcome2 = (params = {}, options = {}) => {
	return {
		en: en.home_noLanguageFoundWelcome2,
		fr: fr.home_noLanguageFoundWelcome2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_noLanguageFoundTitle = (params = {}, options = {}) => {
	return {
		en: en.home_noLanguageFoundTitle,
		fr: fr.home_noLanguageFoundTitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_h1 = (params = {}, options = {}) => {
	return {
		en: en.home_h1,
		fr: fr.home_h1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ number: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const TestimonialTooltip_title = (params , options = {}) => {
	return {
		en: en.TestimonialTooltip_title,
		fr: fr.TestimonialTooltip_title
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const TestimonialTooltip_jeromeDelauney = (params = {}, options = {}) => {
	return {
		en: en.TestimonialTooltip_jeromeDelauney,
		fr: fr.TestimonialTooltip_jeromeDelauney
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const TestimonialTooltip_shompothKhan = (params = {}, options = {}) => {
	return {
		en: en.TestimonialTooltip_shompothKhan,
		fr: fr.TestimonialTooltip_shompothKhan
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const TestimonialTooltip_mansourMahamat = (params = {}, options = {}) => {
	return {
		en: en.TestimonialTooltip_mansourMahamat,
		fr: fr.TestimonialTooltip_mansourMahamat
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const TestimonialTooltip_manonEche = (params = {}, options = {}) => {
	return {
		en: en.TestimonialTooltip_manonEche,
		fr: fr.TestimonialTooltip_manonEche
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const TestimonialTooltip_allianceTshindayi = (params = {}, options = {}) => {
	return {
		en: en.TestimonialTooltip_allianceTshindayi,
		fr: fr.TestimonialTooltip_allianceTshindayi
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const HeroForMain_title = (params = {}, options = {}) => {
	return {
		en: en.HeroForMain_title,
		fr: fr.HeroForMain_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const HeroForMain_headline = (params = {}, options = {}) => {
	return {
		en: en.HeroForMain_headline,
		fr: fr.HeroForMain_headline
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const HeroForMain_button = (params = {}, options = {}) => {
	return {
		en: en.HeroForMain_button,
		fr: fr.HeroForMain_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_meta_title = (params = {}, options = {}) => {
	return {
		en: en.login_meta_title,
		fr: fr.login_meta_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_meta_description = (params = {}, options = {}) => {
	return {
		en: en.login_meta_description,
		fr: fr.login_meta_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_meta_title = (params = {}, options = {}) => {
	return {
		en: en.signup_meta_title,
		fr: fr.signup_meta_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_meta_description = (params = {}, options = {}) => {
	return {
		en: en.signup_meta_description,
		fr: fr.signup_meta_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ fullName: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const welcome_back = (params , options = {}) => {
	return {
		en: en.welcome_back,
		fr: fr.welcome_back
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_connected = (params = {}, options = {}) => {
	return {
		en: en.login_connected,
		fr: fr.login_connected
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_unknowedError = (params = {}, options = {}) => {
	return {
		en: en.login_unknowedError,
		fr: fr.login_unknowedError
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_title = (params = {}, options = {}) => {
	return {
		en: en.login_title,
		fr: fr.login_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_headline = (params = {}, options = {}) => {
	return {
		en: en.login_headline,
		fr: fr.login_headline
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_google = (params = {}, options = {}) => {
	return {
		en: en.login_google,
		fr: fr.login_google
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_else = (params = {}, options = {}) => {
	return {
		en: en.login_else,
		fr: fr.login_else
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_or = (params = {}, options = {}) => {
	return {
		en: en.login_or,
		fr: fr.login_or
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_email = (params = {}, options = {}) => {
	return {
		en: en.login_email,
		fr: fr.login_email
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_email_required = (params = {}, options = {}) => {
	return {
		en: en.login_email_required,
		fr: fr.login_email_required
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_email_invalid = (params = {}, options = {}) => {
	return {
		en: en.login_email_invalid,
		fr: fr.login_email_invalid
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_password = (params = {}, options = {}) => {
	return {
		en: en.login_password,
		fr: fr.login_password
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_password_required = (params = {}, options = {}) => {
	return {
		en: en.login_password_required,
		fr: fr.login_password_required
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_submit = (params = {}, options = {}) => {
	return {
		en: en.login_submit,
		fr: fr.login_submit
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_forgottenPassword = (params = {}, options = {}) => {
	return {
		en: en.login_forgottenPassword,
		fr: fr.login_forgottenPassword
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_noAccount = (params = {}, options = {}) => {
	return {
		en: en.login_noAccount,
		fr: fr.login_noAccount
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_createYourAccount = (params = {}, options = {}) => {
	return {
		en: en.login_createYourAccount,
		fr: fr.login_createYourAccount
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const verify_account_meta_title = (params = {}, options = {}) => {
	return {
		en: en.verify_account_meta_title,
		fr: fr.verify_account_meta_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const verify_account_meta_description = (params = {}, options = {}) => {
	return {
		en: en.verify_account_meta_description,
		fr: fr.verify_account_meta_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const verify_account_title = (params = {}, options = {}) => {
	return {
		en: en.verify_account_title,
		fr: fr.verify_account_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const verify_account_description_1 = (params = {}, options = {}) => {
	return {
		en: en.verify_account_description_1,
		fr: fr.verify_account_description_1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const verify_account_description_2 = (params = {}, options = {}) => {
	return {
		en: en.verify_account_description_2,
		fr: fr.verify_account_description_2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const verify_account_invalid_code = (params = {}, options = {}) => {
	return {
		en: en.verify_account_invalid_code,
		fr: fr.verify_account_invalid_code
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const verify_account_resend_email = (params = {}, options = {}) => {
	return {
		en: en.verify_account_resend_email,
		fr: fr.verify_account_resend_email
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const verify_account_email_sent = (params = {}, options = {}) => {
	return {
		en: en.verify_account_email_sent,
		fr: fr.verify_account_email_sent
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const verify_account_connected = (params = {}, options = {}) => {
	return {
		en: en.verify_account_connected,
		fr: fr.verify_account_connected
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const verify_account_disconnection = (params = {}, options = {}) => {
	return {
		en: en.verify_account_disconnection,
		fr: fr.verify_account_disconnection
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const verify_account_disconnection_link = (params = {}, options = {}) => {
	return {
		en: en.verify_account_disconnection_link,
		fr: fr.verify_account_disconnection_link
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_meta_title = (params = {}, options = {}) => {
	return {
		en: en.onboarding_meta_title,
		fr: fr.onboarding_meta_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_meta_description = (params = {}, options = {}) => {
	return {
		en: en.onboarding_meta_description,
		fr: fr.onboarding_meta_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_invalidPhoto = (params = {}, options = {}) => {
	return {
		en: en.onboarding_invalidPhoto,
		fr: fr.onboarding_invalidPhoto
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_error = (params = {}, options = {}) => {
	return {
		en: en.onboarding_error,
		fr: fr.onboarding_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ firstName: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_welcome = (params , options = {}) => {
	return {
		en: en.onboarding_welcome,
		fr: fr.onboarding_welcome
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_welcomeHeadline = (params = {}, options = {}) => {
	return {
		en: en.onboarding_welcomeHeadline,
		fr: fr.onboarding_welcomeHeadline
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_start = (params = {}, options = {}) => {
	return {
		en: en.onboarding_start,
		fr: fr.onboarding_start
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_category = (params = {}, options = {}) => {
	return {
		en: en.onboarding_category,
		fr: fr.onboarding_category
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_entrepreneurs = (params = {}, options = {}) => {
	return {
		en: en.onboarding_entrepreneurs,
		fr: fr.onboarding_entrepreneurs
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_developers = (params = {}, options = {}) => {
	return {
		en: en.onboarding_developers,
		fr: fr.onboarding_developers
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_continue = (params = {}, options = {}) => {
	return {
		en: en.onboarding_continue,
		fr: fr.onboarding_continue
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_goal = (params = {}, options = {}) => {
	return {
		en: en.onboarding_goal,
		fr: fr.onboarding_goal
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_discoveringEntrepreneur = (params = {}, options = {}) => {
	return {
		en: en.onboarding_discoveringEntrepreneur,
		fr: fr.onboarding_discoveringEntrepreneur
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_creatingBusiness = (params = {}, options = {}) => {
	return {
		en: en.onboarding_creatingBusiness,
		fr: fr.onboarding_creatingBusiness
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_becomingFreelancer = (params = {}, options = {}) => {
	return {
		en: en.onboarding_becomingFreelancer,
		fr: fr.onboarding_becomingFreelancer
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_learningInvest = (params = {}, options = {}) => {
	return {
		en: en.onboarding_learningInvest,
		fr: fr.onboarding_learningInvest
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_growingBusiness = (params = {}, options = {}) => {
	return {
		en: en.onboarding_growingBusiness,
		fr: fr.onboarding_growingBusiness
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_level = (params = {}, options = {}) => {
	return {
		en: en.onboarding_level,
		fr: fr.onboarding_level
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_beginner = (params = {}, options = {}) => {
	return {
		en: en.onboarding_beginner,
		fr: fr.onboarding_beginner
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_novice = (params = {}, options = {}) => {
	return {
		en: en.onboarding_novice,
		fr: fr.onboarding_novice
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_intermediate = (params = {}, options = {}) => {
	return {
		en: en.onboarding_intermediate,
		fr: fr.onboarding_intermediate
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_advanced = (params = {}, options = {}) => {
	return {
		en: en.onboarding_advanced,
		fr: fr.onboarding_advanced
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_goalForDev = (params = {}, options = {}) => {
	return {
		en: en.onboarding_goalForDev,
		fr: fr.onboarding_goalForDev
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_createFirstWebsite = (params = {}, options = {}) => {
	return {
		en: en.onboarding_createFirstWebsite,
		fr: fr.onboarding_createFirstWebsite
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_createFirstApp = (params = {}, options = {}) => {
	return {
		en: en.onboarding_createFirstApp,
		fr: fr.onboarding_createFirstApp
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_createMySaas = (params = {}, options = {}) => {
	return {
		en: en.onboarding_createMySaas,
		fr: fr.onboarding_createMySaas
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_createMyStartup = (params = {}, options = {}) => {
	return {
		en: en.onboarding_createMyStartup,
		fr: fr.onboarding_createMyStartup
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_dontKnow = (params = {}, options = {}) => {
	return {
		en: en.onboarding_dontKnow,
		fr: fr.onboarding_dontKnow
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_birthday = (params = {}, options = {}) => {
	return {
		en: en.onboarding_birthday,
		fr: fr.onboarding_birthday
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_birthdayHeadline = (params = {}, options = {}) => {
	return {
		en: en.onboarding_birthdayHeadline,
		fr: fr.onboarding_birthdayHeadline
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_photo = (params = {}, options = {}) => {
	return {
		en: en.onboarding_photo,
		fr: fr.onboarding_photo
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_photoHeadline = (params = {}, options = {}) => {
	return {
		en: en.onboarding_photoHeadline,
		fr: fr.onboarding_photoHeadline
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_upload = (params = {}, options = {}) => {
	return {
		en: en.onboarding_upload,
		fr: fr.onboarding_upload
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_skip = (params = {}, options = {}) => {
	return {
		en: en.onboarding_skip,
		fr: fr.onboarding_skip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_sources = (params = {}, options = {}) => {
	return {
		en: en.onboarding_sources,
		fr: fr.onboarding_sources
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_sourcesHeadline = (params = {}, options = {}) => {
	return {
		en: en.onboarding_sourcesHeadline,
		fr: fr.onboarding_sourcesHeadline
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_fromYoutube = (params = {}, options = {}) => {
	return {
		en: en.onboarding_fromYoutube,
		fr: fr.onboarding_fromYoutube
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_fromTiktok = (params = {}, options = {}) => {
	return {
		en: en.onboarding_fromTiktok,
		fr: fr.onboarding_fromTiktok
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_fromInstagram = (params = {}, options = {}) => {
	return {
		en: en.onboarding_fromInstagram,
		fr: fr.onboarding_fromInstagram
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_fromFacebook = (params = {}, options = {}) => {
	return {
		en: en.onboarding_fromFacebook,
		fr: fr.onboarding_fromFacebook
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_fromLinkedin = (params = {}, options = {}) => {
	return {
		en: en.onboarding_fromLinkedin,
		fr: fr.onboarding_fromLinkedin
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_fromTwitter = (params = {}, options = {}) => {
	return {
		en: en.onboarding_fromTwitter,
		fr: fr.onboarding_fromTwitter
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_fromThreads = (params = {}, options = {}) => {
	return {
		en: en.onboarding_fromThreads,
		fr: fr.onboarding_fromThreads
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_fromSearchEngines = (params = {}, options = {}) => {
	return {
		en: en.onboarding_fromSearchEngines,
		fr: fr.onboarding_fromSearchEngines
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_fromAds = (params = {}, options = {}) => {
	return {
		en: en.onboarding_fromAds,
		fr: fr.onboarding_fromAds
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_fromRecommendations = (params = {}, options = {}) => {
	return {
		en: en.onboarding_fromRecommendations,
		fr: fr.onboarding_fromRecommendations
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_fromOther = (params = {}, options = {}) => {
	return {
		en: en.onboarding_fromOther,
		fr: fr.onboarding_fromOther
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_confirm = (params = {}, options = {}) => {
	return {
		en: en.onboarding_confirm,
		fr: fr.onboarding_confirm
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_ready = (params = {}, options = {}) => {
	return {
		en: en.onboarding_ready,
		fr: fr.onboarding_ready
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_readyLabelForButton = (params = {}, options = {}) => {
	return {
		en: en.onboarding_readyLabelForButton,
		fr: fr.onboarding_readyLabelForButton
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_titleForReady = (params = {}, options = {}) => {
	return {
		en: en.onboarding_titleForReady,
		fr: fr.onboarding_titleForReady
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenPassword_meta_title = (params = {}, options = {}) => {
	return {
		en: en.forgottenPassword_meta_title,
		fr: fr.forgottenPassword_meta_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenPassword_meta_description = (params = {}, options = {}) => {
	return {
		en: en.forgottenPassword_meta_description,
		fr: fr.forgottenPassword_meta_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenpassword_notSamePasswords = (params = {}, options = {}) => {
	return {
		en: en.forgottenpassword_notSamePasswords,
		fr: fr.forgottenpassword_notSamePasswords
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenPassword_passwordChanged = (params = {}, options = {}) => {
	return {
		en: en.forgottenPassword_passwordChanged,
		fr: fr.forgottenPassword_passwordChanged
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenPassword_passwordChangedDescription = (params = {}, options = {}) => {
	return {
		en: en.forgottenPassword_passwordChangedDescription,
		fr: fr.forgottenPassword_passwordChangedDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenPassword_login = (params = {}, options = {}) => {
	return {
		en: en.forgottenPassword_login,
		fr: fr.forgottenPassword_login
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenPassword_title = (params = {}, options = {}) => {
	return {
		en: en.forgottenPassword_title,
		fr: fr.forgottenPassword_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenPassword_description = (params = {}, options = {}) => {
	return {
		en: en.forgottenPassword_description,
		fr: fr.forgottenPassword_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenPassword_newPassword = (params = {}, options = {}) => {
	return {
		en: en.forgottenPassword_newPassword,
		fr: fr.forgottenPassword_newPassword
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenPassword_required = (params = {}, options = {}) => {
	return {
		en: en.forgottenPassword_required,
		fr: fr.forgottenPassword_required
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenPassword_confirmPassword = (params = {}, options = {}) => {
	return {
		en: en.forgottenPassword_confirmPassword,
		fr: fr.forgottenPassword_confirmPassword
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenPassword_reset = (params = {}, options = {}) => {
	return {
		en: en.forgottenPassword_reset,
		fr: fr.forgottenPassword_reset
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenPassword_new = (params = {}, options = {}) => {
	return {
		en: en.forgottenPassword_new,
		fr: fr.forgottenPassword_new
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenPassword_newDescription = (params = {}, options = {}) => {
	return {
		en: en.forgottenPassword_newDescription,
		fr: fr.forgottenPassword_newDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenPassword_email = (params = {}, options = {}) => {
	return {
		en: en.forgottenPassword_email,
		fr: fr.forgottenPassword_email
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenPassword_emailRequired = (params = {}, options = {}) => {
	return {
		en: en.forgottenPassword_emailRequired,
		fr: fr.forgottenPassword_emailRequired
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenPassword_emailInvalid = (params = {}, options = {}) => {
	return {
		en: en.forgottenPassword_emailInvalid,
		fr: fr.forgottenPassword_emailInvalid
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenPassword_submit = (params = {}, options = {}) => {
	return {
		en: en.forgottenPassword_submit,
		fr: fr.forgottenPassword_submit
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenPassword_redirectForLogin = (params = {}, options = {}) => {
	return {
		en: en.forgottenPassword_redirectForLogin,
		fr: fr.forgottenPassword_redirectForLogin
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenPassword_emailSent = (params = {}, options = {}) => {
	return {
		en: en.forgottenPassword_emailSent,
		fr: fr.forgottenPassword_emailSent
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenPassword_emailSentDescription = (params = {}, options = {}) => {
	return {
		en: en.forgottenPassword_emailSentDescription,
		fr: fr.forgottenPassword_emailSentDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_meta_title = (params = {}, options = {}) => {
	return {
		en: en.pricing_meta_title,
		fr: fr.pricing_meta_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_meta_description = (params = {}, options = {}) => {
	return {
		en: en.pricing_meta_description,
		fr: fr.pricing_meta_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_error_name = (params = {}, options = {}) => {
	return {
		en: en.pricing_error_name,
		fr: fr.pricing_error_name
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_description = (params = {}, options = {}) => {
	return {
		en: en.pricing_description,
		fr: fr.pricing_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const enterprises = (params = {}, options = {}) => {
	return {
		en: en.enterprises,
		fr: fr.enterprises
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_dontLetYourSkills = (params = {}, options = {}) => {
	return {
		en: en.pricing_dontLetYourSkills,
		fr: fr.pricing_dontLetYourSkills
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_dontLetYourSkills2 = (params = {}, options = {}) => {
	return {
		en: en.pricing_dontLetYourSkills2,
		fr: fr.pricing_dontLetYourSkills2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_dontLetYourSkillsHeadline1 = (params = {}, options = {}) => {
	return {
		en: en.pricing_dontLetYourSkillsHeadline1,
		fr: fr.pricing_dontLetYourSkillsHeadline1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_dontLetYourSkillsHeadline2 = (params = {}, options = {}) => {
	return {
		en: en.pricing_dontLetYourSkillsHeadline2,
		fr: fr.pricing_dontLetYourSkillsHeadline2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_withoutBelievemy = (params = {}, options = {}) => {
	return {
		en: en.pricing_withoutBelievemy,
		fr: fr.pricing_withoutBelievemy
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_withoutBelievemyDescription = (params = {}, options = {}) => {
	return {
		en: en.pricing_withoutBelievemyDescription,
		fr: fr.pricing_withoutBelievemyDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_withBelievemy = (params = {}, options = {}) => {
	return {
		en: en.pricing_withBelievemy,
		fr: fr.pricing_withBelievemy
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_withBelievemyDescription = (params = {}, options = {}) => {
	return {
		en: en.pricing_withBelievemyDescription,
		fr: fr.pricing_withBelievemyDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_thisWillHappen1 = (params = {}, options = {}) => {
	return {
		en: en.pricing_thisWillHappen1,
		fr: fr.pricing_thisWillHappen1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_thisWillHappen2 = (params = {}, options = {}) => {
	return {
		en: en.pricing_thisWillHappen2,
		fr: fr.pricing_thisWillHappen2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_youWillBeSolo = (params = {}, options = {}) => {
	return {
		en: en.pricing_youWillBeSolo,
		fr: fr.pricing_youWillBeSolo
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_youWillBeSoloDescription = (params = {}, options = {}) => {
	return {
		en: en.pricing_youWillBeSoloDescription,
		fr: fr.pricing_youWillBeSoloDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_youWillBeOutOfDate = (params = {}, options = {}) => {
	return {
		en: en.pricing_youWillBeOutOfDate,
		fr: fr.pricing_youWillBeOutOfDate
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_youWillBeOutOfDateDescription = (params = {}, options = {}) => {
	return {
		en: en.pricing_youWillBeOutOfDateDescription,
		fr: fr.pricing_youWillBeOutOfDateDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_youWillLooseTime = (params = {}, options = {}) => {
	return {
		en: en.pricing_youWillLooseTime,
		fr: fr.pricing_youWillLooseTime
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_youWillLooseTimeDescription = (params = {}, options = {}) => {
	return {
		en: en.pricing_youWillLooseTimeDescription,
		fr: fr.pricing_youWillLooseTimeDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_thisMakesDifference = (params = {}, options = {}) => {
	return {
		en: en.pricing_thisMakesDifference,
		fr: fr.pricing_thisMakesDifference
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_thisMakesDifference_for_freelance = (params = {}, options = {}) => {
	return {
		en: en.pricing_thisMakesDifference_for_freelance,
		fr: fr.pricing_thisMakesDifference_for_freelance
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_thisMakesDifference_for_employee = (params = {}, options = {}) => {
	return {
		en: en.pricing_thisMakesDifference_for_employee,
		fr: fr.pricing_thisMakesDifference_for_employee
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_sources = (params = {}, options = {}) => {
	return {
		en: en.pricing_sources,
		fr: fr.pricing_sources
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_see = (params = {}, options = {}) => {
	return {
		en: en.pricing_see,
		fr: fr.pricing_see
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_employeeSources = (params = {}, options = {}) => {
	return {
		en: en.pricing_employeeSources,
		fr: fr.pricing_employeeSources
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_ourPricing = (params = {}, options = {}) => {
	return {
		en: en.pricing_ourPricing,
		fr: fr.pricing_ourPricing
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_ourPricingDescription = (params = {}, options = {}) => {
	return {
		en: en.pricing_ourPricingDescription,
		fr: fr.pricing_ourPricingDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_monthlySubscription = (params = {}, options = {}) => {
	return {
		en: en.pricing_monthlySubscription,
		fr: fr.pricing_monthlySubscription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_monthlySubscriptionDescription = (params = {}, options = {}) => {
	return {
		en: en.pricing_monthlySubscriptionDescription,
		fr: fr.pricing_monthlySubscriptionDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_monthlySubscriptionPriceWithoutAnyEngagement = (params = {}, options = {}) => {
	return {
		en: en.pricing_monthlySubscriptionPriceWithoutAnyEngagement,
		fr: fr.pricing_monthlySubscriptionPriceWithoutAnyEngagement
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_subscriptionPriceWithEngagement = (params = {}, options = {}) => {
	return {
		en: en.pricing_subscriptionPriceWithEngagement,
		fr: fr.pricing_subscriptionPriceWithEngagement
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_startThisSubscription = (params = {}, options = {}) => {
	return {
		en: en.pricing_startThisSubscription,
		fr: fr.pricing_startThisSubscription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_startForFree = (params = {}, options = {}) => {
	return {
		en: en.pricing_startForFree,
		fr: fr.pricing_startForFree
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_advantage1 = (params = {}, options = {}) => {
	return {
		en: en.pricing_advantage1,
		fr: fr.pricing_advantage1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_advantage2 = (params = {}, options = {}) => {
	return {
		en: en.pricing_advantage2,
		fr: fr.pricing_advantage2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_advantage3 = (params = {}, options = {}) => {
	return {
		en: en.pricing_advantage3,
		fr: fr.pricing_advantage3
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_advantage4 = (params = {}, options = {}) => {
	return {
		en: en.pricing_advantage4,
		fr: fr.pricing_advantage4
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_advantage5 = (params = {}, options = {}) => {
	return {
		en: en.pricing_advantage5,
		fr: fr.pricing_advantage5
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_advantage6 = (params = {}, options = {}) => {
	return {
		en: en.pricing_advantage6,
		fr: fr.pricing_advantage6
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_6MonthsSubscription = (params = {}, options = {}) => {
	return {
		en: en.pricing_6MonthsSubscription,
		fr: fr.pricing_6MonthsSubscription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_6MonthsSubscriptionDescription = (params = {}, options = {}) => {
	return {
		en: en.pricing_6MonthsSubscriptionDescription,
		fr: fr.pricing_6MonthsSubscriptionDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_allTheAdvantages = (params = {}, options = {}) => {
	return {
		en: en.pricing_allTheAdvantages,
		fr: fr.pricing_allTheAdvantages
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_advantageFor6Months = (params = {}, options = {}) => {
	return {
		en: en.pricing_advantageFor6Months,
		fr: fr.pricing_advantageFor6Months
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_annuallySubscription = (params = {}, options = {}) => {
	return {
		en: en.pricing_annuallySubscription,
		fr: fr.pricing_annuallySubscription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_annuallySubscriptionDescription = (params = {}, options = {}) => {
	return {
		en: en.pricing_annuallySubscriptionDescription,
		fr: fr.pricing_annuallySubscriptionDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_advantageForAnnually = (params = {}, options = {}) => {
	return {
		en: en.pricing_advantageForAnnually,
		fr: fr.pricing_advantageForAnnually
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_theMostPopular = (params = {}, options = {}) => {
	return {
		en: en.pricing_theMostPopular,
		fr: fr.pricing_theMostPopular
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_whySellSubscriptions = (params = {}, options = {}) => {
	return {
		en: en.pricing_whySellSubscriptions,
		fr: fr.pricing_whySellSubscriptions
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_questions = (params = {}, options = {}) => {
	return {
		en: en.pricing_questions,
		fr: fr.pricing_questions
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_faq_start = (params = {}, options = {}) => {
	return {
		en: en.pricing_faq_start,
		fr: fr.pricing_faq_start
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_faq_start_description = (params = {}, options = {}) => {
	return {
		en: en.pricing_faq_start_description,
		fr: fr.pricing_faq_start_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_faq_debit = (params = {}, options = {}) => {
	return {
		en: en.pricing_faq_debit,
		fr: fr.pricing_faq_debit
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_faq_debit_description = (params = {}, options = {}) => {
	return {
		en: en.pricing_faq_debit_description,
		fr: fr.pricing_faq_debit_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_faq_help = (params = {}, options = {}) => {
	return {
		en: en.pricing_faq_help,
		fr: fr.pricing_faq_help
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_faq_help_description = (params = {}, options = {}) => {
	return {
		en: en.pricing_faq_help_description,
		fr: fr.pricing_faq_help_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_faq_cancel = (params = {}, options = {}) => {
	return {
		en: en.pricing_faq_cancel,
		fr: fr.pricing_faq_cancel
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_faq_cancel_description = (params = {}, options = {}) => {
	return {
		en: en.pricing_faq_cancel_description,
		fr: fr.pricing_faq_cancel_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_faq_paid = (params = {}, options = {}) => {
	return {
		en: en.pricing_faq_paid,
		fr: fr.pricing_faq_paid
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_faq_paid_description = (params = {}, options = {}) => {
	return {
		en: en.pricing_faq_paid_description,
		fr: fr.pricing_faq_paid_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_faq_diff = (params = {}, options = {}) => {
	return {
		en: en.pricing_faq_diff,
		fr: fr.pricing_faq_diff
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_faq_diff_description = (params = {}, options = {}) => {
	return {
		en: en.pricing_faq_diff_description,
		fr: fr.pricing_faq_diff_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_haveAnotherQuestion = (params = {}, options = {}) => {
	return {
		en: en.pricing_haveAnotherQuestion,
		fr: fr.pricing_haveAnotherQuestion
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_oneMoreThing = (params = {}, options = {}) => {
	return {
		en: en.pricing_oneMoreThing,
		fr: fr.pricing_oneMoreThing
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_oneMoreThingDescription = (params = {}, options = {}) => {
	return {
		en: en.pricing_oneMoreThingDescription,
		fr: fr.pricing_oneMoreThingDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_oneMoreThingReason1 = (params = {}, options = {}) => {
	return {
		en: en.pricing_oneMoreThingReason1,
		fr: fr.pricing_oneMoreThingReason1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_oneMoreThingReason1Description = (params = {}, options = {}) => {
	return {
		en: en.pricing_oneMoreThingReason1Description,
		fr: fr.pricing_oneMoreThingReason1Description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_oneMoreThingReason2 = (params = {}, options = {}) => {
	return {
		en: en.pricing_oneMoreThingReason2,
		fr: fr.pricing_oneMoreThingReason2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_oneMoreThingReason2Description = (params = {}, options = {}) => {
	return {
		en: en.pricing_oneMoreThingReason2Description,
		fr: fr.pricing_oneMoreThingReason2Description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_oneMoreThingReason2DescriptionBis = (params = {}, options = {}) => {
	return {
		en: en.pricing_oneMoreThingReason2DescriptionBis,
		fr: fr.pricing_oneMoreThingReason2DescriptionBis
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const testimonials_title = (params = {}, options = {}) => {
	return {
		en: en.testimonials_title,
		fr: fr.testimonials_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const testimonial_1 = (params = {}, options = {}) => {
	return {
		en: en.testimonial_1,
		fr: fr.testimonial_1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const testimonial_2 = (params = {}, options = {}) => {
	return {
		en: en.testimonial_2,
		fr: fr.testimonial_2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const testimonial_3 = (params = {}, options = {}) => {
	return {
		en: en.testimonial_3,
		fr: fr.testimonial_3
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_yourOrder = (params = {}, options = {}) => {
	return {
		en: en.pricing_yourOrder,
		fr: fr.pricing_yourOrder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_believemyPremium = (params = {}, options = {}) => {
	return {
		en: en.pricing_believemyPremium,
		fr: fr.pricing_believemyPremium
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_subtotal = (params = {}, options = {}) => {
	return {
		en: en.pricing_subtotal,
		fr: fr.pricing_subtotal
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_vat = (params = {}, options = {}) => {
	return {
		en: en.pricing_vat,
		fr: fr.pricing_vat
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_total = (params = {}, options = {}) => {
	return {
		en: en.pricing_total,
		fr: fr.pricing_total
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_week = (params = {}, options = {}) => {
	return {
		en: en.pricing_week,
		fr: fr.pricing_week
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_totalForToday = (params = {}, options = {}) => {
	return {
		en: en.pricing_totalForToday,
		fr: fr.pricing_totalForToday
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_noPaymentToday = (params = {}, options = {}) => {
	return {
		en: en.pricing_noPaymentToday,
		fr: fr.pricing_noPaymentToday
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_noPaymentTodayDescription = (params = {}, options = {}) => {
	return {
		en: en.pricing_noPaymentTodayDescription,
		fr: fr.pricing_noPaymentTodayDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_bankingInfos = (params = {}, options = {}) => {
	return {
		en: en.pricing_bankingInfos,
		fr: fr.pricing_bankingInfos
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_bankingInfosDescription = (params = {}, options = {}) => {
	return {
		en: en.pricing_bankingInfosDescription,
		fr: fr.pricing_bankingInfosDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_youCanStopAnytime = (params = {}, options = {}) => {
	return {
		en: en.pricing_youCanStopAnytime,
		fr: fr.pricing_youCanStopAnytime
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_auth = (params = {}, options = {}) => {
	return {
		en: en.pricing_auth,
		fr: fr.pricing_auth
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_authDescription = (params = {}, options = {}) => {
	return {
		en: en.pricing_authDescription,
		fr: fr.pricing_authDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_email = (params = {}, options = {}) => {
	return {
		en: en.pricing_email,
		fr: fr.pricing_email
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_password = (params = {}, options = {}) => {
	return {
		en: en.pricing_password,
		fr: fr.pricing_password
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_continue = (params = {}, options = {}) => {
	return {
		en: en.pricing_continue,
		fr: fr.pricing_continue
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_billingInfos = (params = {}, options = {}) => {
	return {
		en: en.pricing_billingInfos,
		fr: fr.pricing_billingInfos
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_billingInfosDescription = (params = {}, options = {}) => {
	return {
		en: en.pricing_billingInfosDescription,
		fr: fr.pricing_billingInfosDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_firstName = (params = {}, options = {}) => {
	return {
		en: en.pricing_firstName,
		fr: fr.pricing_firstName
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_lastName = (params = {}, options = {}) => {
	return {
		en: en.pricing_lastName,
		fr: fr.pricing_lastName
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_street = (params = {}, options = {}) => {
	return {
		en: en.pricing_street,
		fr: fr.pricing_street
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_addressOption = (params = {}, options = {}) => {
	return {
		en: en.pricing_addressOption,
		fr: fr.pricing_addressOption
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_postalCode = (params = {}, options = {}) => {
	return {
		en: en.pricing_postalCode,
		fr: fr.pricing_postalCode
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_city = (params = {}, options = {}) => {
	return {
		en: en.pricing_city,
		fr: fr.pricing_city
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_country = (params = {}, options = {}) => {
	return {
		en: en.pricing_country,
		fr: fr.pricing_country
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_payment = (params = {}, options = {}) => {
	return {
		en: en.pricing_payment,
		fr: fr.pricing_payment
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_paymentDescription = (params = {}, options = {}) => {
	return {
		en: en.pricing_paymentDescription,
		fr: fr.pricing_paymentDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_meta_title = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_meta_title,
		fr: fr.joinInstructor_meta_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_meta_description = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_meta_description,
		fr: fr.joinInstructor_meta_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_subtitle = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_subtitle,
		fr: fr.joinInstructor_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_title = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_title,
		fr: fr.joinInstructor_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_headline = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_headline,
		fr: fr.joinInstructor_headline
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_remuneration = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_remuneration,
		fr: fr.joinInstructor_remuneration
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_remuneration_argument1_title = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_remuneration_argument1_title,
		fr: fr.joinInstructor_remuneration_argument1_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_remuneration_argument1_description = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_remuneration_argument1_description,
		fr: fr.joinInstructor_remuneration_argument1_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_remuneration_argument2_title = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_remuneration_argument2_title,
		fr: fr.joinInstructor_remuneration_argument2_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_remuneration_argument2_description = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_remuneration_argument2_description,
		fr: fr.joinInstructor_remuneration_argument2_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_remuneration_argument2_description2 = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_remuneration_argument2_description2,
		fr: fr.joinInstructor_remuneration_argument2_description2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_remuneration_argument3_title = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_remuneration_argument3_title,
		fr: fr.joinInstructor_remuneration_argument3_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_remuneration_argument3_description = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_remuneration_argument3_description,
		fr: fr.joinInstructor_remuneration_argument3_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_remuneration_argument3_description2 = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_remuneration_argument3_description2,
		fr: fr.joinInstructor_remuneration_argument3_description2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_remuneration_argument3_description3 = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_remuneration_argument3_description3,
		fr: fr.joinInstructor_remuneration_argument3_description3
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_example,
		fr: fr.joinInstructor_example
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_description = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_example_description,
		fr: fr.joinInstructor_example_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_first = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_example_first,
		fr: fr.joinInstructor_example_first
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_first_description = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_example_first_description,
		fr: fr.joinInstructor_example_first_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_two = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_example_two,
		fr: fr.joinInstructor_example_two
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_two_description = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_example_two_description,
		fr: fr.joinInstructor_example_two_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_three = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_example_three,
		fr: fr.joinInstructor_example_three
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_three_description = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_example_three_description,
		fr: fr.joinInstructor_example_three_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_first_argument1 = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_example_first_argument1,
		fr: fr.joinInstructor_example_first_argument1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_first_argument2 = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_example_first_argument2,
		fr: fr.joinInstructor_example_first_argument2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_first_argument3 = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_example_first_argument3,
		fr: fr.joinInstructor_example_first_argument3
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_first_remuneration = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_example_first_remuneration,
		fr: fr.joinInstructor_example_first_remuneration
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_first_remuneration_argument1 = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_example_first_remuneration_argument1,
		fr: fr.joinInstructor_example_first_remuneration_argument1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_first_remuneration_argument2 = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_example_first_remuneration_argument2,
		fr: fr.joinInstructor_example_first_remuneration_argument2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_first_remuneration_month = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_example_first_remuneration_month,
		fr: fr.joinInstructor_example_first_remuneration_month
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_after = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_after,
		fr: fr.joinInstructor_after
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_after_argument1 = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_after_argument1,
		fr: fr.joinInstructor_after_argument1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_after_argument2 = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_after_argument2,
		fr: fr.joinInstructor_after_argument2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_after_argument3 = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_after_argument3,
		fr: fr.joinInstructor_after_argument3
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_after_argument4 = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_after_argument4,
		fr: fr.joinInstructor_after_argument4
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_after_month = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_after_month,
		fr: fr.joinInstructor_after_month
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_two_argument1 = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_example_two_argument1,
		fr: fr.joinInstructor_example_two_argument1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_two_argument2 = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_example_two_argument2,
		fr: fr.joinInstructor_example_two_argument2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_two_argument3 = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_example_two_argument3,
		fr: fr.joinInstructor_example_two_argument3
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_two_remuneration = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_example_two_remuneration,
		fr: fr.joinInstructor_example_two_remuneration
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_two_remuneration_argument1 = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_example_two_remuneration_argument1,
		fr: fr.joinInstructor_example_two_remuneration_argument1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_two_remuneration_argument2 = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_example_two_remuneration_argument2,
		fr: fr.joinInstructor_example_two_remuneration_argument2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_after_two_argument1 = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_after_two_argument1,
		fr: fr.joinInstructor_after_two_argument1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_after_two_argument2 = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_after_two_argument2,
		fr: fr.joinInstructor_after_two_argument2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_after_two_argument3 = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_after_two_argument3,
		fr: fr.joinInstructor_after_two_argument3
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_after_two_argument4 = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_after_two_argument4,
		fr: fr.joinInstructor_after_two_argument4
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_three_argument1 = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_example_three_argument1,
		fr: fr.joinInstructor_example_three_argument1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_three_argument2 = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_example_three_argument2,
		fr: fr.joinInstructor_example_three_argument2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_three_argument3 = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_example_three_argument3,
		fr: fr.joinInstructor_example_three_argument3
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_three_remuneration_argument1 = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_example_three_remuneration_argument1,
		fr: fr.joinInstructor_example_three_remuneration_argument1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_three_remuneration_argument2 = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_example_three_remuneration_argument2,
		fr: fr.joinInstructor_example_three_remuneration_argument2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_oneTimeOnly = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_oneTimeOnly,
		fr: fr.joinInstructor_oneTimeOnly
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_after_three_argument1 = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_after_three_argument1,
		fr: fr.joinInstructor_after_three_argument1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_after_three_argument2 = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_after_three_argument2,
		fr: fr.joinInstructor_after_three_argument2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_after_three_argument3 = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_after_three_argument3,
		fr: fr.joinInstructor_after_three_argument3
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_after_three_argument4 = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_after_three_argument4,
		fr: fr.joinInstructor_after_three_argument4
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_faq = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_faq,
		fr: fr.joinInstructor_faq
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_faq_question1 = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_faq_question1,
		fr: fr.joinInstructor_faq_question1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_faq_answer1 = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_faq_answer1,
		fr: fr.joinInstructor_faq_answer1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_faq_question2 = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_faq_question2,
		fr: fr.joinInstructor_faq_question2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_faq_answer2 = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_faq_answer2,
		fr: fr.joinInstructor_faq_answer2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_faq_question3 = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_faq_question3,
		fr: fr.joinInstructor_faq_question3
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_faq_answer3 = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_faq_answer3,
		fr: fr.joinInstructor_faq_answer3
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_haveAnotherQuestion = (params = {}, options = {}) => {
	return {
		en: en.joinInstructor_haveAnotherQuestion,
		fr: fr.joinInstructor_haveAnotherQuestion
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_meta_title = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_meta_title,
		fr: fr.joinMentor_meta_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_meta_description = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_meta_description,
		fr: fr.joinMentor_meta_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_subtitle = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_subtitle,
		fr: fr.joinMentor_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_title = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_title,
		fr: fr.joinMentor_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_headline = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_headline,
		fr: fr.joinMentor_headline
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_doWhatYouLove = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_doWhatYouLove,
		fr: fr.joinMentor_doWhatYouLove
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_doWhatYouLoveSubtitle = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_doWhatYouLoveSubtitle,
		fr: fr.joinMentor_doWhatYouLoveSubtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourTime = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_manageYourTime,
		fr: fr.joinMentor_manageYourTime
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourTimeDescription1 = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_manageYourTimeDescription1,
		fr: fr.joinMentor_manageYourTimeDescription1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourTimeDescription2 = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_manageYourTimeDescription2,
		fr: fr.joinMentor_manageYourTimeDescription2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourMoney = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_manageYourMoney,
		fr: fr.joinMentor_manageYourMoney
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourMoneyDescription1 = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_manageYourMoneyDescription1,
		fr: fr.joinMentor_manageYourMoneyDescription1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourMoneyDescription2 = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_manageYourMoneyDescription2,
		fr: fr.joinMentor_manageYourMoneyDescription2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourWarranties = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_manageYourWarranties,
		fr: fr.joinMentor_manageYourWarranties
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourWarrantiesDescription1 = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_manageYourWarrantiesDescription1,
		fr: fr.joinMentor_manageYourWarrantiesDescription1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourWarrantiesDescription2 = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_manageYourWarrantiesDescription2,
		fr: fr.joinMentor_manageYourWarrantiesDescription2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_call = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_call,
		fr: fr.joinMentor_call
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_callImage = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_callImage,
		fr: fr.joinMentor_callImage
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_all = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_all,
		fr: fr.joinMentor_all
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_neverMiss = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_neverMiss,
		fr: fr.joinMentor_neverMiss
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_neverMissDescription1 = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_neverMissDescription1,
		fr: fr.joinMentor_neverMissDescription1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_neverMissDescription2 = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_neverMissDescription2,
		fr: fr.joinMentor_neverMissDescription2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_inviteForWorldwide = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_inviteForWorldwide,
		fr: fr.joinMentor_inviteForWorldwide
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_inviteForWorldwideDescription1 = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_inviteForWorldwideDescription1,
		fr: fr.joinMentor_inviteForWorldwideDescription1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_inviteForWorldwideDescription2 = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_inviteForWorldwideDescription2,
		fr: fr.joinMentor_inviteForWorldwideDescription2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourInvitations = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_manageYourInvitations,
		fr: fr.joinMentor_manageYourInvitations
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourInvitationsDescription1 = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_manageYourInvitationsDescription1,
		fr: fr.joinMentor_manageYourInvitationsDescription1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourInvitationsDescription2 = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_manageYourInvitationsDescription2,
		fr: fr.joinMentor_manageYourInvitationsDescription2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourCalls = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_manageYourCalls,
		fr: fr.joinMentor_manageYourCalls
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourCallsDescription1 = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_manageYourCallsDescription1,
		fr: fr.joinMentor_manageYourCallsDescription1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourGuarantees = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_manageYourGuarantees,
		fr: fr.joinMentor_manageYourGuarantees
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourGuaranteesDescription1 = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_manageYourGuaranteesDescription1,
		fr: fr.joinMentor_manageYourGuaranteesDescription1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourGuaranteesDescription2 = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_manageYourGuaranteesDescription2,
		fr: fr.joinMentor_manageYourGuaranteesDescription2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourVisio = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_manageYourVisio,
		fr: fr.joinMentor_manageYourVisio
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourVisioDescription1 = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_manageYourVisioDescription1,
		fr: fr.joinMentor_manageYourVisioDescription1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourVisioDescription2 = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_manageYourVisioDescription2,
		fr: fr.joinMentor_manageYourVisioDescription2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourPayments = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_manageYourPayments,
		fr: fr.joinMentor_manageYourPayments
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourPaymentsDescription1 = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_manageYourPaymentsDescription1,
		fr: fr.joinMentor_manageYourPaymentsDescription1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourPaymentsDescription2 = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_manageYourPaymentsDescription2,
		fr: fr.joinMentor_manageYourPaymentsDescription2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_faq = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_faq,
		fr: fr.joinMentor_faq
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_faq_one = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_faq_one,
		fr: fr.joinMentor_faq_one
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_faq_answer_one = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_faq_answer_one,
		fr: fr.joinMentor_faq_answer_one
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_faq_two = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_faq_two,
		fr: fr.joinMentor_faq_two
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_faq_answer_two1 = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_faq_answer_two1,
		fr: fr.joinMentor_faq_answer_two1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_faq_answer_two2 = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_faq_answer_two2,
		fr: fr.joinMentor_faq_answer_two2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_faq_answer_two3 = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_faq_answer_two3,
		fr: fr.joinMentor_faq_answer_two3
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_faq_three = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_faq_three,
		fr: fr.joinMentor_faq_three
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_faq_answer_three1 = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_faq_answer_three1,
		fr: fr.joinMentor_faq_answer_three1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_faq_answer_three2 = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_faq_answer_three2,
		fr: fr.joinMentor_faq_answer_three2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_faq_answer_three3 = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_faq_answer_three3,
		fr: fr.joinMentor_faq_answer_three3
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_faq_another = (params = {}, options = {}) => {
	return {
		en: en.joinMentor_faq_another,
		fr: fr.joinMentor_faq_another
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ firstName: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const UserLayout_bye = (params , options = {}) => {
	return {
		en: en.UserLayout_bye,
		fr: fr.UserLayout_bye
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legalMentions_meta_title = (params = {}, options = {}) => {
	return {
		en: en.legalMentions_meta_title,
		fr: fr.legalMentions_meta_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legalMentions_meta_description = (params = {}, options = {}) => {
	return {
		en: en.legalMentions_meta_description,
		fr: fr.legalMentions_meta_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legalMentions_title = (params = {}, options = {}) => {
	return {
		en: en.legalMentions_title,
		fr: fr.legalMentions_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legalMentions_director = (params = {}, options = {}) => {
	return {
		en: en.legalMentions_director,
		fr: fr.legalMentions_director
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legalMentions_fullname = (params = {}, options = {}) => {
	return {
		en: en.legalMentions_fullname,
		fr: fr.legalMentions_fullname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legalMentions_email = (params = {}, options = {}) => {
	return {
		en: en.legalMentions_email,
		fr: fr.legalMentions_email
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legalMentions_SIREN = (params = {}, options = {}) => {
	return {
		en: en.legalMentions_SIREN,
		fr: fr.legalMentions_SIREN
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legalMentions_address = (params = {}, options = {}) => {
	return {
		en: en.legalMentions_address,
		fr: fr.legalMentions_address
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legalMentions_NDA = (params = {}, options = {}) => {
	return {
		en: en.legalMentions_NDA,
		fr: fr.legalMentions_NDA
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legalMentions_hosting = (params = {}, options = {}) => {
	return {
		en: en.legalMentions_hosting,
		fr: fr.legalMentions_hosting
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legalMentions_hostingCompany = (params = {}, options = {}) => {
	return {
		en: en.legalMentions_hostingCompany,
		fr: fr.legalMentions_hostingCompany
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legalMentions_hostingAddress = (params = {}, options = {}) => {
	return {
		en: en.legalMentions_hostingAddress,
		fr: fr.legalMentions_hostingAddress
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legalMentions_hostingPhone = (params = {}, options = {}) => {
	return {
		en: en.legalMentions_hostingPhone,
		fr: fr.legalMentions_hostingPhone
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legalMentions_hostingEmail = (params = {}, options = {}) => {
	return {
		en: en.legalMentions_hostingEmail,
		fr: fr.legalMentions_hostingEmail
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legalMentions_hostingBisCompany = (params = {}, options = {}) => {
	return {
		en: en.legalMentions_hostingBisCompany,
		fr: fr.legalMentions_hostingBisCompany
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legalMentions_hostingBisAddress = (params = {}, options = {}) => {
	return {
		en: en.legalMentions_hostingBisAddress,
		fr: fr.legalMentions_hostingBisAddress
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legalMentions_hostingBisEmail = (params = {}, options = {}) => {
	return {
		en: en.legalMentions_hostingBisEmail,
		fr: fr.legalMentions_hostingBisEmail
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legalMentions_others = (params = {}, options = {}) => {
	return {
		en: en.legalMentions_others,
		fr: fr.legalMentions_others
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legalMentions_others1 = (params = {}, options = {}) => {
	return {
		en: en.legalMentions_others1,
		fr: fr.legalMentions_others1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legalMentions_others2 = (params = {}, options = {}) => {
	return {
		en: en.legalMentions_others2,
		fr: fr.legalMentions_others2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const termsOfSale_meta_title = (params = {}, options = {}) => {
	return {
		en: en.termsOfSale_meta_title,
		fr: fr.termsOfSale_meta_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const termsOfSale_meta_description = (params = {}, options = {}) => {
	return {
		en: en.termsOfSale_meta_description,
		fr: fr.termsOfSale_meta_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const termsOfSale_title = (params = {}, options = {}) => {
	return {
		en: en.termsOfSale_title,
		fr: fr.termsOfSale_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const termsOfSale_alert = (params = {}, options = {}) => {
	return {
		en: en.termsOfSale_alert,
		fr: fr.termsOfSale_alert
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const privacy_meta_title = (params = {}, options = {}) => {
	return {
		en: en.privacy_meta_title,
		fr: fr.privacy_meta_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const privacy_meta_description = (params = {}, options = {}) => {
	return {
		en: en.privacy_meta_description,
		fr: fr.privacy_meta_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const privacy_title = (params = {}, options = {}) => {
	return {
		en: en.privacy_title,
		fr: fr.privacy_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_meta_title = (params = {}, options = {}) => {
	return {
		en: en.press_meta_title,
		fr: fr.press_meta_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_meta_description = (params = {}, options = {}) => {
	return {
		en: en.press_meta_description,
		fr: fr.press_meta_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_title = (params = {}, options = {}) => {
	return {
		en: en.press_title,
		fr: fr.press_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_headline = (params = {}, options = {}) => {
	return {
		en: en.press_headline,
		fr: fr.press_headline
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date1 = (params = {}, options = {}) => {
	return {
		en: en.press_date1,
		fr: fr.press_date1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date1_description = (params = {}, options = {}) => {
	return {
		en: en.press_date1_description,
		fr: fr.press_date1_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date2 = (params = {}, options = {}) => {
	return {
		en: en.press_date2,
		fr: fr.press_date2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date2_description = (params = {}, options = {}) => {
	return {
		en: en.press_date2_description,
		fr: fr.press_date2_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date3 = (params = {}, options = {}) => {
	return {
		en: en.press_date3,
		fr: fr.press_date3
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date3_description = (params = {}, options = {}) => {
	return {
		en: en.press_date3_description,
		fr: fr.press_date3_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date4 = (params = {}, options = {}) => {
	return {
		en: en.press_date4,
		fr: fr.press_date4
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date4_description = (params = {}, options = {}) => {
	return {
		en: en.press_date4_description,
		fr: fr.press_date4_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date5 = (params = {}, options = {}) => {
	return {
		en: en.press_date5,
		fr: fr.press_date5
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date5_description = (params = {}, options = {}) => {
	return {
		en: en.press_date5_description,
		fr: fr.press_date5_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date6 = (params = {}, options = {}) => {
	return {
		en: en.press_date6,
		fr: fr.press_date6
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date6_description = (params = {}, options = {}) => {
	return {
		en: en.press_date6_description,
		fr: fr.press_date6_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date7 = (params = {}, options = {}) => {
	return {
		en: en.press_date7,
		fr: fr.press_date7
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date7_description = (params = {}, options = {}) => {
	return {
		en: en.press_date7_description,
		fr: fr.press_date7_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date8 = (params = {}, options = {}) => {
	return {
		en: en.press_date8,
		fr: fr.press_date8
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date8_description = (params = {}, options = {}) => {
	return {
		en: en.press_date8_description,
		fr: fr.press_date8_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date9 = (params = {}, options = {}) => {
	return {
		en: en.press_date9,
		fr: fr.press_date9
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date9_description = (params = {}, options = {}) => {
	return {
		en: en.press_date9_description,
		fr: fr.press_date9_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date10 = (params = {}, options = {}) => {
	return {
		en: en.press_date10,
		fr: fr.press_date10
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date10_description = (params = {}, options = {}) => {
	return {
		en: en.press_date10_description,
		fr: fr.press_date10_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date11 = (params = {}, options = {}) => {
	return {
		en: en.press_date11,
		fr: fr.press_date11
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date11_description = (params = {}, options = {}) => {
	return {
		en: en.press_date11_description,
		fr: fr.press_date11_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date12 = (params = {}, options = {}) => {
	return {
		en: en.press_date12,
		fr: fr.press_date12
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date12_description = (params = {}, options = {}) => {
	return {
		en: en.press_date12_description,
		fr: fr.press_date12_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date13 = (params = {}, options = {}) => {
	return {
		en: en.press_date13,
		fr: fr.press_date13
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date13_description = (params = {}, options = {}) => {
	return {
		en: en.press_date13_description,
		fr: fr.press_date13_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_contact = (params = {}, options = {}) => {
	return {
		en: en.press_contact,
		fr: fr.press_contact
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_contact_description = (params = {}, options = {}) => {
	return {
		en: en.press_contact_description,
		fr: fr.press_contact_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_contact_address = (params = {}, options = {}) => {
	return {
		en: en.press_contact_address,
		fr: fr.press_contact_address
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_pressKit = (params = {}, options = {}) => {
	return {
		en: en.press_pressKit,
		fr: fr.press_pressKit
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_pressKitDescription = (params = {}, options = {}) => {
	return {
		en: en.press_pressKitDescription,
		fr: fr.press_pressKitDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_pressKitDownload = (params = {}, options = {}) => {
	return {
		en: en.press_pressKitDownload,
		fr: fr.press_pressKitDownload
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const community_meta_title = (params = {}, options = {}) => {
	return {
		en: en.community_meta_title,
		fr: fr.community_meta_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const community_meta_description = (params = {}, options = {}) => {
	return {
		en: en.community_meta_description,
		fr: fr.community_meta_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const community_title = (params = {}, options = {}) => {
	return {
		en: en.community_title,
		fr: fr.community_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const community_subtitle = (params = {}, options = {}) => {
	return {
		en: en.community_subtitle,
		fr: fr.community_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const DiscussionCard_replies = (params = {}, options = {}) => {
	return {
		en: en.DiscussionCard_replies,
		fr: fr.DiscussionCard_replies
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const DiscussionCard_reply = (params = {}, options = {}) => {
	return {
		en: en.DiscussionCard_reply,
		fr: fr.DiscussionCard_reply
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const DiscussionCard_participants = (params = {}, options = {}) => {
	return {
		en: en.DiscussionCard_participants,
		fr: fr.DiscussionCard_participants
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const DiscussionCard_participant = (params = {}, options = {}) => {
	return {
		en: en.DiscussionCard_participant,
		fr: fr.DiscussionCard_participant
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityComponent_noDiscussions = (params = {}, options = {}) => {
	return {
		en: en.CommunityComponent_noDiscussions,
		fr: fr.CommunityComponent_noDiscussions
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityComponent_filters_all = (params = {}, options = {}) => {
	return {
		en: en.CommunityComponent_filters_all,
		fr: fr.CommunityComponent_filters_all
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityComponent_filters_solved = (params = {}, options = {}) => {
	return {
		en: en.CommunityComponent_filters_solved,
		fr: fr.CommunityComponent_filters_solved
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityComponent_filters_unsolved = (params = {}, options = {}) => {
	return {
		en: en.CommunityComponent_filters_unsolved,
		fr: fr.CommunityComponent_filters_unsolved
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityDiscussion_error = (params = {}, options = {}) => {
	return {
		en: en.CommunityDiscussion_error,
		fr: fr.CommunityDiscussion_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityDiscussion_success = (params = {}, options = {}) => {
	return {
		en: en.CommunityDiscussion_success,
		fr: fr.CommunityDiscussion_success
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityDiscussion_confirmDeleteDiscussion = (params = {}, options = {}) => {
	return {
		en: en.CommunityDiscussion_confirmDeleteDiscussion,
		fr: fr.CommunityDiscussion_confirmDeleteDiscussion
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityDiscussion_discussionDeleted = (params = {}, options = {}) => {
	return {
		en: en.CommunityDiscussion_discussionDeleted,
		fr: fr.CommunityDiscussion_discussionDeleted
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityDiscussion_confirmDelete = (params = {}, options = {}) => {
	return {
		en: en.CommunityDiscussion_confirmDelete,
		fr: fr.CommunityDiscussion_confirmDelete
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityDiscussion_messageDeleted = (params = {}, options = {}) => {
	return {
		en: en.CommunityDiscussion_messageDeleted,
		fr: fr.CommunityDiscussion_messageDeleted
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityDiscussion_notMarkedForGuide = (params = {}, options = {}) => {
	return {
		en: en.CommunityDiscussion_notMarkedForGuide,
		fr: fr.CommunityDiscussion_notMarkedForGuide
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityDiscussion_markedForGuide = (params = {}, options = {}) => {
	return {
		en: en.CommunityDiscussion_markedForGuide,
		fr: fr.CommunityDiscussion_markedForGuide
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityDiscussion_bestMessage = (params = {}, options = {}) => {
	return {
		en: en.CommunityDiscussion_bestMessage,
		fr: fr.CommunityDiscussion_bestMessage
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ date: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityDiscussion_createdAt = (params , options = {}) => {
	return {
		en: en.CommunityDiscussion_createdAt,
		fr: fr.CommunityDiscussion_createdAt
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ date: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityDiscussion_lastActivity = (params , options = {}) => {
	return {
		en: en.CommunityDiscussion_lastActivity,
		fr: fr.CommunityDiscussion_lastActivity
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityDiscussion_guide = (params = {}, options = {}) => {
	return {
		en: en.CommunityDiscussion_guide,
		fr: fr.CommunityDiscussion_guide
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_bestAnswerAdded = (params = {}, options = {}) => {
	return {
		en: en.Message_bestAnswerAdded,
		fr: fr.Message_bestAnswerAdded
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_bestAnswerRemoved = (params = {}, options = {}) => {
	return {
		en: en.Message_bestAnswerRemoved,
		fr: fr.Message_bestAnswerRemoved
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_messageUpdatedSuccessfully = (params = {}, options = {}) => {
	return {
		en: en.Message_messageUpdatedSuccessfully,
		fr: fr.Message_messageUpdatedSuccessfully
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_discussionUpdatedSuccessfully = (params = {}, options = {}) => {
	return {
		en: en.Message_discussionUpdatedSuccessfully,
		fr: fr.Message_discussionUpdatedSuccessfully
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ date: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_createdAt = (params , options = {}) => {
	return {
		en: en.Message_createdAt,
		fr: fr.Message_createdAt
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ date: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_updatedAt = (params , options = {}) => {
	return {
		en: en.Message_updatedAt,
		fr: fr.Message_updatedAt
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_bestMessage = (params = {}, options = {}) => {
	return {
		en: en.Message_bestMessage,
		fr: fr.Message_bestMessage
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_removeBestMessage = (params = {}, options = {}) => {
	return {
		en: en.Message_removeBestMessage,
		fr: fr.Message_removeBestMessage
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_markAsGuideButton = (params = {}, options = {}) => {
	return {
		en: en.Message_markAsGuideButton,
		fr: fr.Message_markAsGuideButton
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_unmarkAsGuideButton = (params = {}, options = {}) => {
	return {
		en: en.Message_unmarkAsGuideButton,
		fr: fr.Message_unmarkAsGuideButton
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_writeYourMessage = (params = {}, options = {}) => {
	return {
		en: en.Message_writeYourMessage,
		fr: fr.Message_writeYourMessage
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_cancel = (params = {}, options = {}) => {
	return {
		en: en.Message_cancel,
		fr: fr.Message_cancel
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_save = (params = {}, options = {}) => {
	return {
		en: en.Message_save,
		fr: fr.Message_save
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityDiscussion_course = (params = {}, options = {}) => {
	return {
		en: en.CommunityDiscussion_course,
		fr: fr.CommunityDiscussion_course
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityDiscussion_seeCourse = (params = {}, options = {}) => {
	return {
		en: en.CommunityDiscussion_seeCourse,
		fr: fr.CommunityDiscussion_seeCourse
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityDiscussion_noCourse = (params = {}, options = {}) => {
	return {
		en: en.CommunityDiscussion_noCourse,
		fr: fr.CommunityDiscussion_noCourse
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityDiscussion_seeAccelerators = (params = {}, options = {}) => {
	return {
		en: en.CommunityDiscussion_seeAccelerators,
		fr: fr.CommunityDiscussion_seeAccelerators
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityDiscussion_send = (params = {}, options = {}) => {
	return {
		en: en.CommunityDiscussion_send,
		fr: fr.CommunityDiscussion_send
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityDiscussion_signup = (params = {}, options = {}) => {
	return {
		en: en.CommunityDiscussion_signup,
		fr: fr.CommunityDiscussion_signup
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityDiscussion_or = (params = {}, options = {}) => {
	return {
		en: en.CommunityDiscussion_or,
		fr: fr.CommunityDiscussion_or
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityDiscussion_login = (params = {}, options = {}) => {
	return {
		en: en.CommunityDiscussion_login,
		fr: fr.CommunityDiscussion_login
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityDiscussion_toReply = (params = {}, options = {}) => {
	return {
		en: en.CommunityDiscussion_toReply,
		fr: fr.CommunityDiscussion_toReply
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const events_meta_title = (params = {}, options = {}) => {
	return {
		en: en.events_meta_title,
		fr: fr.events_meta_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const events_meta_description = (params = {}, options = {}) => {
	return {
		en: en.events_meta_description,
		fr: fr.events_meta_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const events_text = (params = {}, options = {}) => {
	return {
		en: en.events_text,
		fr: fr.events_text
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const events_title = (params = {}, options = {}) => {
	return {
		en: en.events_title,
		fr: fr.events_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const events_subtitle = (params = {}, options = {}) => {
	return {
		en: en.events_subtitle,
		fr: fr.events_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const events_soon = (params = {}, options = {}) => {
	return {
		en: en.events_soon,
		fr: fr.events_soon
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const events_noEvents = (params = {}, options = {}) => {
	return {
		en: en.events_noEvents,
		fr: fr.events_noEvents
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const events_future = (params = {}, options = {}) => {
	return {
		en: en.events_future,
		fr: fr.events_future
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const events_date = (params = {}, options = {}) => {
	return {
		en: en.events_date,
		fr: fr.events_date
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const events_parisTimezoneDate = (params = {}, options = {}) => {
	return {
		en: en.events_parisTimezoneDate,
		fr: fr.events_parisTimezoneDate
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_title = (params = {}, options = {}) => {
	return {
		en: en.signup_title,
		fr: fr.signup_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_description = (params = {}, options = {}) => {
	return {
		en: en.signup_description,
		fr: fr.signup_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_description_playground = (params = {}, options = {}) => {
	return {
		en: en.signup_description_playground,
		fr: fr.signup_description_playground
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_google = (params = {}, options = {}) => {
	return {
		en: en.signup_google,
		fr: fr.signup_google
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_else = (params = {}, options = {}) => {
	return {
		en: en.signup_else,
		fr: fr.signup_else
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_firstname = (params = {}, options = {}) => {
	return {
		en: en.signup_firstname,
		fr: fr.signup_firstname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_firstname_error = (params = {}, options = {}) => {
	return {
		en: en.signup_firstname_error,
		fr: fr.signup_firstname_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_lastname = (params = {}, options = {}) => {
	return {
		en: en.signup_lastname,
		fr: fr.signup_lastname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_lastname_error = (params = {}, options = {}) => {
	return {
		en: en.signup_lastname_error,
		fr: fr.signup_lastname_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_email = (params = {}, options = {}) => {
	return {
		en: en.signup_email,
		fr: fr.signup_email
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_email_error = (params = {}, options = {}) => {
	return {
		en: en.signup_email_error,
		fr: fr.signup_email_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_password = (params = {}, options = {}) => {
	return {
		en: en.signup_password,
		fr: fr.signup_password
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_password_error = (params = {}, options = {}) => {
	return {
		en: en.signup_password_error,
		fr: fr.signup_password_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_terms = (params = {}, options = {}) => {
	return {
		en: en.signup_terms,
		fr: fr.signup_terms
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_createAccount = (params = {}, options = {}) => {
	return {
		en: en.signup_createAccount,
		fr: fr.signup_createAccount
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_or = (params = {}, options = {}) => {
	return {
		en: en.signup_or,
		fr: fr.signup_or
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_login = (params = {}, options = {}) => {
	return {
		en: en.signup_login,
		fr: fr.signup_login
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_loginNow = (params = {}, options = {}) => {
	return {
		en: en.signup_loginNow,
		fr: fr.signup_loginNow
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_emailAlreadyUsed = (params = {}, options = {}) => {
	return {
		en: en.login_emailAlreadyUsed,
		fr: fr.login_emailAlreadyUsed
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const maintenance_title = (params = {}, options = {}) => {
	return {
		en: en.maintenance_title,
		fr: fr.maintenance_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const maintenance_subtitle = (params = {}, options = {}) => {
	return {
		en: en.maintenance_subtitle,
		fr: fr.maintenance_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ level: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Level_levelUp = (params , options = {}) => {
	return {
		en: en.Level_levelUp,
		fr: fr.Level_levelUp
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_articleUrl = (params = {}, options = {}) => {
	return {
		en: en.writer_articleUrl,
		fr: fr.writer_articleUrl
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_link_copy = (params = {}, options = {}) => {
	return {
		en: en.writer_article_link_copy,
		fr: fr.writer_article_link_copy
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const rss_articles_fr_title = (params = {}, options = {}) => {
	return {
		en: en.rss_articles_fr_title,
		fr: fr.rss_articles_fr_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const rss_articles_fr_description = (params = {}, options = {}) => {
	return {
		en: en.rss_articles_fr_description,
		fr: fr.rss_articles_fr_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invitation_meta_title = (params = {}, options = {}) => {
	return {
		en: en.invitation_meta_title,
		fr: fr.invitation_meta_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invitation_meta_description = (params = {}, options = {}) => {
	return {
		en: en.invitation_meta_description,
		fr: fr.invitation_meta_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invitation_firstNameError = (params = {}, options = {}) => {
	return {
		en: en.invitation_firstNameError,
		fr: fr.invitation_firstNameError
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invitation_lastNameError = (params = {}, options = {}) => {
	return {
		en: en.invitation_lastNameError,
		fr: fr.invitation_lastNameError
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invitation_passwordError = (params = {}, options = {}) => {
	return {
		en: en.invitation_passwordError,
		fr: fr.invitation_passwordError
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invitation_emailError = (params = {}, options = {}) => {
	return {
		en: en.invitation_emailError,
		fr: fr.invitation_emailError
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invitation_title = (params = {}, options = {}) => {
	return {
		en: en.invitation_title,
		fr: fr.invitation_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ friend: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invitation_forFriend = (params , options = {}) => {
	return {
		en: en.invitation_forFriend,
		fr: fr.invitation_forFriend
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invitation_description = (params = {}, options = {}) => {
	return {
		en: en.invitation_description,
		fr: fr.invitation_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invitation_email = (params = {}, options = {}) => {
	return {
		en: en.invitation_email,
		fr: fr.invitation_email
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invitation_password = (params = {}, options = {}) => {
	return {
		en: en.invitation_password,
		fr: fr.invitation_password
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invitation_firstname = (params = {}, options = {}) => {
	return {
		en: en.invitation_firstname,
		fr: fr.invitation_firstname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invitation_lastname = (params = {}, options = {}) => {
	return {
		en: en.invitation_lastname,
		fr: fr.invitation_lastname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invitation_activate = (params = {}, options = {}) => {
	return {
		en: en.invitation_activate,
		fr: fr.invitation_activate
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invitation_next = (params = {}, options = {}) => {
	return {
		en: en.invitation_next,
		fr: fr.invitation_next
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invitation_alreadyAccount = (params = {}, options = {}) => {
	return {
		en: en.invitation_alreadyAccount,
		fr: fr.invitation_alreadyAccount
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invitation_passwordForUser = (params = {}, options = {}) => {
	return {
		en: en.invitation_passwordForUser,
		fr: fr.invitation_passwordForUser
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invitation_notFound = (params = {}, options = {}) => {
	return {
		en: en.invitation_notFound,
		fr: fr.invitation_notFound
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invitation_activated = (params = {}, options = {}) => {
	return {
		en: en.invitation_activated,
		fr: fr.invitation_activated
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invitation_login = (params = {}, options = {}) => {
	return {
		en: en.invitation_login,
		fr: fr.invitation_login
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invitation_alreadyActivated = (params = {}, options = {}) => {
	return {
		en: en.invitation_alreadyActivated,
		fr: fr.invitation_alreadyActivated
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const courses_tab_discover = (params = {}, options = {}) => {
	return {
		en: en.courses_tab_discover,
		fr: fr.courses_tab_discover
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const courses_tab_beginner = (params = {}, options = {}) => {
	return {
		en: en.courses_tab_beginner,
		fr: fr.courses_tab_beginner
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const courses_tab_intermediate = (params = {}, options = {}) => {
	return {
		en: en.courses_tab_intermediate,
		fr: fr.courses_tab_intermediate
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const courses_tab_advanced = (params = {}, options = {}) => {
	return {
		en: en.courses_tab_advanced,
		fr: fr.courses_tab_advanced
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_glossary_title = (params = {}, options = {}) => {
	return {
		en: en.course_glossary_title,
		fr: fr.course_glossary_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_glossary_description = (params = {}, options = {}) => {
	return {
		en: en.course_glossary_description,
		fr: fr.course_glossary_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_glossary_button = (params = {}, options = {}) => {
	return {
		en: en.course_glossary_button,
		fr: fr.course_glossary_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const article_share = (params = {}, options = {}) => {
	return {
		en: en.article_share,
		fr: fr.article_share
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const article_share_description = (params = {}, options = {}) => {
	return {
		en: en.article_share_description,
		fr: fr.article_share_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const article_share_button = (params = {}, options = {}) => {
	return {
		en: en.article_share_button,
		fr: fr.article_share_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const article_ads_title = (params = {}, options = {}) => {
	return {
		en: en.article_ads_title,
		fr: fr.article_ads_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const article_ads_description = (params = {}, options = {}) => {
	return {
		en: en.article_ads_description,
		fr: fr.article_ads_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const article_ads_button = (params = {}, options = {}) => {
	return {
		en: en.article_ads_button,
		fr: fr.article_ads_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const term_share = (params = {}, options = {}) => {
	return {
		en: en.term_share,
		fr: fr.term_share
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const term_share_description = (params = {}, options = {}) => {
	return {
		en: en.term_share_description,
		fr: fr.term_share_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const term_share_button = (params = {}, options = {}) => {
	return {
		en: en.term_share_button,
		fr: fr.term_share_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ name: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const term_discover_our_glossary = (params , options = {}) => {
	return {
		en: en.term_discover_our_glossary,
		fr: fr.term_discover_our_glossary
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_hero_title = (params = {}, options = {}) => {
	return {
		en: en.home_hero_title,
		fr: fr.home_hero_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_hero_description = (params = {}, options = {}) => {
	return {
		en: en.home_hero_description,
		fr: fr.home_hero_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_googleContinue = (params = {}, options = {}) => {
	return {
		en: en.signup_googleContinue,
		fr: fr.signup_googleContinue
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_emailContinue = (params = {}, options = {}) => {
	return {
		en: en.signup_emailContinue,
		fr: fr.signup_emailContinue
	}[options.languageTag ?? languageTag()]()
}

